import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import stereotype from 'stereotype';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { DriverLicenseService } from 'gw-capability-driverlicense';
import { MockUpUtil, EntityUtil } from 'gw-portals-util-js';
import { readViewModelValue } from 'gw-jutro-adapters-react';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

import metadata from './DriversPage.metadata.json5';
import messages from './DriversPage.messages';

function DriversPage(props) {
    const {
        showConfirm
    } = useModal();

    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('DriverPage');

    const writeValue = useCallback(
        (value, path) => {
            const vmPath = path.replace(/\.value$/, '');
            const inputCtrlType = _.get(submissionVM, `${vmPath}.aspects.inputCtrlType`);
            let effectiveValue;
            switch (inputCtrlType) {
                case 'boolean':
                case 'number':
                    effectiveValue = stereotype(value);
                    break;
                default:
                    effectiveValue = value;
                    break;
            }
            _.set(submissionVM, path, effectiveValue);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const createDriverVM = useCallback(() => {
        if (_.isEmpty(submissionVM.lobData.personalAuto.coverables.drivers.value)) {
            const accountHolder = submissionVM.baseData.accountHolder.value;
            const driverObj = {
                accidents: '0',
                violations: '0',
                lastName: accountHolder.lastName,
                firstName: accountHolder.firstName,
                dateOfBirth: accountHolder.dateOfBirth,
                isPolicyHolder: true,
                person: accountHolder
            };
            const { _dtoName, _xCenter } = submissionVM.lobData.personalAuto.coverables.drivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
            submissionVM.lobData.personalAuto.coverables.drivers.pushElement(driverVM);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';

        if (
            MockUpUtil.isValueMocked(
                submissionVM.value,
                'quote.pa',
                `${driversPath}.0.licenseNumber`
            )
        ) {
            const driverPath = `${driversPath}.value[0]`;
            const driver = _.get(submissionVM, driverPath);

            const newDriver = {
                ...driver,
                licenseNumber: undefined,
                yearLicensed: undefined,
                gender: undefined
            };
            _.set(submissionVM, driverPath, newDriver);
            updateWizardData(submissionVM);
        }
        const drivers = _.get(submissionVM, `${driversPath}.value`);

        if (_.isEmpty(drivers)) {
            createDriverVM();
        }
        setPageInitialized(true);
        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        const accountHolderPath = 'baseData.accountHolder.value';
        const driversPath = 'lobData.personalAuto.coverables.drivers.value';
        const accountHolder = _.get(submissionVM, accountHolderPath);
        const driversList = _.get(submissionVM, driversPath);
        driversList.forEach((driver, index) => {
            if (
                _.isEmpty(driver.person.displayName)
                || !driver.person.displayName.includes(
                    `${driver.person.firstName} ${driver.person.lastName}`
                )
            ) {
                const displayname = `${driver.person.firstName} ${driver.person.lastName}`;
                _.set(submissionVM, `${driversPath}[${index}].person.displayName`, displayname);
            }
        });
        const accountHolderDriverIndex = _.findIndex(driversList, { isPolicyHolder: true });
        const accountHolderDriverPath = `${driversPath}[${accountHolderDriverIndex}]`;
        const accountHolderDriver = _.get(submissionVM, accountHolderDriverPath);

        const commonObjects = _.pick(accountHolderDriver, [
            'gender',
            'licenseNumber',
            'licenseState'
        ]);
        const commonObjectsForDriver = _.pick(accountHolder, ['firstName', 'lastName']);
        const newDriver = { ...accountHolderDriver, ...commonObjects, ...commonObjectsForDriver };

        _.set(submissionVM, accountHolderDriverPath, newDriver);
        _.set(submissionVM, accountHolderPath, { ...accountHolder, ...commonObjects });
        _.set(
            submissionVM,
            `${accountHolderDriverPath}.person`,
            _.get(submissionVM, accountHolderPath)
        );

        return submissionVM;
    }, [submissionVM]);

    const addDriver = useCallback(() => {
        const driverObj = {
            accidents: '0',
            violations: '0',
            isPolicyHolder: false,
            tempID: EntityUtil.nextId()
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.drivers;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
        driverVM.person = {};
        submissionVM.lobData.personalAuto.coverables.drivers.pushElement(driverVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeDriver = useCallback(
        (evt) => {
            const driverListPath = 'lobData.personalAuto.coverables.drivers.value';
            const driverPath = evt.path;
            const driver = _.get(submissionVM, driverPath).value;
            const driverList = _.get(submissionVM, driverListPath);
            const driverIndex = driverList.findIndex(
                (driverFromList) => _.isEqual(driverFromList, driver)
            );

            showConfirm({
                title: messages.removeDriverTitle,
                message: messages.removeDriverDescription,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const newDriverList = _.remove(
                    driverList,
                    (driverFromList) => _.isEqual(driverFromList, driver)
                );
                _.set(submissionVM, newDriverList, driverListPath);
                disregardFieldValidation(`driver${driverIndex}`);
                updateWizardData(submissionVM);
                return true;
            }, _.noop);
        },
        [disregardFieldValidation, showConfirm, submissionVM, updateWizardData]
    );

    const onFileUpload = useCallback(
        async (file, index) => {
            const driverPath = `lobData.personalAuto.coverables.drivers.children[${index}]`;
            const driverLicenseData = await DriverLicenseService.uploadBarcode(file);
            const driverVM = _.get(submissionVM, driverPath);

            Object.entries(driverLicenseData).forEach(([key, value]) => {
                if (_.has(driverVM, key)) {
                    _.set(driverVM, `${key}.value`, value);
                }
                if (_.has(driverVM.person, key)) {
                    _.set(driverVM.person, `${key}.value`, value);
                }
            });
            writeValue(driverVM.value, `${driverPath}.value`);
        },
        [submissionVM, writeValue]
    );

    const generateOverrides = useCallback(() => {
        const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
        const overrideProps = {};
        let readOnlyFields = ['driverFirstName', 'driverLastName', 'driverDateOfBirth'];

        if (config.persona === 'producer' || config.persona === 'csr') {
            readOnlyFields = ['driverFirstName', 'driverLastName'];
        }

        drivers.forEach((driver, index) => {
            const isAccountHolder = driver.isPolicyHolder;

            let driverTitle = translator(messages.paDriverTitle);

            if (drivers.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.paDriverTitle)} ${index + 1}`;
            }

            overrideProps[`driver${index}`] = {
                readOnlyFields: isAccountHolder ? readOnlyFields : null
            };
            overrideProps[`scanDriverLicenseBarcodeContainer${index}`] = {
                visible: !isAccountHolder && (
                    breakpoint === 'phoneWide'
                    || breakpoint === 'phone'
                    || breakpoint === 'tablet'
                )
            };
            overrideProps[`paDriverTitle${index}`] = {
                content: driverTitle
            };
            overrideProps[`scanDriverLicenseBarcode${index}`] = {
                onValueChange: undefined,
                onUpload: (file) => onFileUpload(file, index),
                maxFileSize: config.maxFileUploadSize
            };
            overrideProps[`paDeleteDriver${index}`] = {
                visible: !isAccountHolder
            };
        });

        return overrideProps;
    }, [breakpoint, onFileUpload, submissionVM, translator]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        addDriver: {
            disabled: !isComponentValid
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onAddDriverClick: addDriver,
            onRemoveDriver: removeDriver,
            onValidate: onValidate
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

DriversPage.propTypes = wizardProps;
export default DriversPage;
