import React, { useContext, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { DriverLicenseService } from 'gw-capability-driverlicense';
import { useValidation } from 'gw-portals-validation-react';
import { EntityUtil } from 'gw-portals-util-js';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

import metadata from './YourInfoPage.metadata.json5';
import './YourInfoPage.messages';

function YourInfoPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, isComponentValid, initialValidation } = useValidation('YourInfoPage');

    const driverIndex = useMemo(() => {
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
        const driversPath = 'lobData.personalAuto.coverables.drivers.value';
        const driverList = _.get(submissionVM, driversPath, []);

        return driverList.findIndex(({ person }) => person.publicID === accountHolder.publicID);
    }, [submissionVM]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        // If accountHolder driver exists and accountHolder is changed,
        // the update draft call will fail if the driver is not changed as well
        if (driverIndex !== -1) {
            const pathToDriver = `lobData.personalAuto.coverables.drivers.children[${driverIndex}]`;
            _.set(submissionVM, 'baseData.accountHolder.value.tempId', EntityUtil.nextId());
            const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
            _.set(submissionVM, `${pathToDriver}.dateOfBirth`, accountHolder.dateOfBirth);
            _.set(submissionVM, `${pathToDriver}.isPolicyHolder`, true);
            _.set(submissionVM, `${pathToDriver}.person`, accountHolder);
        }
        submissionVM.value = await LoadSaveService.updateDraftSubmissionAndPrimaryLocation(
            submissionVM.value
        );
        return submissionVM;
    }, [driverIndex, submissionVM]);

    const onFileUpload = useCallback(
        async (file) => {
            const accountHolderPath = 'baseData.accountHolder';
            const driverLicenseData = await DriverLicenseService.uploadBarcode(file);
            const accountHolderVM = _.get(submissionVM, accountHolderPath);
            Object.entries(driverLicenseData).forEach(([key, value]) => {
                if (_.has(accountHolderVM, key)) {
                    _.set(accountHolderVM, `${key}.value`, value);
                }
            });
            writeValue(accountHolderVM.value, `${accountHolderPath}.value`);
        },
        [submissionVM, writeValue]
    );

    const minimumAllowedDateOfBirth = useMemo(() => {
        const currentDate = new Date();
        const { ageAllowedToDrive } = config.personalAutoConfig;
        currentDate.setFullYear(currentDate.getFullYear() - ageAllowedToDrive);
        return currentDate;
    }, [submissionVM]);

    // eslint-disable-next-line consistent-return
    const dobIn = useMemo(() => {
        const dobValue = _.get(submissionVM.value, 'baseData.accountHolder.dateOfBirth');
        if (dobValue !== null && dobValue !== undefined) {
            const { year, month, day } = dobValue;
            return new Date(year, month, day);
        }
    }, [submissionVM]);

    const oldEnough = (minimumAllowedDateOfBirth > dobIn);

    const disableNextBool = !(oldEnough && isComponentValid);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        dateOfBirth: {
            maxDate: minimumAllowedDateOfBirth
        },
        scanDriverLicenseBarcodeContainer: {
            visible: breakpoint !== 'desktop'
        },
        scanDriverLicenseBarcode: {
            onValueChange: undefined,
            maxFileSize: config.maxFileUploadSize
        },
        addresslookup: {
            // Use custom onValueChange handler to avoid state comparison issue
            // in AddressLookup component due to the use of _.set() in Jutro onFieldValueChange,
            onValueChange: writeValue
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onFileUpload: onFileUpload,
            onValidate: onValidate,
            writeValue: writeValue
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={disableNextBool}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourInfoPage.propTypes = wizardProps;
export default YourInfoPage;
