import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';

function QuoteClauseTableCell({
    clauseCellUniqueID,
    clauses,
    code,
    loadingClause,
    onChangeSubmission,
    onSyncCoverages,
    onChangeSubmissionAndSync,
    onValidate,
    path,
}) {
    const clauseIndex = clauses.findIndex(
        (aClause) => clauseCellUniqueID.coverageUniqueID.split('__')[0] === aClause.publicID
    );
    const clause = clauses[clauseIndex];
    const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
    const isClauseLoading = _.get(loadingClause, 'clauseID') === clauseCellUniqueID.coverageUniqueID
        && _.get(loadingClause, 'quoteCode') === code;

    if (clauseIndex !== -1) {
        return (
            <SingleClauseComponentVM
                value={clause}
                path={`${path}.children[${clauseIndex}]`}
                onChangeClause={onChangeSubmission}
                onSyncCoverages={onSyncCoverages}
                onChangeSubmissionAndSync={onChangeSubmissionAndSync}
                loadingClause={isClauseLoading || isQuoteLoading}
                idPrefix={code}
                labelTop
                labelPosition="top"
                onValidate={onValidate}
                showAmount={false}
            />
        );
    }
    return null;
}

QuoteClauseTableCell.propTypes = {
    clauseCellUniqueID: PropTypes.shape({
        coverageUniqueID: PropTypes.string
    }).isRequired,
    clauses: PropTypes.array,
    code: PropTypes.string.isRequired,
    loadingClause: PropTypes.shape({}),
    onChangeSubmission: PropTypes.func.isRequired,
    onSyncCoverages: PropTypes.func.isRequired,
    onChangeSubmissionAndSync: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    path: PropTypes.string.isRequired,
};

QuoteClauseTableCell.defaultProps = {
    loadingClause: undefined,
    onValidate: undefined,
    clauses: [],
};

export default QuoteClauseTableCell;
