/**
 * Provides branding data from URL
 * using either 'branding' query string
 * or sub domain
 */
import queryString from 'query-string';

const BRANDS = [
    {host: 'insurethebox', brand: 'itb'},
    {host: 'tescobank', brand: 'tbb'},
    {host: 'tbboxins', brand: 'tbb'},
    {host: 'drivelikeagirl', brand: 'dgt'},
    {host: 'toyotamotorinsurance', brand: 'toy'},
    {host: 'lexusmotorinsurance', brand: 'lex'},
    {host: 'autosaninsurance', brand: 'aut'},
    {host: 'kinto-lcv-insurance', brand: 'kin'},
    {host: 'kwik-fit', brand: 'kfi'},
    {host: 'toyotainsuranceservices', brand: 'tya'}
  ];

const hostNameFromURLsegments = window.location.hostname.split('.');

let hostName = BRANDS.find(function(hostBrands){
    return hostNameFromURLsegments.includes(hostBrands.host);
 });

 let hostNameBrand;
 if(hostName) {
    hostNameBrand = hostName.brand;
 }

 let localhostBrandSearch;
 if(window.location.hostname === 'localhost') {
    localhostBrandSearch = queryString.parse(window.location.search).branding?.substring(0, 3);
 }

export default {
    BRANDING: (
        localhostBrandSearch || // e.g. XXX?branding=custom gives "custom"
        hostNameBrand || // e.g. custom-domain.guidewire.com gives  "custom-domain"
        window.brandingFromRequest // gets branding param value from request
    )
};
