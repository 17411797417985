import DriverComponent from './components/DriverComponent/DriverComponent';
import PMDriverComponent from './components/PMDriverComponent/PMDriverComponent';
import VehicleComponent from './components/VehicleComponent/VehicleComponent';
import PMVehicleComponent from './components/PMVehicleComponent/PMVehicleComponent';
import PolicyChangeDetailsComponent from './components/PolicyChangeDetailsComponent/PolicyChangeDetailsComponent';
import PolicyDiffComponent from './components/PolicyDiffComponent/PolicyDiffComponent';
import PMQuotePage_BenefitsSection from './components/PMQuotePage_BenefitsSection/PMQuotePage_Benefits';
import PMQuotePage_ExcessSection from './components/PMQuotePage_ExcessSection/PMQuotePage_Excess';
import PMQuotePage_PNCDSection from './components/PMQuotePage_PNCDSection/PMQuotePage_PNCD';
import PMQuotePage_CoverageSection from './components/PMQuotePage_CoverageSection/PMQuotePage_Coverage';
import PMConvictionsComponent from './components/PMConvictionsComponent/PMConvictionsComponent';
import PMClaimsComponent from './components/PMClaimsComponent/PMClaimsComponent';
import PMQuoteReviewComponent from './components/PMQuoteReviewComponent/PMQuoteReviewComponent';
import PMQuoteReviewDriverComponent from './components/PMQuoteReviewDriverComponent/PMQuoteReviewDriverComponent';
import PMQuoteReviewCarComponent from './components/PMQuoteReviewCarComponent/PMQuoteReviewCarComponent';
import PMQuoteReviewAddOnsComponent from './components/PMQuoteReviewAddOnsComponent/PMQuoteReviewAddOnsComponent';
import PMContactDetailsComponent from './components/PMContactDetailsComponent/PMContactDetailsComponent';
import PMPaymentPlansComponent from './components/PMPaymentPlansComponent/PMPaymentPlansComponent';
import PMMonthlyRepresentativeExampleComponent from './components/PMMonthlyRepresentativeExampleComponent/PMMonthlyRepresentativeExampleComponent';
import PMPersonDetailsComponent from './components/PMPersonDetailsComponent/PMPersonDetailsComponent';
import CookiesModal from './components/CookiesComponent/CookiesModal';
import CookiesComponent from './components/CookiesComponent/CookiesComponent';
import PMDriverDetailsComponent from './components/PMDriverDetailsComponent/PMDriverDetailsComponent';
import DDMandateTableDisplayComponent from './components/DDMandateTableDisplayComponent/DDMandateTableDisplayComponent';
import PNCDPopupModalWindow from './components/PNCDPopupModal/PNCDPopupModalWindow';
import DDViewMandateModalPopup from 'gw-capability-quoteandbind-pm-react/pages/PMLegalPage3Information/DDViewMandateModalPopup';

const policyJobComponentMap = {
    drivercomponent: { component: 'DriverComponent' },
    pmdrivercomponent: { component: 'PMDriverComponent' },
    vehiclecomponent: { component: 'VehicleComponent' },
    pmvehiclecomponent: { component: 'PMVehicleComponent' },
    PolicyDiffComponent: { component: 'PolicyDiffComponent' },
    PMQuotePage_BenefitsSection: { component: 'PMQuotePage_BenefitsSection' },
    PMQuotePage_ExcessSection: { component: 'PMQuotePage_ExcessSection' },
    PMQuotePage_PNCDSection: { component: 'PMQuotePage_PNCDSection' },
    PMQuotePage_Coverage: { component: 'PMQuotePage_CoverageSection' },
    pmconvictionscomponent: { component: 'PMConvictionsComponent' },
    pmclaimscomponent: { component: 'PMClaimsComponent' },
    pmquotereviewcomponent: { component: 'PMQuoteReviewComponent' },
    pmquotereviewdrivercomponent: { component: 'PMQuoteReviewDriverComponent' },
    pmquotereviewcarcomponent: { component: 'PMQuoteReviewCarComponent' },
    pmquotereviewaddonscomponent: { component: 'PMQuoteReviewAddOnsComponent' },
    pmcontactdetailscomponent: { component: 'PMContactDetailsComponent' },
    PMPaymentPlansComponent: { component: 'PMPaymentPlansComponent' },
    PMMonthlyRepresentativeExampleComponent: { component: 'PMMonthlyRepresentativeExampleComponent' },
    PMPersonDetailsComponent: { component: 'PMPersonDetailsComponent' },
    CookiesModal: { component: 'CookiesModal' },
    CookiesComponent: { component: 'CookiesComponent' },
    PMDriverDetailsComponent: { component: 'PMDriverDetailsComponent' },
    DDMandateTableDisplayComponent: { component: 'DDMandateTableDisplayComponent' },
    DDViewMandateModalPopup: { component: 'DDViewMandateModalPopup' },
    PNCDPopupModalWindow: { component: 'PNCDPopupModalWindow' }
};

const policyJobComponents = {
    DriverComponent,
    PMDriverComponent,
    VehicleComponent,
    PMVehicleComponent,
    PolicyDiffComponent,
    PMConvictionsComponent,
    PMClaimsComponent,
    PMQuotePage_BenefitsSection,
    PMQuotePage_ExcessSection,
    PMQuotePage_CoverageSection,
    PMQuotePage_PNCDSection,
    PMQuoteReviewComponent,
    PMQuoteReviewDriverComponent,
    PMQuoteReviewCarComponent,
    PMQuoteReviewAddOnsComponent,
    PMContactDetailsComponent,
    PMPaymentPlansComponent,
    PMMonthlyRepresentativeExampleComponent,
    PMPersonDetailsComponent,
    CookiesModal,
    CookiesComponent,
    PMDriverDetailsComponent,
    DDMandateTableDisplayComponent,
    DDViewMandateModalPopup,
    PNCDPopupModalWindow
};

export {
    DriverComponent,
    PMDriverComponent,
    VehicleComponent,
    PMVehicleComponent,
    PolicyChangeDetailsComponent,
    PolicyDiffComponent,
    PMQuotePage_BenefitsSection,
    PMQuotePage_ExcessSection,
    PMQuotePage_PNCDSection,
    PMQuotePage_CoverageSection,
    policyJobComponentMap,
    policyJobComponents,
    PMConvictionsComponent,
    PMClaimsComponent,
    PMQuoteReviewComponent,
    PMQuoteReviewDriverComponent,
    PMQuoteReviewCarComponent,
    PMQuoteReviewAddOnsComponent,
    PMContactDetailsComponent,
    PMPaymentPlansComponent,
    PMMonthlyRepresentativeExampleComponent,
    PMPersonDetailsComponent,
    CookiesModal,
    CookiesComponent,
    PMDriverDetailsComponent,
    DDMandateTableDisplayComponent,
    DDViewMandateModalPopup,
    PNCDPopupModalWindow
};
