import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';

import messages from './CommonQuoteInfo.messages';
import commonQuoteStyles from '../../quoteandbind-common-styles.module.scss';

function CommonQuoteInfo(props) {
    const { submissionVM, history } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const quoteId = _.get(submissionVM, 'quoteID.value');
    const location = _.get(submissionVM, 'baseData.policyAddress.displayName.value');

    if (breakpoint === 'phoneWide' || breakpoint === 'phone') {
        return (
            <div className={commonQuoteStyles.quoteInfoBar}>
                <div className={commonQuoteStyles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.quote)}
                    </div>
                    <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                        {quoteId}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.quoteWrapper}>
                <div className={commonQuoteStyles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.quote)}
                    </div>
                    <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                        {quoteId}
                    </div>
                </div>
                <div className={commonQuoteStyles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.location)}
                    </div>
                    <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>
                        <Tooltip
                            id="policyLocation"
                            placement="bottom"
                            trigger="mouseenter"
                            content={location}
                        >
                            <div className={commonQuoteStyles.infoOverflow}>{location}</div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

CommonQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default CommonQuoteInfo;
