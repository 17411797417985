import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { Badge, Tooltip, Card, StepProgressBar } from '@jutro/components';
import { WizardPage, wizardProps, WizardContext } from 'gw-portals-wizard-react';
import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'gw-portals-util-js';
import { withRouter, useLocation, useHistory } from 'react-router-dom';

import messages from './PmQuoteInfo.messages';
import styles from './PmQuoteInfo.module.scss';
import ProgressStepUtil from '../../util/ProgressStepUtil';

function PmQuoteInfo(props) {
    const { wizardData, wizardSnapshot, onNavPageJump } = useContext(WizardContext);
    const { location, history } = props;
    const [stepperContainerVisibilityClass, setStepperContainerVisibilityClass] = useState('stepperContainer');
    const navBarSupressionPages = ['/success-payment', '/purchase-failed'];
    const currentLocation = useLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const arrStepNavigationMapper = [
        {
            stepTitle: 'About you',
            stepNavigation: 'about-you',
            stepIndex: 0,
            pageLinkIndex: 0,
            allowModalSave: false
        },
        {
            stepTitle: 'About the driver',
            stepNavigation: 'driver-details',
            stepIndex: 1,
            pageLinkIndex: 1,
            allowModalSave: false
        },
        {
            stepTitle: 'About your car',
            stepNavigation: 'vehicle-details',
            stepIndex: 2,
            pageLinkIndex: 3,
            allowModalSave: false
        },
        {
            stepTitle: 'Your Quote',
            stepNavigation: 'quote',
            stepIndex: 3,
            pageLinkIndex: 4,
            allowModalSave: false
        },
        {
            stepTitle: 'Legal and payment',
            stepNavigation: 'legal-and-info-1',
            stepIndex: 4,
            pageLinkIndex: 5,
            allowModalSave: false
        }
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialSteps = [
        {
            active: true,
            disabled: false,
            title: 'About you',
            visited: true
        },
        {
            active: false,
            disabled: true,
            title: 'About the driver',
            visited: false
        },
        {
            active: false,
            disabled: true,
            title: 'About your car',
            visited: false
        },
        {
            active: false,
            disabled: true,
            title: 'Your Quote',
            visited: false
        },
        {
            active: false,
            disabled: true,
            title: 'Legal and payment',
            visited: false
        }
    ];

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        let currentSteps = JSON.parse(window.sessionStorage.getItem("steps"));
        if(!currentSteps) {
            currentSteps = initialSteps;
        }
        if (navBarSupressionPages.includes(location.pathname)) {
            setStepperContainerVisibilityClass('stepperContainerHidden');
        }
        const updatedSteps = ProgressStepUtil.getStepsArray(history, currentSteps);
        setSteps(updatedSteps);
        window.sessionStorage.setItem("steps", JSON.stringify(updatedSteps));
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const handleStepClick = useCallback((stepIndex) => {
        if (stepIndex >= 0 && stepIndex < steps.length) {
            const userSelectedNavigationElement = steps[stepIndex].title;
            if (userSelectedNavigationElement !== '') {
                const obj = arrStepNavigationMapper.find(
                    (nav) => nav.stepTitle === userSelectedNavigationElement
                );

                if (obj) {
                    const matchedStepsElement = steps[obj.stepIndex];
                    if (!matchedStepsElement.disabled && matchedStepsElement.visited) {
                        if (
                            currentLocation.pathname !== `/${obj.stepNavigation}` &&
                            userSelectedNavigationElement !== 'Legal and payment'
                        ) {
                            const index = obj.pageLinkIndex;
                            const modalMessages = {
                                title: messages.modalTitle.defaultMessage,
                                message: messages.modalMessage.defaultMessage,
                                messageProps: {
                                    ok: messages.modalOkButtonText.defaultMessage,
                                    close: messages.modalCloseButtonText.defaultMessage,
                                },
                            };
                            onNavPageJump({
                                wizardData,
                                wizardSnapshot,
                                modalMessages,
                                index,
                            });
                        }
                    }
                }
            }
        }
    // eslint-disable-next-line max-len
    }, [arrStepNavigationMapper, currentLocation.pathname, onNavPageJump, steps, wizardData, wizardSnapshot]);

    const stepsWithOnClick = steps.map((step, index) => ({
        ...step,
        onClick: () => handleStepClick(index),
    }));

    return (
        <div className={stepperContainerVisibilityClass}>
            <Card
                id="step-progress-bar-panel"
                isPanel
                title=""
            >
                <StepProgressBar
                    aria-label="StepProgressBar"
                    steps={stepsWithOnClick}
                    onClick={handleStepClick}
                />
            </Card>
        </div>
    );
}

PmQuoteInfo.propTypes = wizardProps;

export default withRouter(PmQuoteInfo);
