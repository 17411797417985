function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}
function scrollToElementWithOffset(elementId, offsetElementId) {
    const element = document.getElementById(elementId);
    const offsetElement = document.getElementById(offsetElementId);
    if (element && offsetElement) {
        const offset = offsetElement.offsetHeight;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            behavior: 'smooth',
            top: elementPosition - offset,
        });
    }
}
const ScrollToElementUtil = {
    scrollToElement: scrollToElement,
    scrollToElementWithOffset: scrollToElementWithOffset,
};

export default ScrollToElementUtil;
