import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverTitle: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Title',
        defaultMessage: 'Title',
    },
    paDriverFirstName: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverGenderPlaceholder: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.-- Choose Driver Gender --',
        defaultMessage: '-- Choose Driver Gender --',
    },
    paDriversLicenseNumber: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Drivers License Number',
        defaultMessage: 'Drivers License Number',
    },
    paDriverLicenceState: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.License State',
        defaultMessage: 'License State',
    },
    paDriverYearFirstLicensed: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Year First Licensed',
        defaultMessage: 'Year First Licensed',
    },
    paDriverNumberOfAccidents: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    },
    pmDriverpolicyholderPre: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.policyholder pre',
        defaultMessage: '(Policyholder - ',
    },
    pmDriverpolicyholderPost: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.policyholder post',
        defaultMessage: ')',
    },
    pmMainErrorBannerMessage: {
       id: 'quoteandbind.pm.views.pm-add-driver-new-error-one-of-two',
       defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    }
});
