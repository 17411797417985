import _ from 'lodash';
import { initiatePromiseObject } from 'gw-portals-promise-js';

const defaultConfig = {
    appId: null,
    debugMode: false,
    currentScriptTag: null,
    shareDialog: null
};

/**
 * If locale is given as language only (e.g. 'es'), try to normalize it to full locale -> es_ES
 * Facebook will default to English if the guess is wrong.
 * With just 'es' facebook would default to English anyways.
 *
 * @param {String} locale
 * @returns {String}
 */
function normalizeLocaleInfo(locale) {
    if (locale.indexOf('_') === -1) {
        return `${locale}_${locale.toUpperCase()}`;
    }
    return locale;
}

function getURL(locale) {
    const localeToUse = locale || normalizeLocaleInfo(localStorage.getItem('selectedLanguage'));
    return `https://connect.facebook.net/${localeToUse}/sdk.js`;
}

const currentLocale = localStorage.getItem('selectedLanguage') || '';
const normalisedLocale = normalizeLocaleInfo(currentLocale);
const SCRIPT_URL = getURL(normalisedLocale);

function cleanupPreviousInstance() {
    const fbRoot = document.querySelector('body #fb-root');
    const localeWithUnderscore = currentLocale.replace('-', '_');
    const headTag = [
        document.querySelector(`head script[src="${SCRIPT_URL}"]`),
        document.querySelector(`head script[src^="${getURL(localeWithUnderscore)}"]`)
    ];

    if (fbRoot) {
        document.body.removeChild(fbRoot);
    }
    if (headTag) {
        headTag.forEach((tag) => {
            if (tag) {
                document.head.removeChild(tag);
            }
        });
    }
    if (window.FB) {
        delete window.FB;
    }
}

class FacebookService {
    static getClient(config = {}) {
        const mergedConfig = _.merge({}, defaultConfig, config);
        const facebookPromise = initiatePromiseObject();
        let scriptTag;

        if (window.FB) {
            return Promise.resolve(window.FB, mergedConfig);
        }

        window.fbAsyncInit = () => {
            const client = window.FB;
            if (client) {
                client.init({
                    appId: mergedConfig.appId,
                    version: 'v5.0',
                    xfbml: false
                });

                facebookPromise.resolve(client, {
                    ...config,
                    currentScriptTag: scriptTag
                });
            } else {
                facebookPromise.reject('Facebook Client is not available');
            }
        };

        // Access control prevents loading facebook sdk with ajax
        scriptTag = document.createElement('script');
        scriptTag.async = 'async';
        scriptTag.onerror = () => {
            facebookPromise.reject('Facebook Client is not available');
        };
        scriptTag.src = getURL();

        if (config.currentScriptTag) {
            cleanupPreviousInstance();
        }
        document.head.appendChild(scriptTag);

        return facebookPromise.promise;
    }

    static removeClient(config = {}) {
        cleanupPreviousInstance(config);
    }
}

export default FacebookService;
