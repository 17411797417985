/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ScrollToError } from '@jutro/wizard-next';
import ValidationUtil from '../../util/ValidationUtil';
import { withRouter } from 'react-router-dom';
import { MockUpUtil } from 'gw-portals-util-js';
import styles from './PMLegalPage2InformationPage.scss';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import { brandingData } from 'gw-portals-branding-js';
import htmlParser from 'html-react-parser';

import metadata from './PMLegalPage2InformationPage.metadata.json5';
import messagesTranslationsSet from './PMLegalPage2InformationPage.messages';

const PATH_TO_MOCK_DATA = 'quote.pm';
const MOCK_DATA_TO_SET = ['baseData.accountHolder.emailAddress1'];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

function initialiseVM(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    // Stop-gap fix for the isCardRegisteredToYou radio button to be able to be selectable,
    // But will have to be looked at again as some of the paymentDetails metadata is undefined
    submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
    submissionVM.bindData.paymentDetails.creditCardData.value = submissionVM.bindData.paymentDetails.creditCardData.value || {};
}

function PMLegalPage2InformationPage(props) {
    const { wizardData: submissionVM, updateWizardData, goNext: history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PMLegalPage2InformationPage');
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const [isStoreCardDetailsFuturePolicy, setStoreCardDetailsFuturePolicy] = useState(false);
    const [isThisCardRegisteredToYou, setThisCardRegisteredToYou] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const [submitted, setSubmitted] = useState(false);
    const isHomeNumberValid = ValidationUtil.isHomeNumberValid(submissionVM);
    const isPhoneNumberValid = ValidationUtil.isPhoneNumberValid(submissionVM);
    const [isPhoneNumberLnPValid, setPhoneNumberLnPValid] = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, '', {}, isPhoneNumberLnPValid);
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    const translator = useTranslator();
    const brand = brandingData.BRANDING;

    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
            updateWizardData(submissionVM);
        } else {
            let vm = viewModelService.clone(submissionVM);

            //AutoRenew_itb
            _.set(vm.value, 'bindData.autoRenew_itb', true);

            if (!_.isEqual(vm.value, submissionVM.value)) {
                updateWizardData(vm);
            }
        }
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(7));
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVMInitialised]);

    useEffect(() => {
        if (submissionVM.bindData.storeCardForFuture_itb.value === undefined){
        const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
        newUpdatedSubmissionVM.bindData.storeCardForFuture_itb.value = true
        updateWizardData(newUpdatedSubmissionVM);
        }
    }, []);

    const onNext = useCallback(() => {
        if (invalidPropsLength > 0) {
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }
        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "PMLegalPage2InformationPage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [history, invalidPropsLength, submissionVM, updateWizardData, viewModelService]);

    const getBooleanFieldValue = (path) => {
        const booleanVal = _.get(submissionVM, path, false);

        return booleanVal;
    };
    const onNameHandleValueChange = (value, path) => {
        const pattern = /^[a-zA-Z-' ]{0,25}$/;
        if (pattern.test(value)) {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
        }
    };
    const handlePhoneNumChange = useCallback(
        (value) => {
            const pattern = /^[0-9]{0,11}$/;
            const allowTestNums = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
            if (pattern.test(value)) {
                if (allowTestNums.test(value)) {
                    setPhoneNumberLnPValid(true);
                } else {
                    setPhoneNumberLnPValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'bindData.paymentDetails.creditCardData.cardHolderPhoneNumber', value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );
    const handleInPageRadioButtonChanges = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
            if (path.includes('isCardRegisteredToYou')) {
                setThisCardRegisteredToYou(!value);
            } else {
                setStoreCardDetailsFuturePolicy(!value);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        inlineNotificationErrorBarAboutYou: {
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).strErrorBannerTextOutput,
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).blnErrorWidgetVisibility
        },
        isCardRegisteredToYou: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleInPageRadioButtonChanges,
            required: getBooleanFieldValue('bindData.paymentDetails.creditCardData.isCardRegisteredToYou.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.paymentDetails.creditCardData.isCardRegisteredToYou.aspects.required'),
            validationMessages: (submissionVM.bindData.paymentDetails.creditCardData && submissionVM.bindData.paymentDetails.creditCardData.isCardRegisteredToYou && submissionVM.bindData.paymentDetails.creditCardData.isCardRegisteredToYou.value === undefined && submitted) ? ['This field is required'] : [],
        },
        storeCardForFuture_itb: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleInPageRadioButtonChanges,
            required: getBooleanFieldValue('bindData.storeCardForFuture_itb.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.storeCardForFuture_itb.aspects.required'),
            value: !isStoreCardDetailsFuturePolicy
        },
        areYouHappyStoreCardDetailsContainer: {
            visible: isStoreCardDetailsFuturePolicy
        },
        cardRegisteredRisclaimerFirst: {
            visible: !isThisCardRegisteredToYou
        },
        isCardRegisteredToYouContainer: {
            visible: isThisCardRegisteredToYou
        },
        firstName: {
            onValueChange: onNameHandleValueChange,
            required: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderFirstname.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderFirstname.aspects.required'),
            validationMessages: (submissionVM.bindData.paymentDetails.creditCardData && submissionVM.bindData.paymentDetails.creditCardData.cardHolderFirstname && (submissionVM.bindData.paymentDetails.creditCardData.cardHolderFirstname.value == '' || submissionVM.bindData.paymentDetails.creditCardData.cardHolderFirstname.value == null)) ? ['This field is required'] : [],
        },
        surName: {
            onValueChange: onNameHandleValueChange,
            required: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderSurname.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderSurname.aspects.required'),
            validationMessages: (submissionVM.bindData.paymentDetails.creditCardData && submissionVM.bindData.paymentDetails.creditCardData.cardHolderSurname && (submissionVM.bindData.paymentDetails.creditCardData.cardHolderSurname.value == '' || submissionVM.bindData.paymentDetails.creditCardData.cardHolderSurname.value == null)) ? ['This field is required'] : [],
        },
        emailAddress: {
            validationMessages: (submissionVM.bindData.paymentDetails.creditCardData && submissionVM.bindData.paymentDetails.creditCardData.cardHolderEmailAddress && (submissionVM.bindData.paymentDetails.creditCardData.cardHolderEmailAddress.value == '' || submissionVM.bindData.paymentDetails.creditCardData.cardHolderEmailAddress.value == null)) ? ['This field is required'] : [],
            validator: {
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/,
                message: {
                    id: 'emailValidationMessage',
                    defaultMessage: 'This field is required'
                }
            },
            required: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderEmailAddress.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderEmailAddress.aspects.required')
        },
        PhoneNumber: {
            validationMessages: (submissionVM.bindData.paymentDetails.creditCardData && submissionVM.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber && (submissionVM.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.value == '' || submissionVM.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.value == null)) ? ['This field is required'] : [],
            validator: {
                pattern: /^(07700900[0-9]{3})|(07[0-9]{9})$/,
                message: {
                    id: 'phoneNumberValidationMessage',
                    defaultMessage: 'Value entered must be a valid phone number'
                }
            },
            onValueChange: handlePhoneNumChange,
            required: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.aspects.required')
        },
        billingaddressLine1: {
            validationMessages: (submissionVM.bindData.billingAddress.addressLine1 && (submissionVM.bindData.billingAddress.addressLine1.value == '' || submissionVM.bindData.billingAddress.addressLine1.value == null)) ? ['This field is required'] : [],
            required: getBooleanFieldValue('bindData.billingAddress.addressLine1.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.billingAddress.addressLine1.aspects.required')
        },
        billingpostalCode: {
            validationMessages: (submissionVM.bindData.billingAddress.postalCode && (submissionVM.bindData.billingAddress.postalCode.value == '' || submissionVM.bindData.billingAddress.postalCode.value == null)) ? ['This field is required'] : [],
            required: getBooleanFieldValue('bindData.billingAddress.postalCode.aspects.required'),
            showRequired: getBooleanFieldValue('bindData.billingAddress.postalCode.aspects.required')
        },
        detailsdisclaimer: {
            content: htmlParser(translator(messagesTranslationsSet[`detailsdisclaimer${brand.toUpperCase()}`]))
        },
        QnBLnP2RenewalSubHeadingText: {
            content: htmlParser(translator(messagesTranslationsSet[`QnBLnP2RenewalSubHeadingText${brand.toUpperCase()}`]))
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "PMLegalPage2InformationPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                disableNext={false}
                nextLabel={'Continue'}
                previousLabel={'Back'} 
                disableCancel={true}
                cancelLabel={''}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={submitted}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

PMLegalPage2InformationPage.propTypes = wizardProps;
export default withRouter(PMLegalPage2InformationPage);
