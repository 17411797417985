import { defineMessages } from 'react-intl';

export default defineMessages({
    pmLegalThreeOfFourTitle: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Title',
        defaultMessage: 'Direct debit mandate (step 3 of 4)',
    },
    pmLegalThreeOfFourDDMandateNotice: {
        id: 'quoteandbind.pm.views.pm-legal2-information.DD mandate notice',
        defaultMessage: "Please check the following details are correct, if they are click ‘Continue’ to proceed. If not, please go back to the previous screen, amend your payment details and try again."
    },
    pmLegalThreeOfFourViewMandate: {
        id: 'quoteandbind.pm.views.pm-legal3-information.view complete mandate',
        defaultMessage: 'View completed mandate'
    },
    pmLegalThreeOfFourEditMandate: {
        id: 'quoteandbind.pm.views.pm-legal3-information.edit mandate',
        defaultMessage: 'Edit details'
    },
    pmLegalThreeOfFourDDMandateNotice2: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD mandate notice2',
        defaultMessage: 'Amount to be debited from bank account'
    },
    pmLegalThreeOfFourDDMandateNotice4: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD mandate notice4',
        defaultMessage: 'If you have any questions about your instalments, contact Premium Credit on 0344 736 9836.'
    },
    pmLegalThreeOfFourPCLCompanyName: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company name',
        defaultMessage: 'Premium Credit Limited'
    },
    pmLegalThreeOfFourPCLCompanyAddress1: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company address1',
        defaultMessage: 'Ermyn house'
    },
    pmLegalThreeOfFourPCLCompanyAddress2: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company address2',
        defaultMessage: 'Ermyn Way'
    },
    pmLegalThreeOfFourPCLCompanyCity: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company city',
        defaultMessage: 'Leatherhead'
    },
    pmLegalThreeOfFourPCLCompanyCounty: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company county',
        defaultMessage: 'Surrey'
    },
    pmLegalThreeOfFourPCLCompanyPostcode: {
        id: 'quoteandbind.pm.views.pm-legal3-information.Company postcode',
        defaultMessage: 'KT22 8UX'
    },
    popupNameOfAccHolder: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Name of account holder',
        defaultMessage: 'Name(s) of Account Holder(s)'
    },
    popupBankAccNumber: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Bank account number',
        defaultMessage: 'Bank/Building Society account number'
    },
    popupBranchSortCode: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Branch sort code',
        defaultMessage: 'Branch Sort Code'
    },
    popupFullNameAddressOfBank: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Full name and address of bank',
        defaultMessage: 'Name and full postal address of Bank or Building Society'
    },
    popupInstructionToBank: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Instruction to your Bank',
        defaultMessage: 'Instruction to your Bank or Building Society to pay by Direct Debit'
    },
    popupServiceNumber: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Service User Number',
        defaultMessage: 'Service User Number'
    },
    popupReference: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.Reference',
        defaultMessage: 'Reference'
    },
    popupInstructionToBank_title: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.instruction to bank title',
        defaultMessage: 'Instruction to your Bank or Building Society'
    },
    popupInstructionToBank_info: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.instruction to bank info',
        defaultMessage: 'Please pay Premium Credit Limited Direct Debits from the account detailed in this instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with Premium Credit Limited and, if so, details will be passed electronically to my Bank/Building Society'
    },
    popupDate: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.date',
        defaultMessage: 'Date'
    },
    popupDay: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.day',
        defaultMessage: 'Day '
    },
    popupMonth: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.month',
        defaultMessage: 'Month '
    },
    popupYear: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.year',
        defaultMessage: 'Year '
    },
    popupPrint: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.print',
        defaultMessage: 'Print'
    },
    popupDDDisclaimer: {
        id: 'quoteandbind.pm.views.pm-legal3-information.modal.DD disclaimer',
        defaultMessage: 'The details of your Direct Debit Instruction will be sent to you within 3 working days or no later than 5 working days before the collection'
    },
    theseAreDetailsDdiTYA: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD details title.tya',
        defaultMessage: 'These are the details you have entered to set up the Direct Debit Instruction:'
    },
    theseAreDetailsDdiKFI: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD details title.kfi',
        defaultMessage: '<strong>These are the details you have entered to set up the Direct Debit Instruction:</strong>'
    },
    legal3of4InfoDDMandateNotice3TYA: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD details mandate notice.tya',
        defaultMessage: 'The company name which will appear on your bank statement against the Direct Debit will be PC/AIOI INSURANCE. Your Direct Debit Instruction will be confirmed to you by email within 3 working days. Alternatively the confirmation letter incorporating your advance notice will be received by you no later than 5 working days prior to the first collection date. If you are to receive this by post it will be sent to the address you have provided for your insurance policy.'
    },
    legal3of4InfoDDMandateNotice3KFI: {
        id: 'quoteandbind.pm.views.pm-legal3-information.DD details mandate notice.kfi',
        defaultMessage: 'The company name which will appear on your bank statement against the Direct Debit will be PC/KWIKFITINS. Your Direct Debit Instruction will be confirmed to you by email within 3 working days. Alternatively the confirmation letter incorporating your advance notice will be received by you no later than 5 working days prior to the first collection date. If you are to receive this by post it will be sent to the address you have provided for your insurance policy.'
    },
});
