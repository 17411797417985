import { defineMessages } from 'react-intl';

export default defineMessages({
    benefitsTitle: {
        id: 'quoteandbind.pm.components.benefits.Title',
        defaultMessage: 'Your policy includes these excellent benefits as standard:',
    },
    toyLexParts: {
        id: 'quoteandbind.pm.components.benefits.toy lex parts',
        defaultMessage: 'Genuine Toyota / Lexus Parts+ by Toyota Insurance Services approved repairers, protecting any existing warranties.',
    },
    misfuellingKFI: {
        id: 'quoteandbind.pm.components.benefits.misfuelling.kfi',
        defaultMessage: 'Misfuelling Cover - Up to £250 cover to clean the fuel system and other associated parts of your car if you fill your car with an inappropriate grade or type of fuel',
    },
    keyProtect: {
        id: 'quoteandbind.pm.components.benefits.Key Protect',
        defaultMessage: 'Up to £1,500 cover for loss and theft of keys',
    },
    courtesyCar: {
        id: 'quoteandbind.pm.components.benefits.Courtesy Car',
        defaultMessage: 'Courtesy car insurance cover*',
    },
    uninsuredDriver: {
        id: 'quoteandbind.pm.components.benefits.Uninsured Driver',
        defaultMessage: 'Uninsured Driver Benefit cover**',
    },
    vandalismCover: {
        id: 'quoteandbind.pm.components.benefits.Vandalism Cover',
        defaultMessage: "Vandalism Promise - If you make a claim for malicious damage, you won't lose your NCD",
    },
    drivingAbroadTYA: {
        id: 'quoteandbind.pm.components.benefits.Driving Abroad.tya',
        defaultMessage: '3 day EU comprehensive driving cover, with the option to extend to 90 days for an additional premium',
    },
    drivingAbroadKFI: {
        id: 'quoteandbind.pm.components.benefits.Driving Abroad.kfi',
        defaultMessage: '3 day EU comprehensive driving cover, with the option to extend with premium cover',
    },
    ncdProtection: {
        id: 'quoteandbind.pm.components.benefits.NCD Protection',
        defaultMessage: "Have more than 2 years No Claim Discount (NCD)? - We'll protect your NCD for free!",
    },
    benefitsNote_Applies: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.applies',
        defaultMessage: "+ Applies to Toyota / Lexus cars only. Excludes windscreen / glass replacement",
    },
    benefitsNote_1TYA: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.1.tya',
        defaultMessage: "*If you make a claim for malicious damage, you won't lose your NCD",
    },
    benefitsNote_1KFI: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.1.kfi',
        defaultMessage: '*Provided by one of our approved repairers for the duration of the repair. Subject to availability.',
    },
    benefitsNote_2: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.2',
        defaultMessage: "**if you make a claim for an accident that is not your fault, and the driver of the other vehicle that hits you is not insured, you won't lose your NCD, or have to pay an excess",
    },
    benefitsNote_IPID_preLink: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.preLink',
        defaultMessage: 'Please read the ',
    },
    benefitsNote_IPID_link: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.link',
        defaultMessage: 'Insurance Product Information Document',
    },
    benefitsNote_IPID_postLinkTYA: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.postLink.tya',
        defaultMessage: ' to understand the benefits, limitations and exclusions of our cover.',
    },
    benefitsNote_IPID_postLinkKFI: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.postLink.kfi',
        defaultMessage: ' to understand the main benefits, limitations and exclusions of the cover',
    },
});
