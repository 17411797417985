import { defineMessages } from 'react-intl';

export default defineMessages({
    paPolicyInfo: {
        id: 'quoteandbind.views.policyinfo.Personal Auto Policy Information',
        defaultMessage: 'Personal Auto Policy Information',
    },
    paPolicyInformation: {
        id: 'quoteandbind.pa.views.pa-policy-info.Policy Information',
        defaultMessage: 'Policy Information',
    },
    coverageStartDate: {
        id: 'quoteandbind.pa.views.pa-policy-info.Coverage Start Date',
        defaultMessage: 'Coverage Start Date',
    },
    vehicles: {
        id: 'quoteandbind.pa.views.pa-policy-info.Vehicles',
        defaultMessage: 'Vehicles'
    },
    name: {
        id: 'quoteandbind.pa.views.pa-policy-info.Name',
        defaultMessage: 'Name'
    },
    phoneNumber: {
        id: 'quoteandbind.pa.views.pa-policy-info.Phone Number',
        defaultMessage: 'Phone Number'
    },
    emailAddress: {
        id: 'quoteandbind.pa.views.pa-policy-info.Email Address',
        defaultMessage: 'Email Address'
    },
    noDriversExist: {
        id: 'quoteandbind.pa.views.pa-policy-info.No Drivers Exist',
        defaultMessage: 'No Drivers Exist...',
    },
    primaryDriverContactDetails: {
        id: 'quoteandbind.pa.views.pa-policy-info.Primary Driver Contact Details',
        defaultMessage: 'Primary Driver Contact Details',
    },
    drivers: {
        id: 'quoteandbind.pa.views.pa-policy-info.Drivers',
        defaultMessage: 'Drivers',
    },
    displayName: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.Name',
        defaultMessage: 'Name'
    },
    licenseNumber: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.License Number',
        defaultMessage: 'License Number'
    },
    driverlicenseState: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.License State',
        defaultMessage: 'License State'
    },
    make: {
        id: 'quoteandbind.pa.views.pa-policy-info.Make',
        defaultMessage: 'Make',
    },
    noVehiclesExist: {
        id: 'quoteandbind.pa.views.pa-policy-info.No Vehicles Exist',
        defaultMessage: 'No Vehicles Exist...',
    },
    model: {
        id: 'quoteandbind.pa.views.pa-policy-info.Model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'quoteandbind.pa.views.pa-policy-info.Year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'quoteandbind.pa.views.pa-policy-info.License Plate',
        defaultMessage: 'License Plate'
    },
    vehicleLicenseState: {
        id: 'quoteandbind.pa.views.pa-policy-info.License State',
        defaultMessage: 'License State'
    }
});
