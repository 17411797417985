import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class UserProfileService {
    static hasUserAccountBeenActivated(userSessionID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('userprofile'),
            'hasUserAccountBeenActivated',
            [userSessionID, quoteID],
            additionalHeaders
        );
    }

    static hasAccountSetupExpired(userSessionID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('userprofile'),
            'hasAccountSetupExpired',
            [userSessionID, quoteID],
            additionalHeaders
        );
    }

    static canUserAccessAccount(userSessionID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('userprofile'),
            'canUserAccessAccount',
            [userSessionID, quoteID],
            additionalHeaders
        );
    }

    static setupUserAccount(userSessionID, quoteID, password, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('userprofile'),
            'setupUserAccount',
            [userSessionID, quoteID, password],
            additionalHeaders
        );
    }
}