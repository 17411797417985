import _ from 'lodash';
import messages from './QuestionSetComponent.messages';

/**
 * Search for questions only, ignoring answers and code
 * @ignore
 * @param {string} key
 * @returns {boolean}
 */
function filterQuestionSets(key) {
    return key !== 'answers' && key !== 'code';
}

/**
 * Returns presentation metadata based on viewModel and questionSets properties
 * @ignore
 * @param {object} viewModel - the question set view model
 * @returns {object} - Presentation metadata used to render questionSets fields
 */
export default function generateQuestionSetsPresentationMetadata(viewModel) {
    const pageContent = [];
    _.forOwn(viewModel, (question, key) => {
        if (filterQuestionSets(key) && viewModel[key]) {
            const field = viewModel[key];
            const metadataContent = {
                id: key,
                type: 'field',
                componentProps: {
                    label: { id: field.label, defaultMessage: field.label },
                    layout: 'reversed',
                    path: key
                }
            };

            if (field.aspects.inputCtrlType === 'typelist') {
                metadataContent.componentProps.placeholder = messages.typeListPlaceholder;
            }

            pageContent.push(metadataContent);
        }
    });
    return {
        content: [
            {
                id: 'questionSetGridId',
                type: 'container',
                component: 'div',
                contentLayout: { component: 'grid' },
                content: pageContent
            }
        ]
    };
}
