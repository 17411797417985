import React from 'react';
import createHOC from 'react-create-hoc';
import AuthContextProvider from './AuthContextProvider';

const withAuthContextProvider = createHOC(
    'withAuthContextProvider',
    (ComponentToWrap, authProps) => {
        return function WithAuthContextProvider(props) {
            return (
                <AuthContextProvider {...authProps}>
                    <ComponentToWrap {...props} />
                </AuthContextProvider>
            );
        };
    },
    { factory: true, allowExtraProps: true }
);

export default withAuthContextProvider;
