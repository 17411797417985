import { brandingData } from "gw-portals-branding-js";

const brand = brandingData.BRANDING;
const DEFAULT_BRAND_ICON_NAMES = {
    tya: 'toyota-white-logo',
    kfi: 'main-logo'
};

function importAll(r) {
    return r.keys().map(r);
}

function getBrandIconNames() {
    if (brand === 'tya') {
        return importAll(
            require.context('../../../../applications/quote-and-buy/public/html/branding/tya/', false, /\.(svg|png)$/)
        );
    } if (brand === 'kfi') {
        return importAll(
            require.context('../../../../applications/quote-and-buy/public/html/branding/kfi/', false, /\.(svg|png)$/)
        );
    }
    return [];
}

export default {
    getBrandIconUrl() {
        const iconNamePrefix = DEFAULT_BRAND_ICON_NAMES[brand];
        const iconUrlStartString = `/common/${iconNamePrefix}`;
        const iconList = getBrandIconNames(brand);
        let logoUrl = '';
        iconList.forEach((icon) => {
            if (icon?.default === undefined) {
                if (icon?.includes(iconUrlStartString)) {
                    logoUrl = icon;
                }
            } else {
                if (icon?.default.includes(iconUrlStartString)) {
                    logoUrl = icon.default;
                }
            }
        });
        return logoUrl;
    }
};
