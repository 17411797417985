/* eslint-disable max-len */
import React, {
    useCallback, useMemo, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { Submission } from 'gw-capability-quoteandbind';
import PMMonthlyRepresentativeExampleComponent from '../PMMonthlyRepresentativeExampleComponent/PMMonthlyRepresentativeExampleComponent';
import { Segmentation, SegmentationOption } from 'gw-portals-segmentation-react';
import { brandingData } from 'gw-portals-branding-js';

import metadata from './PMPaymentPlansComponent.metadata.json5';
import styles from './PMPaymentPlansComponent.module.scss';

const ANNUAL_PLAN = 'annual';
const INSTALLMENT_PLAN = 'monthly';
const params = {
    compareBy: brandingData.BRANDING
};
function PMPaymentPlansComponent(props) {
    const {
        data: submissionVM,
        updateWizardData,
        path,
        id,
        index,
        showOptional,
        labelPosition,
        onValidate,
        onValueChange,
        phoneWide,
        onUpdatePaymentPlan,
        onHandleSelectedPlanBln,
        isAddonsClicked,
        submitted
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMPaymentPlansComponent');
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);
    const annualPremium = _.get(submissionVM, 'quoteData.value.offeredQuotes[0].premium.total.amount').toFixed(2);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);
    const [transactionFee, setTransactionFee] = useState(null);
    const submission = useMemo(() => new Submission(submissionVM.value), [submissionVM.value]);
    const [installmentPlanDetails, setInstallmentPlanDetails] = useState(submission.getInstallmentsPaymentPlan_AND());
    const firstUpdateOnPageLoad = useRef(true);
    const [isQuoteUpdating, setIsQuoteUpdating] = useState(false);
    const updatePaymentPlans = () => {
        const newInstallmentPlanDetails = submission.getInstallmentsPaymentPlan_AND();
        setInstallmentPlanDetails(newInstallmentPlanDetails);
        if (newInstallmentPlanDetails) {
            const transactionFee = (newInstallmentPlanDetails.fees_itb.amount).toFixed(2);
            setTransactionFee(transactionFee);
        }
    };
    useEffect(() => {
        updatePaymentPlans();
    }, [submissionVM.value.bindData.paymentPlans]);
    const PaymentFieldavailableValues = [
        {
            code: ANNUAL_PLAN,
            displayName: (
                <div className={styles.oneTimePaymentPlan}>
                    <h4 className={styles.paymentLabel}>One payment</h4>
                    <div className={styles.premiumAmount}><strong>{`£${annualPremium}`}</strong></div>
                </div>
            )
        },
        {
            code: INSTALLMENT_PLAN,
            displayName: (
                (installmentPlanDetails ? (
                    (!isQuoteUpdating && selectedPaymentPlan !== null && !isAddonsClicked) ? (
                        <div className={styles.paymentPlans}>
                            <span className={styles.paymentLabel}>Monthly Payments</span>
                            <p><strong>{`10 monthly payments of £${installmentPlanDetails && installmentPlanDetails.installment.amount.toFixed(2)} plus deposit payable now of £${installmentPlanDetails && installmentPlanDetails.downPayment.amount.toFixed(2)}`}</strong></p>
                            <PMMonthlyRepresentativeExampleComponent installmentPlanDetails={installmentPlanDetails} />
                        </div>
                    ) : (
                        <div className={styles.paymentPlans}>
                            <span className={styles.paymentLabel}>Monthly Payments</span>
                            <p>Select monthly payments to view your insurance premium.</p>
                        </div>
                    )
                )
                : (
                    <div className={styles.paymentPlans}>
                        <span className={styles.paymentLabel}>Monthly Payments</span>
                        <p>Sorry - monthly Instalments are not available for this quote.</p>
                    </div>
                )
                )
            ),
        }
    ];

    const handlePaymentOptionChange = useCallback((code) => {
        if (code === null) {
            setSelectedPaymentPlan(null);
            return;
        }
        if (code === INSTALLMENT_PLAN) {
            if (isAddonsClicked) {
                setIsQuoteUpdating(true);
                onHandleSelectedPlanBln(true);
                onUpdatePaymentPlan('quoteData.offeredQuotes[0]', submissionVM.lobData.personalMotor.coverables.pmVehicles.value[0].voluntaryExcess).then(() => {
                    setIsQuoteUpdating(false);
                });
            }
        }
        const selectedPaymentPlanNew = code === ANNUAL_PLAN ? submission.getAnnualPaymentPlan_AND() : submission.getInstallmentsPaymentPlan_AND();
        submissionVM.value.bindData.selectedPaymentPlan = selectedPaymentPlanNew;
        submissionVM.quoteData.value.offeredQuotes[0].premium.total.amount = parseFloat(annualPremium);
        updateWizardData(submissionVM);
        setSelectedPaymentPlan(code);
    }, [annualPremium, isAddonsClicked, onHandleSelectedPlanBln, onUpdatePaymentPlan, submission, submissionVM, updateWizardData]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'PMPaymentPlansComponent');
        }
        updatePaymentPlans();
        if (!submissionVM.value.bindData.selectedPaymentPlan) {
            handlePaymentOptionChange(ANNUAL_PLAN);
        } else if (submissionVM.value.bindData.selectedPaymentPlan.name === 'Annual Payment Plan') {
            setSelectedPaymentPlan(ANNUAL_PLAN);
        } else {
            setSelectedPaymentPlan(INSTALLMENT_PLAN);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdateOnPageLoad.current) {
            firstUpdateOnPageLoad.current = false;
            return;
        }
        if (isAddonsClicked && selectedPaymentPlan !== ANNUAL_PLAN) {
            handlePaymentOptionChange(null);
        }
    }, [isAddonsClicked]);

    const overrideProps = {
        [`ChoosePaymentWrapper${index}`]: {
            visible: !isQuoteUpdating && selectedPaymentPlan !== null && !isAddonsClicked
        },
        [`totalCostOfPaymentFee${index}`]: {
            label: `The total cost to you for paying by monthly direct debits is the Transaction fee = £${transactionFee}.`,
            className: styles.transactionFee
        },
        [`paymentOptions${index}`]: {
            availableValues: PaymentFieldavailableValues,
            onValueChange: handlePaymentOptionChange,
            value: selectedPaymentPlan,
            disabled: isQuoteUpdating
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handlePaymentOptionChange}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={submitted}
        >
        {installmentPlanDetails
            ? ( <PMMonthlyRepresentativeExampleComponent installmentPlanDetails={installmentPlanDetails} /> )
            : (
                <div className={styles.paymentPlans}>
                    <span className={styles.paymentLabel}>Monthly Payments</span>
                    <p>Sorry - monthly Instalments are not available for this quote.</p>
                </div>
            )
        }
        </ViewModelForm>
    );
}
PMPaymentPlansComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMPaymentPlansComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMPaymentPlansComponent;
