import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutyou: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.about-you',
        defaultMessage: 'About you'
    },
    driver: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.about-the-driver',
        defaultMessage: 'About the driver'
    },
    vehicles: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.Vehicles',
        defaultMessage: 'About your car'
    },
    quote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.Quote',
        defaultMessage: 'Your quote'
    },
    legalpayment: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.legal-and-payment',
        defaultMessage: 'Legal and payment'
    },
    modalTitle: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.modal-title',
        defaultMessage: 'Going Somewhere?'
    },
    modalMessage: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.modal-message',
        defaultMessage: 'Any changes you have made on this page could be lost in the dawns of time! Make sure you save your progress before you leave the page.'
    },
    modalOkButtonText: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.modal-ok-button-text',
        defaultMessage: 'Leave Page'
    },
    modalCloseButtonText: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pm.modal-close-button-text',
        defaultMessage: 'Cancel'
    }
});
