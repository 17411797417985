import React, {useEffect, useState} from "react";
import { addEventListeners,  removeEventListeners } from './util/eventListenerUtil'
import { Button, ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import { useHistory } from 'react-router-dom';
import config from 'app-config';

const sessionEnabledSteps = [
  'about-you',
  'driver-details',
  'driver-summary',
  'vehicle-details',
  'quote',
  'legal-and-info-1',
  'legal-and-info-2',
  'legal-and-info-2-monthly',
  'legal-and-info-3',
  'payment'
];

export const SessionService = () => {
  const history = useHistory();
  const IDLE_TIMEOUT = config.sessionConfig.inactivityIntervalMins * 1000 * 60;
  const SESSION_TIMEOUT = config.sessionConfig.sessionIntervalMins * 1000 * 60;
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const handleReturnToQuote = () => {
    setWarningModalOpen(false);
    createIdleTimeout();
  };

  useEffect(() => {
    const createIdleTimeout = () => setTimeout(()=>{ 
      if(isSessionEnabled(window.location.pathname)) {
        setWarningModalOpen(true);
      }
    },IDLE_TIMEOUT);

    const createSessionTimeout = () => setTimeout(() => {
      if(isSessionEnabled(window.location.pathname)) {
        sessionEnded();
      }
    },SESSION_TIMEOUT);

    const listener = () => {
      if(!isWarningModalOpen){
        clearTimeout(timeout);
        timeout = createIdleTimeout();
      }
    };
    
    const isSessionEnabled = (stepPath) => {
      const stepName = stepPath.replaceAll('/','').replace('quoteandbuy','');
      return sessionEnabledSteps.includes(stepName);
    };

    const sessionEnded = () => {
      window.sessionStorage.clear();
      setWarningModalOpen(false);
      history.push({
          pathname: '/timeout',
      });
    };

    // Initialization
    let timeout = isWarningModalOpen  ? createSessionTimeout() : createIdleTimeout();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[isWarningModalOpen]);

  return (
    <div>
      {isWarningModalOpen && (
        <ModalNext isOpen={isWarningModalOpen}>
            <ModalHeader onClose={handleReturnToQuote} title='Are you still there?'/>
            <ModalBody>
                <p>We're just checking you are still working on your quote? This page will timeout if there is no activity in the next 30 minutes.</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleReturnToQuote}>Return to Quote</Button>
            </ModalFooter>
        </ModalNext>
        )
      }
    </div>
  ) 
}