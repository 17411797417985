import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { Badge, Tooltip } from '@jutro/components';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'gw-portals-util-js';

import messages from './PaQuoteInfo.messages';
import styles from './PaQuoteInfo.module.scss';

const PATH_TO_MOCK_DATA = 'quote.pa';

function renderVehicles(vehicles = []) {
    if (vehicles.length) {
        return vehicles.map(({ displayName }) => {
            return displayName;
        });
    }
    return ['-'];
}

function renderDrivers(submissionVM, drivers = []) {
    if (drivers.length) {
        return drivers.map(({ person }, index) => {
            const { displayName } = person;
            const pathToDriver = `lobData.personalAuto.coverables.drivers.${index}.person`;
            const isDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
                submissionVM.value,
                PATH_TO_MOCK_DATA,
                `${pathToDriver}.displayName`,
                `${pathToDriver}.firstname`,
                `${pathToDriver}.lastname`
            );
            if (!isDisplayNameMocked) {
                return displayName;
            }
            return ['-'];
        });
    }
    return ['-'];
}

/**
 * Renders a badge if the colection contains more than 1 item
 * @param {Array} collection an array of items
 * @returns {React.ReactNode}
 */
function renderBadge(collection) {
    const size = _.get(collection, 'length', 0);
    if (size > 1) {
        return (
            <Badge
                type="inverse"
                value={size}
                className={commonQuoteStyles.infoBlockBadge}
            />
        );
    }
    return null;
}

function PaQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useTranslator();
    const quoteId = _.get(submissionVM, 'quoteID.value');
    const vehicles = _.get(
        submissionVM,
        'lobData.personalAuto.coverables.vehicles.value',
        []
    ).filter(({ displayName }) => !_.isEmpty(displayName));
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []).filter(
        ({ person }) => !_.isEmpty(person.displayName)
    );

    const address = _.get(submissionVM, 'baseData.policyAddress.value', {});
    const { displayName = '' } = address;

    const isAddressDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
        _.get(submissionVM, 'value'),
        PATH_TO_MOCK_DATA,
        'baseData.policyAddress.displayName',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city'
    );
    const policyAddressToShowInHeader = isAddressDisplayNameMocked
        ? `${address.state} ${address.postalCode}`
        : displayName;

    const vehiclesToDisplay = renderVehicles(vehicles).join(', ');
    const driversToDisplay = renderDrivers(submissionVM, drivers).join(', ');

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.quoteWrapper}>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.personalAutoQuote)}
                    </div>
                    <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                        {quoteId}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.location)}
                    </div>
                    <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>
                        <Tooltip
                            id="policyAddress"
                            placement="bottom"
                            trigger="mouseenter"
                            content={policyAddressToShowInHeader}
                        >
                            <div className={commonQuoteStyles.infoOverflow}>
                                {policyAddressToShowInHeader}
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.vehicles)}
                        {renderBadge(vehicles)}
                    </div>
                    <div id="vehicles" className={commonQuoteStyles.infoBlockContent}>
                        {vehiclesToDisplay !== '-' ?
                            <Tooltip
                                id="vehicleDetails"
                                placement="bottom"
                                trigger="mouseenter"
                                content={vehiclesToDisplay}
                            >
                                <div className={commonQuoteStyles.infoOverflow}>
                                    {vehiclesToDisplay}
                                </div>
                            </Tooltip>
                            : vehiclesToDisplay}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.drivers)}
                        {renderBadge(drivers)}
                    </div>
                    <div id="drivers" className={commonQuoteStyles.infoBlockContent}>
                        {driversToDisplay !== '-' ?
                            <Tooltip
                                id="driverDetails"
                                placement="bottom"
                                trigger="mouseenter"
                                content={driversToDisplay}
                            >
                                <div className={commonQuoteStyles.infoOverflow}>
                                    {driversToDisplay}
                                </div>
                            </Tooltip>
                            : driversToDisplay}
                    </div>
                </div>
            </div>
        </div>
    );
}

PaQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default PaQuoteInfo;
