/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PMDriverComponent.metadata.json5';
import ValidationUtil from '../../../gw-capability-quoteandbind-pm-react/util/ValidationUtil';
import styles from './PMDriverComponent.module.scss';
import messagesTranslationsSet from './PMDriverComponent.messages';

function PMDriverComponent(props) {
    const {
        driver: driverVM,
        labelPosition,
        showOptional,
        phoneWide,
        path: dataPath,
        id,
        index: driverIndex,
        onValidate,
        onValueChange,
        cancelDriverClick,
        minimumAllowedDateOfBirthDriver,
        maximumAllowedDateOfBirthDriver,
        onDriverNameValueChange,
        driverUkResidentMonthIndex,
        handleDriverUkResidentLessThan5YearsValueChange,
        dateForUkResidentLessThan5Years,
        submitted,
        handleDriverGenderValueChange,
        handleDriverLicenseTypeChange,
        handleDriverPassedTestValueChange,
        handleDriverFirstdroveValueChange,
        driverProvisonalLicenseMonthYear,
        driverPassedTestMonthYear,
        driverHasFullLicence,
        minAllowedToDriveDateDriver,
        driversCanHave10YearsExp,
        addDriverConviction,
        addDriverClaim,
        continueDriverClick,
        submissionVM,
        updateWizardData,
        disregardFieldValidation,
        handleDOBValueChange
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMDriverComponent');
    const isPolicyHolder = (typeof driverVM !== 'undefined' && driverVM && Object.keys(driverVM).length > 0) ? (driverVM.isPolicyHolder === undefined || driverVM.isPolicyHolder.value === true) : false;
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM);

    const handleOnValueChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        onValueChange(value, fullPath);
    };
    const onCancelDriverClick = () => {
        cancelDriverClick();
    };
    const onContinueDriverClick = () => {
        continueDriverClick();
    };
    const removeDriverClaim = useCallback(
        (index) => {
            const claimListPath = `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmClaims.value`;
            const claimList = _.get(submissionVM, claimListPath);
            claimList.splice(index, 1);
            if (_.get(submissionVM, claimListPath).length <= 0) {
                _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].hasMotorClaims.value`, false);
            }
            disregardFieldValidation(`driverPMClaimsComponent${index}`);
            updateWizardData(submissionVM);
        },
        [disregardFieldValidation, driverIndex, submissionVM, updateWizardData]
    );
    const removeDriverConviction = useCallback(
        (index) => {
            const convictionListPath = `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmMotorConvictions.value`;
            const convictionList = _.get(submissionVM, convictionListPath);
            convictionList.splice(index, 1);
            if (_.get(submissionVM, convictionListPath).length <= 0) {
                _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].hasMotorConvictions.value`, false);
            }
            disregardFieldValidation(`driverPMConvictionsComponent${index}`);
            updateWizardData(submissionVM);
        },
        [disregardFieldValidation, driverIndex, submissionVM, updateWizardData]
    );
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];

    const handleDriverMotorConvictionsValueChange = useCallback((value, path) => {
        const fullPath = `${dataPath}.children[${driverIndex}].${path}`;
        _.set(submissionVM, `${fullPath}.value`, value);
        if (value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmMotorConvictions.value`, []).length <= 0) {
            addDriverConviction();
        } else if (!value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmMotorConvictions.value`, []).length > 0) {
            _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmMotorConvictions.value`, []).forEach((conviction, index) => {
                disregardFieldValidation(`driverPMConvictionsComponent${index}`);
            });
            _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmMotorConvictions.value`, []);
        }
        updateWizardData(submissionVM);
    }, [addDriverConviction, dataPath, disregardFieldValidation, driverIndex, submissionVM, updateWizardData]);
    const handleDriverMotorClaimsValueChange = useCallback((value, path) => {
        const fullPath = `${dataPath}.children[${driverIndex}].${path}`;
        _.set(submissionVM, `${fullPath}.value`, value);
        if (value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmClaims.value`, []).length <= 0) {
            addDriverClaim();
        } else if (!value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmClaims.value`, []).length > 0) {
            _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmClaims.value`, []).forEach((claim, index) => {
                disregardFieldValidation(`driverPMClaimsComponent${index}`);
            });
            _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].pmClaims.value`, []);
        }
        updateWizardData(submissionVM);
    }, [addDriverClaim, dataPath, disregardFieldValidation, driverIndex, submissionVM, updateWizardData]);
    const generateOverrides = useCallback(() => {
        const convictions = _.get(driverVM, 'pmMotorConvictions.value', []);
        const claims = _.get(driverVM, 'pmClaims.value', []);
        const overrideProps = {};

        convictions.forEach((conviction, index) => {
            overrideProps[`driverPMConvictionsComponent${index}`] = {
                // eslint-disable-next-line max-len
                submitted: submitted,
                convictionCodeValues: submissionVM.lobData.personalMotor.coverables.convictionCodes.value.map((typeCode) => ({
                    code: typeCode,
                    name: typeCode
                })),
            };
            overrideProps[`pmDeleteDriverConviction${index}`] = {
                visible: true,
                onClick: () => {
                    removeDriverConviction(index);
                }
            };
        });
        if (Array.isArray(claims)) {
            claims.forEach((claim, index) => {
                overrideProps[`driverPMClaimsComponent${index}`] = {
                    submitted: submitted,
                };
                overrideProps[`pmDeleteDriverClaim${index}`] = {
                    visible: true,
                    onClick: () => {
                        removeDriverClaim(index);
                    }

                };
            });
        }
        return overrideProps;
    }, [driverVM, submitted, submissionVM, removeDriverConviction, removeDriverClaim]);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, driverIndex);
    }, [driverIndex]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide
        },
        [`inlineNotificationErrorBarAddDriver${driverIndex}`]: {
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).strErrorBannerTextOutput,
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).blnErrorWidgetVisibility
        },
        [`driverMotorConvictions${driverIndex}`]: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleDriverMotorConvictionsValueChange,
            label: {
                id: "platform.inputs.contact-details.drivermotorconvictions",
                defaultMessage: isPolicyHolder ? "Have you had any motoring convictions, disqualifications, driving licence endorsements or fixed penalties in the last 5 years?" : "Have they had any motoring convictions, disqualifications, driving licence endorsements or fixed penalties in the last 5 years?"
              },
            labelPosition: 'top',
        },
        [`addConviction${driverIndex}`]: {
            visible: (typeof driverVM !== 'undefined' && driverVM && Object.keys(driverVM).length > 0) ? _.get(driverVM.value, 'hasMotorConvictions', false) : false,
            onClick: addDriverConviction,
        },

        [`convictionDriversTitleContainer${driverIndex}`]: {
            visible: _.get(driverVM?.value, 'hasMotorConvictions', false),
        },
        [`driverMotorClaims${driverIndex}`]: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleDriverMotorClaimsValueChange,
            label: {
                id: "platform.inputs.contact-details.drivermotorclaims",
                defaultMessage: isPolicyHolder ? "Have you had any motoring claims, accidents or losses in the last 5 years?" : "Have they had any motoring claims, accidents or losses in the last 5 years?"
              },
            labelPosition: 'top',
        },
        [`addClaim${driverIndex}`]: {
            visible: (typeof driverVM !== 'undefined' && driverVM && Object.keys(driverVM).length > 0) ? _.get(driverVM.value, 'hasMotorClaims', false) : false,
            onClick: addDriverClaim,
        },
        [`claimsDriversTitleContainer${driverIndex}`]: {
            visible: _.get(driverVM?.value, 'hasMotorClaims', false),
        },
        [`PMPersonDetailsComponentOfDrivers${driverIndex}`]: {
            path: `${dataPath}[${driverIndex}]`,
            data: driverVM,
            onValueChange: onValueChange,
            onNameValueChange: onDriverNameValueChange,
            handleGenderValueChange: handleDriverGenderValueChange,
            minimumAllowedDateOfBirth: minimumAllowedDateOfBirthDriver,
            maximumAllowedDateOfBirth: maximumAllowedDateOfBirthDriver,
            handleUkResidentLessThan5YearsValueChange: handleDriverUkResidentLessThan5YearsValueChange,
            dateForUkResidentLessThan5Years: dateForUkResidentLessThan5Years,
            ukResidentMonthIndex: driverUkResidentMonthIndex,
            submitted: submitted,
            handleDOBValueChange: handleDOBValueChange
        },
        [`homeOwnerDriver${driverIndex}`]: {
            availableValues: YESNOFieldavailableValues
        },
        [`PMDriverDetailsComponentOfDrivers${driverIndex}`]: {
            path: `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}]`,
            data: driverVM,
            handleLicenseTypeChange: handleDriverLicenseTypeChange,
            handlePassedTestSinceValueChange: handleDriverPassedTestValueChange,
            handleFirstdroveValueChange: handleDriverFirstdroveValueChange,
            provisonalLicenseMonthYear: driverProvisonalLicenseMonthYear,
            passedTestMonthYear: driverPassedTestMonthYear,
            hasFullLicence: driverHasFullLicence,
            minAllowedToDriveDate: minAllowedToDriveDateDriver,
            driverCanHave10YearsExp: driversCanHave10YearsExp,
            submitted: submitted,
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => {
            return readViewModelValue(
                formattedMetadata,
                driverVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [driverVM, formattedMetadata, overrideProps]
    );
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCancelDriverClick: onCancelDriverClick,
            onContinueDriverClick: onContinueDriverClick,
            onRemoveConviction: removeDriverConviction,
            onAddConvictionClick: addDriverConviction,
            onAddClaimClick: addDriverClaim,
            onRemoveClaim: removeDriverClaim,
        }
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleOnValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={submitted}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PMDriverComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMDriverComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMDriverComponent;
