import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ScrollToError } from '@jutro/wizard-next';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { useValidation } from 'gw-portals-validation-react';
import { useModal } from '@jutro/components';
import moment from 'moment';
import htmlParser from 'html-react-parser';
import CookiesModal from "gw-capability-policyjob-react/components/CookiesComponent/CookiesModal";
import ValidationUtil from '../../util/ValidationUtil';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { withRouter } from 'react-router-dom';
import { brandingData } from 'gw-portals-branding-js'
import { TranslatorContext, useTranslator } from '@jutro/locale';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

import metadata from './AboutYouPage.metadata.json5';
import messagesTranslationsSet from './AboutYouPage.messages';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
import styles from './AboutYouPage.module.scss';

function AboutYouPage(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, history, isFromAMP} = props;
    const { onValidate, isComponentValid, initialValidation } = useValidation('AboutYouPage');
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const [submitted, setSubmitted] = useState(false);
    const [dateForUkResidentLessThan5Years, setDateForUkResidentLessThan5Years] = useState(undefined);
    const [ukResidentMonthIndex, setukResidentMonthIndex] = useState(0);
    const [isHomeNumberValid, setIsHomeNumberValid] = useState(ValidationUtil.isHomeNumberValid(submissionVM));
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(ValidationUtil.isPhoneNumberValid(submissionVM));
    const isPhoneNumberLnPValid = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const [shouldReRenderPage, setShouldReRenderPage] = useState(false);
    const [ismodalOpen, setIsModalOpen] = useState(true);
    const getFormattedDate = (date) => {
        if (date != null) {
            const tempDate = new Date(date);
            return { day: tempDate.getDate(), month: tempDate.getMonth(), year: tempDate.getFullYear() };
        }
        else {
            return { day: Date.now().getDate(), month: Date.now().getMonth(), year: Date.now().getFullYear() };
        }
    };

    const convertToDate = (dateObj) => {
        if (dateObj != null) {
            const tempDate = new Date(dateObj.year, dateObj.month, dateObj.day);
            return tempDate;
        }
        else {
            return new Date(Date.now());
        }
    };
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'AboutYouPage', { getFormattedDate: getFormattedDate }, isPhoneNumberLnPValid);
    const {
        showAlert
    } = useModal();
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    const brand = brandingData.BRANDING;
    const hearAboutUsOptionsMapper = {
        kfi: {
            filterOptionPrefix: 'kfi'
        },
        tya: {
            filterOptionPrefix: 'toy'
        }
    };
    const periodStartDate = _.get(submissionVM, 'baseData.periodStartDate.value', null);

    useEffect(() => {
        setIsModalOpen(!getCookie('consent'));
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
        if (accountHolder === undefined) {
            const subtype = 'Person';
            const accountHolder = true;
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, 'baseData.accountHolder.brand_itb', brand);
            _.set(newSubmissionVM.value, 'baseData.accountHolder.subtype', subtype);
            _.set(newSubmissionVM.value, 'baseData.accountHolder.accountHolder', accountHolder);
            updateWizardData(newSubmissionVM);
        } else {
            const monthsList = _.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.aspects.availableValues');
            setDateForUkResidentLessThan5Years(
                _.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.value') && _.get(submissionVM, 'baseData.accountHolder.ukResidencyYear_itb.value') ?
                    {
                        month: monthsList[_.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.value')]?.code,
                        year: _.get(submissionVM, 'baseData.accountHolder.ukResidencyYear_itb.value')
                    } : undefined
            );
        }
        _.set(submissionVM.value, 'baseData.accountHolder.brand_itb', brand.toUpperCase());
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(0));
        if (brand === 'kfi' && isFromAMP) {
            if (_.get(submissionVM.value, 'baseData.purchasedKwikFitProductInLastTwoYears') === undefined || _.get(submissionVM.value, 'baseData.purchasedKwikFitProductInLastTwoYears') === null) {
                _.set(submissionVM.value, 'baseData.purchasedKwikFitProductInLastTwoYears', false);
            }
            if (_.get(submissionVM.value, 'baseData.kwikFitStaffMember') === undefined || _.get(submissionVM.value, 'baseData.kwikFitStaffMember') === null) {
                _.set(submissionVM.value, 'baseData.kwikFitStaffMember', false);
            }
        }
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const reRenderPage = (value) => {
        if (value) {
            setShouldReRenderPage(value);
        }
        setShouldReRenderPage(false);
    };

    const onNext = useCallback(() => {
        if (invalidPropsLength > 0) {
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }

        const QuoteID = _.get(submissionVM.value, 'quoteID');
        if (QuoteID === undefined) {
            _.set(submissionVM.value, 'baseData.accountHolder.brand_itb', brand.toUpperCase());
            return LoadSaveService.createSubmission(submissionVM.value)
                .then((value) => {
                    submissionVM.value = value;
                    window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
                    const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
                    updateWizardData(newUpdatedSubmissionVM);
                    return newUpdatedSubmissionVM;
                })
                .catch((error) => {
                    // re-throw this error within the updater function
                    // it will be triggered during state update
                    history.push({
                        pathname: '/error',
                        data: error,
                        origin: "AboutYouPage [onNext]",
                        quoteID: _.get(submissionVM.value, 'quoteID') || ''
                    });
                    return false;
                });
        }
        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            history.push({
                pathname: '/error',
                data: error
            });
            return false;
        }
    }, [history, invalidPropsLength, submissionVM, updateWizardData, viewModelService]);


    const getBooleanFieldValue = (path) => {
        const booleanVal = _.get(submissionVM, path, false);

        return booleanVal;
    };

    const handleGenderValueChange = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            const genderPath = 'baseData.accountHolder.gender_itb';
            _.set(newSubmissionVM.value, genderPath, value.code);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleDOBValueChange = useCallback(
        (value, path) => {
            const dob = moment({ year: value.year, month: value.month, day: value.day });
            if (!dob.isBetween(moment(maximumAllowedDateOfBirth).startOf('day'), moment(minimumAllowedDateOfBirth).endOf('day'))) {
                showAlert({
                    message: htmlParser('We can’t help on this occasion. Our products are designed for drivers aged 17 – 80, ' +
                        'and therefore we are unfortunately unable to offer you cover.<br/>Please visit the ' +
                        "<a target='blank' href='https://www.biba.org.uk/find-insurance/'><b>British Insurance Brokers' Association</b></a>, " +
                        'where they will have a list of specialist brokers that may be able to assist.'),
                    status: 'error',
                    confirmButtonText: 'OK',
                }).catch(_.noop);
            } else {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handlePeriodStartDateValueChange = useCallback((value, path) => {
        const coverageStartDate = {
            day: value.day,
            month: value.month,
            year: value.year
        };
        _.set(submissionVM.value, 'baseData.periodStartDate', coverageStartDate);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const handleUkResidentLessThan5YearsValueChange = useCallback((value, path) => {
        const monthsList = _.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.aspects.availableValues');
        setukResidentMonthIndex(_.findIndex(monthsList, (mon) => mon.code === _.get(submissionVM.value, 'baseData.accountHolder.ukResidencyMonth_itb')));
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, 'baseData.accountHolder.ukResidencyMonth_itb', monthsList[value.month]?.code);
        _.set(newSubmissionVM.value, 'baseData.accountHolder.ukResidencyYear_itb', value.year);
        updateWizardData(newSubmissionVM);
        setDateForUkResidentLessThan5Years({
            month: value.month,
            year: value.year
        });
    }, [submissionVM, updateWizardData, viewModelService]);

    const onNameValueChange = useCallback(
        (value, path) => {
            const pattern = /^[a-zA-Z-' ]{0,25}$/;
            if (pattern.test(value)) {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onAreYouStaffMemberChange = useCallback((value) => {
        _.set(submissionVM.value, 'baseData.kwikFitStaffMember', value);
        if (!value) _.set(submissionVM.value, 'baseData.kfistaffNumber_itb', '');
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onAreYouMemberChange = useCallback((value) => {
        _.set(submissionVM.value, 'baseData.kwikFitClubMember', value);
        if (!value) _.set(submissionVM.value, 'baseData.kwikFitClubMember', false);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const minimumAllowedDateOfBirth = useMemo(() => {
        const baseDate = new Date(submissionVM.baseData.periodStartDate.year.value, submissionVM.baseData.periodStartDate.month.value, submissionVM.baseData.periodStartDate.day.value);
        const { ageAllowedToDrive } = config.personalAutoConfig;
        baseDate.setFullYear(baseDate.getFullYear() - ageAllowedToDrive);
        return baseDate;
    }, [submissionVM]);

    const maximumAllowedDateOfBirth = useMemo(() => {
        const baseDate = new Date(submissionVM.baseData.periodStartDate.year.value, submissionVM.baseData.periodStartDate.month.value, submissionVM.baseData.periodStartDate.day.value);
        const { maxAgeAllowedToDrive } = config.personalAutoConfig;
        baseDate.setFullYear(baseDate.getFullYear() - maxAgeAllowedToDrive);
        return baseDate;
    }, [submissionVM]);

    const maximumPolicyStartDate = useMemo(() => {
        let baseDate = new Date();
        const { maxDaysInFuturePolicyDate } = config.personalAutoConfig;
        baseDate.setDate(baseDate.getDate() + maxDaysInFuturePolicyDate);
        return baseDate;
    }, [submissionVM]);

    const handleHomeNumChange = useCallback(
        (value, path) => {
            const anyDigitPattern = /^\d*$/;
            const betweenRangePattern = /^\d{4,11}$/;
            if (anyDigitPattern.test(value)) {
                if (betweenRangePattern.test(value) || value === '') {
                    setIsHomeNumberValid(true);
                } else {
                    setIsHomeNumberValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'baseData.accountHolder.homeNumber', value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService, setIsHomeNumberValid]
    );

    const handleMobileNumChange = useCallback(
        (value, path) => {
            const pattern = /^[0-9]{0,11}$/;
            const allowTestNums = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
            if (pattern.test(value)) {
                if (allowTestNums.test(value)) {
                    setIsPhoneNumberValid(true);
                } else {
                    setIsPhoneNumberValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'baseData.accountHolder.cellNumber', value);
                if (_.get(submissionVM, 'bindData.contactPhone.value')) {
                    _.set(newSubmissionVM, 'bindData.contactPhone.value', value);
                }
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleModalActivity = () => {
        setIsModalOpen(!ismodalOpen);
    };

    const isStaffMemberSelected = () => {
        return _.get(submissionVM, 'baseData.kwikFitStaffMember.value') === true;
    };

    const isClubMemberSelected = () => {
        return _.get(submissionVM, 'baseData.kwikFitClubMember.value') === true;
    };

    const getCookie = (name) => {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return false;
    };

    const handleMarketingAnswerValueChange = useCallback((value) => {
        _.set(submissionVM.value, 'baseData.marketingAnswer_itb', value);

        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const overrideProps = {
        /* '@field': {
            // apply to all fields
            //showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'//,
            //showErrors: true
        }, */

        inlineNotificationErrorBarAboutYou: {
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).strErrorBannerTextOutput,
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).blnErrorWidgetVisibility
        },
        homeowner: {
            readOnly: isFromAMP,
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('baseData.accountHolder.homeOwner_itb.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.accountHolder.homeOwner_itb.aspects.required')
        },
        areYouKFIMember: {
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('baseData.kwikFitClubMember.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.kwikFitClubMember.aspects.required'),
            onValueChange: onAreYouMemberChange,
        },
        KFIClubCardNumber: {
            visible: isClubMemberSelected(),
            required: isClubMemberSelected(),
            showRequired: getBooleanFieldValue('baseData.kficlubCardNumber_itb.aspects.required'),
            validationMessages: (submissionVM.baseData.kficlubCardNumber_itb.value == '' || submissionVM.baseData.kficlubCardNumber_itb.value == null) ? ['This field is required'] : [],
            validator: {
                pattern: /^KFC-R-\d{11}$/,
                message: {
                    id: 'clubNumberValidationMessage',
                    defaultMessage: "This field is required"
                }
            },
        },
        kwikFitStaffMember: {
            readOnly: isFromAMP,
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('baseData.kwikFitStaffMember.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.kwikFitStaffMember.aspects.required'),
            onValueChange: onAreYouStaffMemberChange,
        },
        kfiStaffNumber: {
            visible: isStaffMemberSelected(),
            required: isStaffMemberSelected(),
            showRequired: getBooleanFieldValue('baseData.kfistaffNumber_itb.aspects.required'),
            validationMessages: (submissionVM.baseData.kfistaffNumber_itb.value == '' || submissionVM.baseData.kfistaffNumber_itb.value == null) ? ['This field is required'] : [],
            validator: {
                pattern: /^\d{5}$/,
                message: {
                    id: 'staffNumberValidationMessage',
                    defaultMessage: "This field is required"
                }
            },
        },
        purchasedKwikFitProductInLastTwoYears: {
            readOnly: isFromAMP,
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('baseData.purchasedKwikFitProductInLastTwoYears.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.purchasedKwikFitProductInLastTwoYears.aspects.required')
        },
        PMPersonDetailsComponentAboutYouPage: {
            onValueChange: writeValue,
            path: 'baseData.accountHolder',
            data: submissionVM.baseData.accountHolder,
            onNameValueChange: onNameValueChange,
            handleGenderValueChange: handleGenderValueChange,
            minimumAllowedDateOfBirth: minimumAllowedDateOfBirth,
            maximumAllowedDateOfBirth: maximumAllowedDateOfBirth,
            ukResidentMonthIndex: ukResidentMonthIndex,
            handleUkResidentLessThan5YearsValueChange: handleUkResidentLessThan5YearsValueChange,
            dateForUkResidentLessThan5Years: dateForUkResidentLessThan5Years,
            submitted: submitted,
            handleDOBValueChange: handleDOBValueChange,
            isFromAMP: isFromAMP
        },
        'mobile-field': {
            readOnly: isFromAMP,
            validationMessages: (submissionVM.baseData.accountHolder.cellNumber.value == '' || submissionVM.baseData.accountHolder.cellNumber.value == null) ? ['This field is required'] : [],
            validator: {
                pattern: /^(07700900[0-9]{3})|(07[0-9]{9})$/,
                message: {
                    id: 'mobileValidationMessage',
                    defaultMessage: 'Value entered must be a valid phone number'
                }
            },
            onValueChange: handleMobileNumChange,
            required: getBooleanFieldValue('baseData.accountHolder.cellNumber.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.accountHolder.cellNumber.aspects.required')
        },
        'landline-field': {
            readOnly: isFromAMP,
            validator: {
                pattern: /^[0-9]{4,11}$/,
                message: {
                    id: 'landlineValidationMessage',
                    defaultMessage: 'Value entered must be a valid phone number'
                }
            },
            onValueChange: handleHomeNumChange,
            validationMessages: []
        },
        periodStartDate: {
            onValueChange: handlePeriodStartDateValueChange,
            required: getBooleanFieldValue('baseData.periodStartDate.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.periodStartDate.aspects.required'),
            minDate: getFormattedDate(Date.now()),
            maxDate: maximumPolicyStartDate,
            defaultValue: getFormattedDate(Date.now()),
            value: getFormattedDate(convertToDate(submissionVM.baseData.periodStartDate.value))
        },
        addresslookup: {
            // Use custom onValueChange handler to avoid state comparison issue
            // in AddressLookup component due to the use of _.set() in Jutro onFieldValueChange,
            onValueChange: writeValue,
            submitted: submitted,
            reRenderPage: reRenderPage,
            required: getBooleanFieldValue('baseData.policyAddress.postalCode.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.policyAddress.postalCode.aspects.required'),
            isFromAMP: isFromAMP
        },
        emailAddress: {
            readOnly: isFromAMP,
            validationMessages: (submissionVM.baseData.accountHolder.emailAddress1.value == '' || submissionVM.baseData.accountHolder.emailAddress1.value == null) ? ['This field is required'] : [],
            validator: {
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/,
                message: {
                    id: 'emailValidationMessage',
                    defaultMessage: "This field is required"
                }
            },
            required: getBooleanFieldValue('baseData.accountHolder.emailAddress1.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.accountHolder.emailAddress1.aspects.required')
        },
        pmPrivacyNoticeIntroLink: {
            href: DocUtil.getFileUrl(brand, 'PRIVACY_NOTICE', periodStartDate)
        },
        introduction2: {
            content: htmlParser(translator(messagesTranslationsSet[`aboutYouIntro2${brand.toUpperCase()}`].defaultMessage))
        },
        howDidYouHearAboutUs: {
            onValueChange: handleMarketingAnswerValueChange,
            required: getBooleanFieldValue('baseData.marketingAnswer_itb.aspects.required'),
            showRequired: getBooleanFieldValue('baseData.marketingAnswer_itb.aspects.required'),
            // eslint-disable-next-line max-len
            availableValues: submissionVM.baseData.marketingAnswer_itb.aspects.availableValues[0].typelist.getFilter(`${hearAboutUsOptionsMapper[brand].filterOptionPrefix}Options`).codes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            }))
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate,
            writeValue: writeValue
        }
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "AboutYouPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                showPrevious={false}
                skipWhen={initialValidation}
                disableNext={false}
                nextLabel={'Continue'}
                disableCancel={true}
                cancelLabel={''}
            >
                <CookiesModal
                    ismodalOpen={ismodalOpen}
                    handleModalActivity={handleModalActivity}
                    getCookie={getCookie}
                    periodStartDate={periodStartDate}
                />
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    showErrors={submitted}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

AboutYouPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(AboutYouPage));
