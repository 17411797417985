import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { wizardStepProps } from './prop-types/wizardPropTypes';

function renderRoutes({ basePath, steps, propsToPass }) {
    return steps.map((step) => {
        const { component: Component, path } = step;
        const composedPath = [basePath, path].join('');
        return (
            <Route key={path} path={composedPath} render={() => <Component {...propsToPass} />} />
        );
    });
}

/**
 * Use this to render the wizard routes composed by the provided steps.
 *
 * @function WizardRoutes
 * @ignore
 *
 * @override
 *
 * @prop {String} basePath - where the parent wizard is based
 * @prop {Array.<WizardStepProps>} steps - steps composing the routes
 *
 * @example
 *  const steps = [
 *      {
 *          title: 'one',
 *          path: 'step1',
 *          component: PageOne
 *      },
 *      {
 *          title: 'two',
 *          path: 'step2',
 *          component: PageTwo
 *      },
 *      {
 *          title: 'three',
 *          path: 'step3',
 *          component: PageThree
 *      }
 *  ];
 *
 *  const basePath = '/'
 *
 *  <WizardRoutes basePath={basePath} steps={steps} />
 *
 * @returns {ReactElement}
 */
const WizardRoutes = (props) => {
    const { steps, basePath } = props;
    return <Switch>{renderRoutes({ steps, basePath, propsToPass: props })}</Switch>;
};

WizardRoutes.propTypes = {
    /**
     * where the parent wizard is based
     */
    basePath: PropTypes.string.isRequired,
    /**
     * steps composing the routes
     */
    steps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired
};

export default WizardRoutes;
