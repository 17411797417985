import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { MockUpUtil } from 'gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';

import metadata from './QuotePage.metadata.json5';

const PATH_TO_MOCK_DATA = 'quote.pa';
const MOCK_DATA_TO_SET = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

function structureClauseTableData(coverage, index = '') {
    // putting ID into an object as the Jutro table component expects an object
    return {
        coverageUniqueID: `${coverage.publicID}${index}`
    };
}

function generateClauseData({ columnData, completeCoveragePath }) {
    return columnData.map(({ lob, code }) => {
        return {
            code: code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: _.get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (submissionVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    const offerings = _.get(submissionVM, 'lobData.personalAuto.offerings.value');
    const lineCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .lineCoverages.map((coverage) => structureClauseTableData(coverage))),
        'coverageUniqueID'
    );
    const vehicleCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .vehicleCoverages.flatMap(({ coverages }, index) => coverages
                .map((coverage) => structureClauseTableData(coverage, `__${index}`)))),
        'coverageUniqueID'
    );
    return {
        lineCoverages,
        vehicleCoverages
    };
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ code, quote }) => code !== 'CUSTOM' && !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const coveragesUniqIDs = getCoveragesUniqueID(submissionVM);

    const lineCoverages = {
        header: translator(messages.generalCoverages),
        data: coveragesUniqIDs.lineCoverages,
        tableContent: generateClauseData({
            columnData,
            completeCoveragePath: 'coverages.lineCoverages'
        })
    };

    const vehicleCoverages = _.flatMap(columnData, 'lob.data.coverages.vehicleCoverages', []);
    const coveragesByVehicle = _.keyBy(vehicleCoverages, 'vehicleName');

    const vehiclesMetadata = Object.entries(coveragesByVehicle).map((
        [vehicleName],
        vehicleIndex
    ) => {
        const completeCoveragePath = `coverages.vehicleCoverages.children[${vehicleIndex}].coverages`;
        return {
            header: `${translator(messages.vehicleSpecificCoverage)} ${vehicleName}`,
            data: coveragesUniqIDs.vehicleCoverages.filter(
                (coverage) => coverage.coverageUniqueID.endsWith(`__${vehicleIndex}`)
            ),
            tableContent: generateClauseData({ columnData, completeCoveragePath })
        };
    });

    return [lineCoverages, ...vehiclesMetadata];
};

function QuotePage(props) {
    const { wizardSnapshot, wizardData: submissionVM, updateWizardData } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const { onValidate, disregardFieldValidation } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
            return CustomQuoteService.updateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    const updatedClauses = _.get(response, `coverages.${lobName}`);
                    const newSubmissionVM = (submissionVM);
                    // Update the offering status to stale
                    setStaleQuoteBranchCode(response.quote.branchCode);
                    // Update local offering with new one from xcenter
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    // Update local quote status with new one from xcenter
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM.value, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM.value, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(submissionVM.value, `${quotePath}.premium`, response.quote.premium);
                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages
                    ];
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(submissionVM);
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData
        ]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    const updatedClauses = _.get(response, `coverages.${lobName}`);
                    // Update local offering with new one from xcenter
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    // Update local quote status with new one from xcenter
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                    updateWizardData(submissionVM);
                }
            );
        },
        [initialSubmissionVM, submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);
                    const updatedClauses = _.get(response, `coverages.${lobName}`);
                    // Update local offering with new one from xcenter
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                    updateWizardData(submissionVM);
                    return response;
                }
            );
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            await CustomQuoteService.setSelectedVersionOnSubmission(
                quoteID,
                lobOffering.branchName,
                sessionUUID,
                authHeader
            );

            // set chosen quote
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            submissionVM.value = MockUpUtil.cleanUpMockedProperties(
                submissionVM.value,
                PATH_TO_MOCK_DATA,
                ...MOCK_DATA_TO_REMOVE
            );
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(submissionVM);
        const { quoteID } = submissionVM;
        const { underwritingIssues } = props;

        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            quoteTable: {
                columnData: columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                underwritingIssues: underwritingIssues,
                quoteID: quoteID.value
            },
            startDate: {
                visible: showQuoteStartDate
            },
            print: {
                visible: !showQuoteStartDate
            }
        };
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, translator]);

    useEffect(() => {
        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));

        // inject mock data to prevent wizardData.subtreeValid is invalid
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_SET
        );

        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    return (
        <WizardPage showNext={false} showPrevious={false} showCancel={false}>
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode: onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onResetQuote: resetQuote,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onPrint: handlePrint
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={generateOverrides()}
                        onModelChange={updateWizardData}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={onValidate}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
