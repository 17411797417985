import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';
import { brandingData } from 'gw-portals-branding-js';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import themeList from '@jutro/theme-styles/index';
import App from './app/App';

const { trackingConfig = {} } = appConfig;
const APP_BRAND_NAME = {
    tya: 'Toyota Insurance Services',
    kfi: 'Kwik Fit Motor Insurance | Get a Quote',
};

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;
const brandTheme = themeList.find((theme) => {
    return theme.prefix?.includes(brandingData.BRANDING);
});

if (window.self === window.top) {
    const theBody = document.getElementsByTagName('body')[0];
    theBody.style.display = 'block';
} else {
    window.top.location = window.self.location;
}

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLanguageOrLocaleChange: true,
    themeConfig: brandTheme,
    onInit: () => {
        LocaleService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: APP_BRAND_NAME[brandingData.BRANDING],
    },
    appDescription: `${APP_BRAND_NAME[brandingData.BRANDING]} Quote and Buy`,
});
