import React, { useContext, useCallback } from 'react';

import { BreakpointTrackerContext } from '@jutro/layout';

import { pageTemplateProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate, WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { MediaSideBar } from 'gw-components-platform-react';

import WizardSidebarButtons from './WizardSidebarButtons/WizardSidebarButtons';
import { QuoteInfoComponentContext } from './QuoteInfoComponentContext';
import CommonQuoteInfo from './CommonQuoteInfo/CommonQuoteInfo';

function QuoteWizardPageTemplate(props) {
    const quoteInfoComponent = useContext(QuoteInfoComponentContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;

    const renderMediaSidebar = useCallback(() => {
        const isMobile = breakpoint === 'tablet' || isPhone;
        return (
            <React.Fragment>
                <WizardSidebarButtons />
                {isMobile ? null : <MediaSideBar />}
            </React.Fragment>
        );
    }, [breakpoint, isPhone]);

    const renderQuoteInfoBar = useCallback(
        (submissionVM, history) => {
            // We need a variable with Pascal case in order to render it with React
            // however if we named the variable to respect casing
            // the hooks linter would fail to recognize it as a dependency.
            // To not disable the rule, and circumvent the issue, we alias the variable;
            const QuoteInfo = quoteInfoComponent;
            return <QuoteInfo submissionVM={submissionVM} history={history} />;
        },
        [isPhone, quoteInfoComponent]
    );

    return (
        <WizardPageTemplate
            renderContextComponent={renderQuoteInfoBar}
            renderWidgets={renderMediaSidebar}
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </WizardPageTemplate>
    );
}

QuoteWizardPageTemplate.propTypes = pageTemplateProps;
export default QuoteWizardPageTemplate;
