/* eslint-disable max-len */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { wizardProps } from 'gw-portals-wizard-react';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter} from 'react-router-dom';
import { LoadSaveService, Submission } from 'gw-capability-quoteandbind';
import { brandingData } from 'gw-portals-branding-js';
import { ErrorBoundary } from 'gw-portals-error-react';
import { useHistory } from 'react-router-dom';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';

import metadata from './AMPQuoteEntryPage.metadata.json5';
import styles from './AMPQuoteEntryPage.module.scss';

function AMPQuoteEntryPage() {
    const history = useHistory();
    const brand = brandingData.BRANDING;

    const [retrieveQuoteDataVM, updateRetrieveQuoteDataVM] = useState({});
    const [submissionRetrieved, setSubmissionRetrieved] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        const previousLocation = history.location.state?.previousLocation;
        const hash = previousLocation.state?.previousLocation.hash;
        const search = hash.includes('?') ? hash.split('?')[1] : '';
        const params = new URLSearchParams(search);
        const quoteID = params.get('quoteID');
        const postalCode = params.get('postalCode');
        const dateOfBirth = params.get('dateOfBirth');
        const dateParts = dateOfBirth.split('-');
        const amend = params.get('amend');
        const covered = params.get('c');

        const retrieveQuoteViewModel = {
            quoteID: quoteID,
            postalCode: postalCode,
            dateOfBirth: {
                day: dateParts[0],
                month: dateParts[1],
                year: dateParts[2],
            },
            brand_itb: brand.toUpperCase(),
            productCode: null,
            effectiveDate: null,
            displayYourInfoStep: true,
            gatewayportalnewsubmission: null,
            shouldUpdateEffectiveDate: null,
        };

        updateRetrieveQuoteDataVM(retrieveQuoteViewModel);

        if (!submissionRetrieved && quoteID && postalCode && dateOfBirth) {
            LoadSaveService.retrieveSubmission(retrieveQuoteViewModel)
                .then((submission) => {
                    updateRetrieveQuoteDataVM(retrieveQuoteViewModel);
                    const submissionVM = viewModelService.create(new Submission(submission), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO');
                    history.push({
                        pathname: '/',
                        data: submissionVM.value,
                        origin: 'AMPQuoteEntryPage',
                        amend: amend,
                        covered: covered,
                        redirectBln: true
                    });
                    setSubmissionRetrieved(true);
                })
                .catch((error) => {
                    history.push({
                        pathname: '/error',
                        data: error,
                        origin: 'AMPQuoteEntryPage[retrieveSubmission]',
                        quoteID: quoteID || ''
                    });
                });
        }
    }, [updateRetrieveQuoteDataVM, history, brand, submissionRetrieved, viewModelService]);

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        ampEnrtyContainer: {
            visible: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: 'AMPQuoteEntryPage',
            quoteID: ''
        });
        return false;
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <ViewModelForm
                model={retrieveQuoteDataVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </ErrorBoundary>
    );
}
AMPQuoteEntryPage.propTypes = wizardProps;
export default withRouter(withViewModelService(AMPQuoteEntryPage));
