import React from 'react';

import styles from './CookiesComponent.module.scss';
import CookiesComponent from './CookiesComponent';

function CookiesModal({ ismodalOpen, handleModalActivity, getCookie, periodStartDate }) {
    if (!ismodalOpen) {
        return null;
    }

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <CookiesComponent handleModalActivity={handleModalActivity} getCookie={getCookie} periodStartDate={periodStartDate} />
            </div>
        </div>
    );
};

export default CookiesModal;
