import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleTitle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.Vehicle',
        defaultMessage: 'Vehicle',
    },
    paVehicleTitleNumber: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.Vehicle:',
        defaultMessage: 'Vehicle:',
    },
    paAddAnotherVehicleTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Another Vehicle?',
        defaultMessage: 'Add Another Vehicle?',
    },
    paAddDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Vehicle',
        defaultMessage: 'Add Vehicle',
    },
    removeVehicleTitle: {
        id: 'quoteandbind.pa.controllers.PAVehicleDetailsCtrl.Remove Vehicle?',
        defaultMessage: 'Remove Vehicle?',
    },
    removeVehicleDescription: {
        id: 'quoteandbind.pa.drivers.remove-vehicle.message',
        defaultMessage: 'Are you sure you want to remove this vehicle from the list of vehicles?',
    },
    fillInMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.fillInMsg',
        defaultMessage: 'After you enter a VIN and move to the next field, the Make, Model and Year fields will be filled in automatically if possible.',
    },
    additionalInterestsForThisVehicle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.Additional Interests for This Vehicle',
        defaultMessage: 'Additional Interests for This Vehicle',
    },
    paAddAdditionalInterestType: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Type',
        defaultMessage: 'Type',
    },
    paAddAdditionalInterestFirstName: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.First Name',
        defaultMessage: 'First Name',
    },
    paAddAdditionalInterestLastName: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Last Name',
        defaultMessage: 'Last Name',
    },
    paAddAdditionalInterestCountry: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Country',
        defaultMessage: 'Country',
    },
    paAddAdditionalInterestAddress: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Address',
        defaultMessage: 'Address',
    },
    paAddAdditionalInterestCity: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.City',
        defaultMessage: 'City',
    },
    paAddAdditionalInterestState: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.State',
        defaultMessage: 'State',
    },
    paAddAdditionalInterestZipCode: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.ZIP Code',
        defaultMessage: 'ZIP code',
    },
    paAddAdditionalInterestInterestType: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Interest Type',
        defaultMessage: 'Interest Type',
    },
    paAddAdditionalInterestAddressType: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Address Type',
        defaultMessage: 'Address Type',
    },
    paAddAdditionalInterestPhoneNumber: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Phone Number',
        defaultMessage: 'Phone Number',
    },
    paAddAdditionalInterestEmail: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Email',
        defaultMessage: 'Email',
    },
    paAddAdditionalInterestForThisVehicle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Additional Interests for This Vehicle',
        defaultMessage: 'Add Additional Interests for This Vehicle',
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Additional Interest:',
        defaultMessage: 'Additional Interest: { count }'
    }
});
