import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { withModalContext } from '@jutro/components';
import { useDependencies } from 'gw-portals-dependency-react';

import metadata from './RetrieveQuote.metadata.json5';
import styles from './RetrieveQuote.module.scss';
import './RetrieveQuote.messages';
import { ErrorBoundary } from 'gw-portals-error-react';
import { brandingData } from 'gw-portals-branding-js';

function RetrieveQuote(props) {
    //const { onTogglePageState, onRetrieveQuote, history } = props;
    const { onRetrieveQuote, history } = props;
    const [retrieveQuoteVM, updateRetrieveQuoteVM] = useState(undefined);
    const { isComponentValid, onValidate } = useValidation('RetrieveQuote');
    const brand = brandingData.BRANDING;
    //const { quoteID } = useParams();
    
    //const { ViewModelServiceContext } = useDependencies('ViewModelServiceContext');
    const viewModelService = useContext(ViewModelServiceContext);

   useEffect(() => {
        const retrieveQuoteViewModel = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteRetrievalDTO'
        );
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const quoteID = params.get('quoteID');
        retrieveQuoteViewModel.brand_itb.value = brand;
        retrieveQuoteViewModel.quoteID = quoteID;
        updateRetrieveQuoteVM(retrieveQuoteViewModel);

    }, [viewModelService, updateRetrieveQuoteVM]);

    const writeValue = useCallback(
        (value, path) => {
            const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
            _.set(newRetrieveQuoteVM.value, path, value);
            updateRetrieveQuoteVM(newRetrieveQuoteVM);
        },
        [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]
    );

    const handlePostcodeChange = useCallback((value, path) => {
        const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
        let valueUppercase = value.toUpperCase().replace(/ {2,}/g, ' ');
        if (/[A-Z0-9]\s[A-Z0-9]/.test(valueUppercase)) {
            if (/\s$/.test(valueUppercase)) valueUppercase = valueUppercase.replace(/\s$/, '');
            if (/^\s/.test(valueUppercase)) valueUppercase = valueUppercase.replace(/^\s/, '');
        }
        _.set(newRetrieveQuoteVM.value, path, valueUppercase);
        updateRetrieveQuoteVM(newRetrieveQuoteVM);
    }, [retrieveQuoteVM, viewModelService, updateRetrieveQuoteVM]);

    const retrieveQuote = useCallback(() => {
        onRetrieveQuote({
            ...retrieveQuoteVM.value
        });
    }, [onRetrieveQuote, retrieveQuoteVM]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRetrieveQuote: retrieveQuote            
        }
    };

    const overrides = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        retrieveQuoteContinueButton: {
            disabled: !isComponentValid
        },
        retrieveQuotePostCode: {
            onValueChange: handlePostcodeChange,
            validator: {
                pattern: /^[a-zA-Z](([\d]{1}[\w]?)|([a-zA-Z]{1}([\d]{1,2}|[\d]{1}[a-zA-Z]?)))[\s]*[\d]{1}[a-zA-Z]{2}$/,
                message: {
                    id: 'postCodeValidationMessage',
                    defaultMessage: 'Please enter a valid postcode'
                }
            },
            validationMessages: (retrieveQuoteVM?.postalCode
                && (retrieveQuoteVM.postalCode.value == undefined || retrieveQuoteVM.postalCode.value == '')) ? ['This field is required'] : [],
        },
        retrieveQuoteDOB: {
            validationMessages: (retrieveQuoteVM?.dateOfBirth
                && retrieveQuoteVM.dateOfBirth.value == undefined) ? ['This field is required'] : [],
        },
    };
    
    const handleError = useCallback((error = {}) => {
        history.push({ 
            pathname: '/error',
            data: error,
            origin: "RetrieveQuote",
            quoteID: _.get(retrieveQuoteVM.value, 'quoteID')
        });
    }, []);

    return (
        <ErrorBoundary onError={handleError}>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={retrieveQuoteVM}
                    overrideProps={overrides}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
        </ErrorBoundary>
    );
}

RetrieveQuote.propTypes = {
    //onTogglePageState: PropTypes.func.isRequired,
    onRetrieveQuote: PropTypes.func.isRequired
};
export default withRouter(RetrieveQuote);
