/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import moment from 'moment';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

import metadata from './PMConvictionsComponent.metadata.json5';
import './PMConvictionsComponent.messages';

function PMConvictionsComponent(props) {
    const {
        convictionCodeValues,
        data: convictionVM,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        submitted
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMConvictionsComponent');

    const CONVICTION_POINTS_OPTIONS_LABEL = {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11
    };
    const minimumDate = moment().subtract(5, 'years').toDate();
    const [minConvictionDate] = useState({
        year: minimumDate.getFullYear(),
        month: minimumDate.getMonth(),
        day: minimumDate.getDate()
    });
    const [maxConvictionDate] = useState(LocalDateUtil.today);
    const [convictionPointsAvailableValues] = useState(
        Object.entries(CONVICTION_POINTS_OPTIONS_LABEL).map(([key, value]) => ({ code: value, name: key }))
    );

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const getBooleanFieldValue = (fieldPath) => {
        const booleanVal = _.get(convictionVM, fieldPath, false);
        return booleanVal;
    };

    const currentDateBuilder = (strFetchValue) => {
        const objTodaysDate = new Date();
        const intDay = objTodaysDate.getDate();
        const intMonth = objTodaysDate.getMonth();
        const intYear = objTodaysDate.getFullYear();

        switch (strFetchValue) {
            case 'day':
                return intDay;
            case 'month':
                return intMonth;
            case 'year':
                return intYear;
            default:
                return { year: intYear, month: intMonth, day: intDay };
        }
    };

    const isDateInTheAllowedMinMaxRange = (objCheckDate, objMinDate, objMaxDate) => {
        return objCheckDate.getTime() >= objMinDate.getTime() && objCheckDate.getTime() <= objMaxDate.getTime();
    };

    const handleManualEnteredDateConstraint = () => {
        const objConvictionDateInPage = _.get(convictionVM, 'convictionDate');
        if (objConvictionDateInPage?.year && objConvictionDateInPage?.month && objConvictionDateInPage?.day) {
            const convictionInDateRange = isDateInTheAllowedMinMaxRange(new Date(objConvictionDateInPage.year.value, objConvictionDateInPage.month.value, objConvictionDateInPage.day.value), new Date(minConvictionDate.year, minConvictionDate.month, minConvictionDate.day), new Date(currentDateBuilder('year'), currentDateBuilder('month'), currentDateBuilder('day')));
            if (!convictionInDateRange) {
                _.set(convictionVM.value, 'convictionDate', undefined);
            }
        }
    };

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'PMConvictionsComponent');
        }
    }, [id, onValidate, isComponentValid]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const overrideProps = {
        '@field': {
            phoneWide,
        },
        [`convictionCodes${index}`]: {
            availableValues: convictionCodeValues && convictionCodeValues.sort((a, b) => a.code.localeCompare(b.code)),
            required: getBooleanFieldValue('codeID.aspects.required'),
            showRequired: getBooleanFieldValue('codeID.aspects.required'),
        },
        [`convictionDate${index}`]: {
            onBlur: handleManualEnteredDateConstraint(),
            minDate: new Date(minConvictionDate.year, minConvictionDate.month, minConvictionDate.day),
            maxDate: new Date(currentDateBuilder('year'), currentDateBuilder('month'), currentDateBuilder('day')),
            required: getBooleanFieldValue('convictionDate.aspects.required'),
            showRequired: getBooleanFieldValue('convictionDate.aspects.required'),
        },
        [`convictionPoints${index}`]: {
            availableValues: convictionPointsAvailableValues,
            required: getBooleanFieldValue('convictionPoints.aspects.required'),
            showRequired: getBooleanFieldValue('convictionPoints.aspects.required'),
        },
        [`disqualificationMonths${index}`]: {
            required: getBooleanFieldValue('disqualificationMonths.aspects.required'),
            showRequired: getBooleanFieldValue('disqualificationMonths.aspects.required'),
        }
    };
    return (
        <ViewModelForm
            model={convictionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            showErrors={submitted}
        />
    );
}
PMConvictionsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMConvictionsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMConvictionsComponent;
