/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { MockUpUtil } from 'gw-portals-util-js';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ScrollToError } from '@jutro/wizard-next';
import { ErrorBoundary } from 'gw-portals-error-react';
import { withRouter } from 'react-router-dom';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';
import styles from './VehiclesPage.module.scss';
import ValidationUtil from '../../util/ValidationUtil';

function VehiclesPage(props) {
    const {
        showConfirm
    } = useModal();

    const { wizardData: submissionVM, updateWizardData, history, clearVisitedStepsAfterCurrent, currentStep, steps, currentStepIndex } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialised, updateIsPageInitialised] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [vehLookupSuccessful, setVehLookupSuccessful] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const ncdYearsValue = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdYears');
    const ncdYears = ncdYearsValue !== null && ncdYearsValue !== undefined;
    const [isRegModeActive, setIsRegModeActive] = useState(true);
    const [isShowManualModeClicked, setIsShowManualModeClicked] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('VehiclesPage');

    const isHomeNumberValid = useState(ValidationUtil.isHomeNumberValid(submissionVM));
    const isPhoneNumberValid = useState(ValidationUtil.isPhoneNumberValid(submissionVM));
    const isPhoneNumberLnPValid = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const objLoadedVehicleDetails = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0]', []);
    // eslint-disable-next-line max-len
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'VehiclesPage', { isRegModeActive: isRegModeActive, objLoadedVehicleDetails: objLoadedVehicleDetails }, isPhoneNumberLnPValid, vehLookupSuccessful);

    const basePeriodStartDate = useMemo(() => new Date(
        submissionVM.baseData.periodStartDate.year.value,
        submissionVM.baseData.periodStartDate.month.value,
        submissionVM.baseData.periodStartDate.day.value
    ), [
        submissionVM.baseData.periodStartDate.year.value,
        submissionVM.baseData.periodStartDate.month.value,
        submissionVM.baseData.periodStartDate.day.value
    ]);

    const createVehicleVM = useCallback(() => {
        const vehicles = submissionVM.lobData.personalMotor.coverables.pmVehicles.value;
        if (_.isEmpty(vehicles)) {
            const accountHolder = submissionVM.baseData.accountHolder.value;
            const vehicleObj = {
                costNew: {}
            };
            // eslint-disable-next-line max-len
            const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmVehicles;
            _.set(submissionVM.value, "lobData.personalMotor.coverables.pmVehicles", []);
            const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);
            submissionVM.lobData.personalMotor.coverables.pmVehicles.pushElement(vehicleVM);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    const handleRegModeActive = (value) => {
        setIsRegModeActive(value);
    };

    const handleShowManualModeClicked = (value) => {
        setIsShowManualModeClicked(value);
    };
    useEffect(() => {
        if (!isPageInitialised) {
            const vehiclesPath = 'lobData.personalMotor.coverables.pmVehicles';
            const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);

            if (_.isEmpty(vehicles)) {
                createVehicleVM();
            }
            updateIsPageInitialised(true);
        }
        if (currentStep.stepProps.shouldRequoteSub) {
            clearVisitedStepsAfterCurrent();
            steps[currentStepIndex + 1].stepProps.showSelectedAddons = true;
            updateWizardData(submissionVM);
        }
        const quoteID = _.get(submissionVM, 'quoteID.value', null);
        const ncdProtectionAllowed = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtectionAllowed', null);
        if (quoteID && ncdProtectionAllowed) {
            window.sessionStorage.setItem(`ncdProtection-${quoteID}`, null);
            _.set(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtection', false);
            updateWizardData(submissionVM);
        }
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const addVehicle = useCallback(() => {
        const accountHolder = submissionVM.baseData.accountHolder.value;
        const vehicleObj = {
            licenseState: accountHolder.primaryAddress.state,
            costNew: {
                amount: ''
            },
            primaryUse: 'commuting'
        };
        // eslint-disable-next-line max-len
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmVehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);
        submissionVM.lobData.personalMotor.coverables.pmVehicles.pushElement(vehicleVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeVehicle = useCallback((evt) => {
        const vehicleListPath = 'lobData.personalMotor.coverables.pmVehicles.value';
        const currentPath = evt.path;
        const vehiclePath = currentPath.replace(/\.children\[(\d+)\]/, '.children[$1].value');
        const vehicle = _.get(submissionVM, vehiclePath);
        const vehicleList = _.get(submissionVM, vehicleListPath);
        const vehicleIndex = vehicleList.findIndex(
            (driverFromList) => _.isEqual(driverFromList, vehicle)
        );

        showConfirm({
            title: messages.removeVehicleTitle,
            message: messages.removeVehicleDescription,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results !== 'confirm') {
                return _.noop();
            }
            const newVehicleList = _.remove(
                vehicleList,
                (vehicleFromList) => _.isEqual(vehicleFromList, vehicle)
            );
            _.set(submissionVM, newVehicleList, vehicleListPath);
            disregardFieldValidation(`vehicle${vehicleIndex}`);
            updateWizardData(submissionVM);
            return true;
        }, _.noop);
    }, [disregardFieldValidation, showConfirm, submissionVM, updateWizardData]);

    const handleNCDValueChange = useCallback(
        (value) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            const ncdPath = 'lobData.personalMotor.coverables.pmVehicles[0].ncdYears';
            const drivingOtherCarsPath = 'lobData.personalMotor.coverables.pmVehicles[0].haveYouBeenDrivingOtherCars';
            _.set(newSubmissionVM.value, ncdPath, parseInt(value, 10));
            if (value === '0') {
                _.set(newSubmissionVM.value, drivingOtherCarsPath, false);
            }
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const generateOverrides = useCallback(() => {
        const vehiclePath = 'lobData.personalMotor.coverables.pmVehicles';
        const vehicles = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value', []);

        const overrides = vehicles.map((vehicle, index) => {
            let driverTitle = translator(messages.pmVehicleTitleNumber);

            if (vehicles.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.pmVehicleTitleNumber)} ${index + 1}`;
            }

            return {
                inlineNotificationErrorBarVehiclePage: {
                    className: isShowManualModeClicked ? 'inlineNotificationErrorBarVehiclePage' : '',
                    // eslint-disable-next-line max-len
                    message: ValidationUtil.updateInlineErrorBanner(submissionVM, messages, invalidPropsLength, submitted).strErrorBannerTextOutput,
                    // eslint-disable-next-line max-len
                    visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messages, invalidPropsLength, submitted).blnErrorWidgetVisibility
                },
                [`vehicle${index}`]: {
                    submitted: submitted,
                    setVehLookupSuccessful: setVehLookupSuccessful,
                    handleRegModeActive: handleRegModeActive,
                    handleShowManualModeClicked: handleShowManualModeClicked,
                    basePeriodStartDate: basePeriodStartDate,
                },
                [`pmDeleteVehicle${index}`]: {
                    visible: vehicles.length > 1
                },
                [`pmVehicleTitle${index}`]: {
                    content: driverTitle
                },
                [`ncdyears${index}`]: {
                    onValueChange: handleNCDValueChange,
                    labelPosition: 'top',
                    validationMessages: ncdYears ? [] : ['This field is required'],
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, translator, isShowManualModeClicked, invalidPropsLength, submitted, basePeriodStartDate, handleNCDValueChange, ncdYears]);

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "VehiclePage",
            quoteID: _.get(submissionVM.value, 'quoteID')
        });
    }, [history]);

    const onNext = useCallback(() => {
        _.unset(submissionVM.value, 'bindData');
        if (invalidPropsLength > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setSubmitted(true);
            return false;
        }
        return LoadSaveService.saveAndQuoteSubmission(submissionVM.value)
            .then((value) => {
                submissionVM.value = value;
                window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
                const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
                updateWizardData(newUpdatedSubmissionVM);
                return newUpdatedSubmissionVM;
            })
            .catch((error) => {
                history.push({
                    pathname: '/error',
                    data: error,
                origin: "VehiclePage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID')
                });
                return false;
            });
    }, [LoadSaveService, history, invalidPropsLength, submissionVM, updateWizardData, viewModelService]);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddVehicleClick: addVehicle,
            onRemoveVehicle: removeVehicle,
            onValidate
        }
    };

    const isSubmissionQuoted = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    /* useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuoted);
    }, [isSubmissionQuoted, registerInitialComponentValidation]); */

    if (!isPageInitialised) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                skipWhen={initialValidation}
                nextLabel={'Continue'}
                previousLabel={'Back'}
                disableNext={false}
                disableCancel={true}
                cancelLabel={''}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    onModelChange={updateWizardData}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={submitted}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

VehiclesPage.propTypes = wizardProps;
export default withRouter(VehiclesPage);
