import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { withRouter } from 'react-router-dom';
import { Wizard } from 'gw-portals-wizard-react';
import { LoadSaveService, Submission } from 'gw-capability-quoteandbind';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { withModalContext } from '@jutro/components';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'gw-portals-util-js';
import FlowUtil from './util/FlowUtil';
import { messages as commonMessages } from 'gw-platform-translations';

import { withAuthenticationContext } from 'gw-digital-auth-react';

import wizardStepToFieldMapping from './config/pm-wizard-step-to-field-mapping.json5';
import PmQuoteInfo from './components/PmQuoteInfo/PmQuoteInfo';
import wizardConfig from './config/pm-wizard-config.json5';
import './PMWizard.messages';

const PATH_TO_MOCK_DATA = 'quote.pm';
const REMOVE_MOCK_DATA = true;
const MOCK_DATA_TO_SET = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.accountHolder',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city',
    'baseData.policyAddress.postalCode',
    'baseData.productCode',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.subtype',
    'baseData.accountHolder.dateOfBirth.day',
    'baseData.accountHolder.dateOfBirth.month',
    'baseData.accountHolder.dateOfBirth.year'
];
const MOCK_DATA_TO_REMOVE = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.dateOfBirth.day',
    'baseData.accountHolder.dateOfBirth.month',
    'baseData.accountHolder.dateOfBirth.year',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city',
    'baseData.policyAddress.postalCode'
];

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: commonMessages.infoWillBeSavedAsDraft,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function setCoverageStartDate() {
    const currentDate = new Date();
    const coverageStartDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return coverageStartDate;
}

function setPrepData(address) {
    const realData = {
        baseData: {
            policyAddress: address,
            periodStartDate: setCoverageStartDate()
        }
    };

    return MockUpUtil.setMockData(realData, PATH_TO_MOCK_DATA, ...MOCK_DATA_TO_SET);
}

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        ...MOCK_DATA_TO_REMOVE
    );
}

function createSubmissionOnPartialAddress(address) {
    const data = setPrepData(address);
    return LoadSaveService.createSubmission(data);
}

function PMWizard(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const [wizardSteps, setWizardSteps] = useState(steps);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;
    const [stepIndexToStartOn, updateStepIndexToStartOn] = useState(null);
    const [ampCovered, setAmpCovered] = useState(false);
    const [isPeriodStartDate, setIsPeriodStartDate] = useState(false);

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            DriverEmailRequired: true,
            AccountDOBRequired: true
        };

        if (!viewModelService) {
            if (_.isEmpty(location.search)) {
                history.push('/');
            }

            return;
        }

        let submissionObj = {};
        if(FlowUtil.validSubmissionObj(location.data)) {
            window.sessionStorage.clear();
            submissionObj = new Submission(location.data);
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionObj));
            const ampRedirectBln = location.redirectBln;
            if (location.origin === 'AMPQuoteEntryPage' && ampRedirectBln) {
                window.sessionStorage.setItem('ampRedirectBln', 'true');
            }
            const ampAmend = location.amend;
            setAmpCovered(location.covered);
            const periodStatus = _.get(submissionObj, 'baseData.periodStatus');
            const periodStartDate = _.get(submissionObj, 'baseData.periodStartDate');
            const currentDate = new Date();
            const currentDateObj = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            if (periodStartDate) {
                const periodStartDateObj = new Date(periodStartDate.year, periodStartDate.month, periodStartDate.day);
                if (periodStartDateObj < currentDateObj) {
                    setIsPeriodStartDate(true);
                }
            }
            if (location.origin === 'AMPQuoteEntryPage') {
                if (ampAmend === 'false' && periodStatus === 'Quoted' && !isPeriodStartDate) {
                    updateStepIndexToStartOn(4);
                } else {
                    updateStepIndexToStartOn(null);
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (periodStatus === 'Quoted') {
                    updateStepIndexToStartOn(4);
                } else {
                    const vehiclesPath = 'lobData.personalMotor.coverables.pmVehicles';
                    const vehicles = _.get(submissionObj, vehiclesPath);

                    if (_.isEmpty(vehicles)) {
                        const driversPath = 'lobData.personalMotor.coverables.pmDrivers';

                        const drivers = _.get(submissionObj, driversPath);
                        if (_.isEmpty(drivers)) {
                            updateStepIndexToStartOn(null);
                        } else {
                            updateStepIndexToStartOn(2);
                        }
                    } else {
                        updateStepIndexToStartOn(3);
                    }
                }
            }
        } else {
            let storedSubmission = null;
            const sessionStorageSubmissionObj = window.sessionStorage.getItem("submissionVm")
            if (sessionStorageSubmissionObj) {
                storedSubmission = JSON.parse(sessionStorageSubmissionObj);
            }
            if(storedSubmission) {
                submissionObj = new Submission(storedSubmission);
                let lastVisitedStepIndex = null;
                const sessionStoragelastVisitedStepIndex = window.sessionStorage.getItem("lastVisitedStepIndex")
                if (sessionStoragelastVisitedStepIndex) {
                    lastVisitedStepIndex = JSON.parse(sessionStoragelastVisitedStepIndex);
                }
                updateStepIndexToStartOn(lastVisitedStepIndex);
            } else {
                let data = setPrepData('');
                submissionObj = new Submission(data);
                if(REMOVE_MOCK_DATA) {
                    submissionObj = removeMockData(submissionObj);
                }
                updateStepIndexToStartOn(null);
            }
        }

        let submissionPromise;
        submissionPromise = Promise.resolve(submissionObj);

        submissionPromise.then((response) => {
            const submissionVM = viewModelService.create(response, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
            setInitialSubmission(submissionVM);
            setIsLoading(false);
        });
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService, isPeriodStartDate]);

    const handleCancel = useCallback(
        (wizardProps) => {
            const { wizardSnapshot, wizardData } = wizardProps;
            const latestSnapshot = wizardData.aspects.subtreeValid && wizardData.aspects.valid
                ? wizardData
                : wizardSnapshot;

            const periodStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code');
            if (periodStatus === 'Bound') {
                // allow transition once the submission is bound
                return true;
            }
            return showConfirm(ACTION_MSG.cancelAction).then((results) => {
                if (results !== 'confirm') {
                    return _.noop();
                }
                let serverCall = LoadSaveService.updateDraftSubmission;

                if (periodStatus === 'Quoted') {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
                const dataToSave = periodStatus === 'Quoted'
                    ? MockUpUtil.setMockData(wizardDataFromSnapshot, PATH_TO_MOCK_DATA)
                    : MockUpUtil.setMockData(
                        wizardDataFromSnapshot,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_SET
                    );
                setIsLoading(true);

                serverCall(dataToSave)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(() => {
                        showAlert({
                            title: commonMessages.unableToSaveQuote,
                            message: commonMessages.unableToSaveQuoteMessage,
                            status: 'error',
                            icon: 'gw-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                return true;
            }, _.noop);
        },
        [history, showAlert, showConfirm]
    );

    if (isLoading) {
        return <div className="loader"></div>
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={PmQuoteInfo} >
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
                stepIndexOnInitial={stepIndexToStartOn}
                isFromAMP={ampCovered === 'true'}
                //wizardStepToFieldMapping={wizardStepToFieldMapping}
            />
        </QuoteInfoComponentContext.Provider>
    );
}

PMWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            submission: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export const PMWizardComponent = PMWizard;
export default withRouter(withAuthenticationContext(withModalContext(PMWizard)));