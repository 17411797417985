/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState, useRef
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import { ErrorBoundary } from 'gw-portals-error-react';
import ValidationUtil from '../../util/ValidationUtil';
import { withRouter } from 'react-router-dom';
import { MockUpUtil } from 'gw-portals-util-js';
import styles from './PMLegalPage1InformationPage.module.scss';
import metadata from './PMLegalPage1InformationPage.metadata.json5';
import messagesTranslationsSet from './PMLegalPage1InformationPage.messages';
import { ScrollToError } from '@jutro/wizard-next';

import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
import { brandingData } from 'gw-portals-branding-js';

const PATH_TO_MOCK_DATA = 'quote.pm';
const MOCK_DATA_TO_SET = ['baseData.accountHolder.emailAddress1'];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

function PMLegalPage1InformationPage(props) {
    const {
        wizardData: submissionVM, updateWizardData, steps, jumpTo, history
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { onValidate, isComponentValid, initialValidation } = useValidation('PMLegalPage1InformationPage');
    const [isCheckboxActive, setCheckboxActive] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now()); // To trigger ScrollToError
    const [isRegUnknown, setIsRegUnknown] = useState();
    const VEH_REG_PATTERN = /^[a-zA-Z0-9\s]{0,8}$/;
    const strVehicleRegValue = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0].registrationNumber.value', '');
    const brand = brandingData.BRANDING;
    const periodStartDate = _.get(submissionVM, 'baseData.periodStartDate.value', null);

    // eslint-disable-next-line max-len
    /* The following block of 5 constants are updated through the onVehicleRegChange() onBlur call */
    const [isDifferentVehicle, setDifferentVehicleError] = useState(false);
    const [isVehicleWeCouldNotFind, setVehicleWeCouldNotFindError] = useState(false);
    const [isRegistrationFieldEmpty, setRegistrationFieldEmpty] = useState(true);
    const [strEnhancedVehicleDescription, setVehicleEnhancedDescription] = useState('');
    const [isNewRegistrationNumberError, setNewRegistrationNumberError] = useState(false);
    const [otherVehicleInfo, setOtherVehicleInfo] = useState({});

    const [isKeys, setIsKeys] = useState(false);
    const [isBreakdown, setIsBreakdown] = useState(false);
    const [BreakdownLevelSelected, setBreakdownLevelSelected] = useState(null);
    const [isLegal, setIsLegal] = useState(false);
    const [isCCCP, setIsCCCP] = useState(false);
    const isHomeNumberValid = ValidationUtil.isHomeNumberValid(submissionVM);
    const isPhoneNumberValid = ValidationUtil.isPhoneNumberValid(submissionVM);
    const isPhoneNumberLnPValid = ValidationUtil.isPhoneNumberLnPValid(submissionVM);
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'PMLegalPage1InformationPage', {
        // eslint-disable-next-line max-len
        strVehicleRegValue: strVehicleRegValue,
        isVehicleWeCouldNotFind: isVehicleWeCouldNotFind,
        isDifferentVehicle: isDifferentVehicle,
        isCheckboxActive: isCheckboxActive,
        isRegistrationFieldEmpty: isRegistrationFieldEmpty
    }, isPhoneNumberLnPValid);
    const YESNOFieldavailableValues = [
        { code: false, displayName: 'Yes, I agree' },
        { code: true, displayName: 'No, I do not agree' },
    ];

    useEffect(() => {
        const vm = viewModelService.clone(submissionVM);
        if (!vm.bindData.chosenQuote.value) {
            // eslint-disable-next-line max-len
            const selectedQuote = vm.quoteData.offeredQuotes.value.find((quote) => quote.isCustom === true);
            vm.bindData.chosenQuote.value = selectedQuote.publicID;
        }
        if (typeof submissionVM.baseData?.accountHolder?.marketingPrefOptOut_itb?.value === 'undefined') {
            _.set(vm.value, 'baseData.accountHolder.marketingPrefOptOut_itb', false);
            const relatedPreferences = [
                'marketingPrefEmail_itb',
                'marketingPrefSMS_itb',
                'marketingPrefVoiceMob_itb',
                'marketingPrefLandline_itb',
                'marketingPrefPost_itb',
                'marketingPrefMobileApp_itb'
            ];
            relatedPreferences.forEach(preference => {
                _.set(vm.value, `baseData.accountHolder.${preference}`, true);
            });
        }

        const coverage = _.get(submissionVM, 'lobData.personalMotor.offerings.value[0].coverages.pmVehicleCoverages[0].coverages', []);

        const keyCoverage = coverage.find((element) => element.codeIdentifier === 'TMKeyProtectCovKFI');
        if (keyCoverage.selected) {
            setIsKeys(true);
        }

        const breakdownCoverage = coverage.find((element) => element.codeIdentifier === 'TMBreakdownCov');
        if (breakdownCoverage?.selected && breakdownCoverage?.terms[0].chosenTerm !== null) {
            setIsBreakdown(true);
            setBreakdownLevelSelected(breakdownCoverage.terms[0].chosenTerm);
        }

        const legalCoverage = coverage.find((element) => element.codeIdentifier === 'PMLegalExpensesCov');
        if (legalCoverage.selected) {
            setIsLegal(true);
        }

        const cccpCoverage = coverage.find((element) => element.codeIdentifier === 'TMEnhancedCourtesyCarCov');
        if (cccpCoverage.selected) {
            setIsCCCP(true);
        }

        if (submissionVM.value.isAggsQuoted_AND() && (strVehicleRegValue === undefined || strVehicleRegValue === '')) {
            setIsRegUnknown(true);
            _.set(vm.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', true);
        } else {
            setIsRegUnknown(submissionVM.lobData.personalMotor.coverables.pmVehicles.value[0].regNumUnknown);
        }

        if (!_.isEqual(vm.value, submissionVM.value)) {
            updateWizardData(vm);
        }
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(5));
        
        const isRegUnknown = _.get(submissionVM.value.lobData.personalMotor.coverables.pmVehicles[0], 'regNumUnknown');
        const regNumber = _.get(submissionVM.value.lobData.personalMotor.coverables.pmVehicles[0], 'registrationNumber');

        if (isRegUnknown && regNumber){
            setDifferentVehicleError(true);
            setNewRegistrationNumberError(true);
            setVehicleWeCouldNotFindError(false);
            VehicleInfoLookupService.lookupVehicleByRegNumber(regNumber)
            .then((vehicleInfo) => {
                 const vehicleMake = _.get(vehicleInfo, 'make', '');
                 const vehicleYear = _.get(vehicleInfo, 'firstRegisteredYear', '');
                 const vehicleEnhancedDescription = _.get(vehicleInfo, 'enhancedVehicleDescription', '');
                 const vehicleEngineCapacity = _.get(vehicleInfo, 'engineCapacity', '');
                 setVehicleEnhancedDescription(`${vehicleYear} ${vehicleMake} ${vehicleEnhancedDescription} ${vehicleEngineCapacity} CC`);
            })
            .catch((error) => {
               setVehicleWeCouldNotFindError(true);
               return false;
            });
        }
    }, []);

    const handleInPageRadioButtonChanges = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, path, value);

        const relatedPreferences = [
            'marketingPrefEmail_itb',
            'marketingPrefSMS_itb',
            'marketingPrefVoiceMob_itb',
            'marketingPrefLandline_itb',
            'marketingPrefPost_itb',
            'marketingPrefMobileApp_itb'
        ];

        relatedPreferences.forEach(preference => {
            _.set(newSubmissionVM.value, `baseData.accountHolder.${preference}`, !value);
        });

        updateWizardData(newSubmissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const onVehicleRegChange = () => {
        const strVehicleRegistrationValue = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0].registrationNumber.value');
        // Set default values for vehicle lookup status
        setRegistrationFieldEmpty(true);
        setVehicleEnhancedDescription('');
        setDifferentVehicleError(false);
        setNewRegistrationNumberError(false);
        setVehicleWeCouldNotFindError(false);
        // Check we have a value that at least matches the regex pattern
        if (strVehicleRegistrationValue !== '') {
            setRegistrationFieldEmpty(false);
            if (!VEH_REG_PATTERN.test(strVehicleRegistrationValue)) {
                setVehicleEnhancedDescription('');
                setDifferentVehicleError(false);
                setNewRegistrationNumberError(false);
                setVehicleWeCouldNotFindError(true);
                setIsRegUnknown(true);
                setOtherVehicleInfo({});
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', true);
                updateWizardData(newSubmissionVM);
                return;
            }
            if (VEH_REG_PATTERN.test(strVehicleRegistrationValue)) {
                // Check for a valid vehicle using the passed value in the registration field
                VehicleInfoLookupService.lookupVehicleByRegNumber(strVehicleRegistrationValue)
                    .then((vehicleInfo) => {
                        // Assign vehicle lookup details to local constants
                        const foundVehicle = _.get(vehicleInfo, 'errorCode') === undefined;
                        const vehicleMake = _.get(vehicleInfo, 'make', '');
                        const vehicleYear = _.get(vehicleInfo, 'firstRegisteredYear', '');
                        const vehicleEnhancedDescription = _.get(vehicleInfo, 'enhancedVehicleDescription', '');
                        const vehicleEngineCapacity = _.get(vehicleInfo, 'engineCapacity', '');
                        const foundVehicleAbiCode = _.get(vehicleInfo, 'abiCode');
                        // Assign User Provided vehicle details to local constants
                        const userSelelectedVehAbiCode = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0].abiCode.value');
                        // Compare User Provided vehicle details to the vehicle lookup details
                        if (foundVehicle && foundVehicleAbiCode && userSelelectedVehAbiCode) {
                            if (foundVehicleAbiCode !== userSelelectedVehAbiCode) {
                                // This block highlights that we have different vehicles
                                setVehicleEnhancedDescription(`${vehicleYear} ${vehicleMake} ${vehicleEnhancedDescription} ${vehicleEngineCapacity} CC`);
                                setDifferentVehicleError(true);
                                setNewRegistrationNumberError(true);
                                setVehicleWeCouldNotFindError(false);
                                setIsRegUnknown(true);
                                setOtherVehicleInfo(vehicleInfo);
                                const newSubmissionVM = viewModelService.clone(submissionVM);
                                _.set(newSubmissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', true);
                                updateWizardData(newSubmissionVM);
                            } else {
                                // This block is triggered on a vehicle match
                                setVehicleEnhancedDescription('');
                                setDifferentVehicleError(false);
                                setNewRegistrationNumberError(false);
                                setVehicleWeCouldNotFindError(false);
                                setIsRegUnknown(false);
                                setOtherVehicleInfo({});
                                const newSubmissionVM = viewModelService.clone(submissionVM);
                                _.set(newSubmissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', false);
                                updateWizardData(newSubmissionVM);
                            }
                        } else {
                            setVehicleEnhancedDescription('');
                            setDifferentVehicleError(false);
                            setNewRegistrationNumberError(false);
                            setVehicleWeCouldNotFindError(true);
                            setIsRegUnknown(true);
                            setOtherVehicleInfo({});
                            const newSubmissionVM = viewModelService.clone(submissionVM);
                            _.set(newSubmissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', true);
                            updateWizardData(newSubmissionVM);
                        }
                    })
                    .catch((error) => {
                        history.push({
                            pathname: '/error',
                            data: error,
                            origin: "PMLegalPage1InformationPage [lookupVehicleByRegNumber]",
                            quoteID: _.get(submissionVM.value, 'quoteID') || ''
                        });
                        return false;
                    });
            }
        }
    };

    const onNext = useCallback(() => {
        const veh = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0]');
        if (veh.regNumUnknown
            && !isCheckboxActive
            && VEH_REG_PATTERN.test(veh.registrationNumber)) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (isNewRegistrationNumberError) {
                setSubmitted(true);
                setErrorTimestamp(Date.now());
            }
            return false;
        }
        if ((invalidPropsLength > 0)) {
            setSubmitted(true);
            setErrorTimestamp(Date.now());
            return false;
        }
        if (isCheckboxActive && isDifferentVehicle) {
            _.set(submissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].make', `${otherVehicleInfo.make}`);
            _.set(submissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].model', `${otherVehicleInfo.model}`);
            _.set(submissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].fuelType', `${otherVehicleInfo.fuelType}`);
            _.set(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0].enhancedVehicleDescription.value', `${otherVehicleInfo.enhancedVehicleDescription}`);
            _.set(submissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].engineCapacity', `${otherVehicleInfo.engineCapacity}`);
            _.set(submissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].firstRegisteredYear', `${otherVehicleInfo.firstRegisteredYear}`);
            const indexOfQuotePage = _.findIndex(
                steps,
                ({ path }) => path === 'vehicle-details'
            );
            steps[indexOfQuotePage].stepProps.shouldRequoteSub = true;
            jumpTo(indexOfQuotePage);
        }
        if (!submissionVM.lobData.personalMotor.coverables.pmVehicles.value[0].regNumUnknown && !isDifferentVehicle) {
            try {
                window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
                const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
                updateWizardData(newUpdatedSubmissionVM);
                return newUpdatedSubmissionVM;
            } catch (error) {
                // re-throw this error within the updater function
                // it will be triggered during state update
                history.push({
                    pathname: '/error',
                    data: error,
                    origin: "PMLegalPage1InformationPage [onNext]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            }
        } else {
            return false;
        }
    }, [history, invalidPropsLength, isCheckboxActive, isDifferentVehicle, jumpTo, steps, submissionVM, updateWizardData, viewModelService]);

    const onNextAction = useCallback(() => {
        return submissionVM;
    }, [submissionVM]);

    const checkboxFieldAction = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, path, value);
        _.set(newSubmissionVM.value, 'lobData.personalMotor.coverables.pmVehicles[0].regNumUnknown', !value);
        updateWizardData(newSubmissionVM);
        setCheckboxActive(value);
    });

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        inlineNotificationErrorBarLegalAndPayment1of3: {
            // eslint-disable-next-line max-len
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).strErrorBannerTextOutput,
            // eslint-disable-next-line max-len
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).blnErrorWidgetVisibility
        },
        registration: {
            validationMessages: (invalidPropsLength && submitted && isRegistrationFieldEmpty) ? ['This field is required'] : [],
            // eslint-disable-next-line no-unneeded-ternary, max-len
            showErrors: (invalidPropsLength && submitted && isRegistrationFieldEmpty) ? true : false,
            disabled: !isRegUnknown,
            onBlur: onVehicleRegChange,
            required: true,
            showRequired: true
        },
        differentVehicleError: {
            visible: isDifferentVehicle,
            className: 'jut__OldFieldMessage__error'
        },
        legalpage1InfoHeader: {
            content: submissionVM.bindData.selectedPaymentPlan.numberOfInstallments.value > 0 ? 'Legal and payment (step 1 of 4)' : 'Legal and payment (step 1 of 3)',
        },
        vehicleDetails: {
            visible: isDifferentVehicle
        },
        VehicleDetailsHeader: {
            className: (brand === 'kfi') ? 'gw-bold' : 'yourVehicleHeader'
        },
        vehicleDetailsInfo: {
            content: strEnhancedVehicleDescription,
            readOnly: true
        },
        matchedCarDetailsContent: {
            content: "Thanks, we've matched your car to the details entered in your quote.",
            className: isNewRegistrationNumberError ? 'mymarketingpreferencescontent' : '',
            visible: !isRegUnknown && !isNewRegistrationNumberError
        },
        WeCouldNotFindThisCar: {
            visible: isVehicleWeCouldNotFind,
        },
        marketingEmailsAndSmsMessagesRadio: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleInPageRadioButtonChanges,
        },
        quoteRecalculationContainer: {
            visible: isNewRegistrationNumberError
        },
        checkboxField: {
            validationMessages: (invalidPropsLength && submitted && isNewRegistrationNumberError && !isCheckboxActive) ? ['Please fill out this field'] : [],
            // eslint-disable-next-line no-unneeded-ternary, max-len
            showErrors: (invalidPropsLength && submitted && isNewRegistrationNumberError && !isCheckboxActive) ? true : false,
            value: isCheckboxActive
        },
        PolicyDocumentLink: {
            href: DocUtil.getFileUrl(brand,'PRIVATE_CAR_POLICY_DOCUMENT', periodStartDate)
        },
        ImportantInformationLink: {
            href: DocUtil.getFileUrl(brand,'IMPORTANT_INFORMATION', periodStartDate)
        },
        InsuranceProductInformationDocumentLink: {
            href: DocUtil.getFileUrl(brand,'CORE_IPID', periodStartDate)
        },
        PrivacyNoticeLink: {
            href: DocUtil.getFileUrl(brand,'PRIVACY_NOTICE', periodStartDate)
        },
        mymarketingpreferencesPrivacyNoticelink: {
            href: DocUtil.getFileUrl(brand,'PRIVACY_NOTICE', periodStartDate)
        },
        LegalExpencesCoverLink: {
            visible: isLegal,
            href: DocUtil.getFileUrl(brand,'LEGAL_EXP_IPID', periodStartDate)
        },
        KeyProductInsuranceProductInformationDocumentLink: {
            visible: isKeys,
            href: DocUtil.getFileUrl(brand,'KEYS_IPID', periodStartDate)
        },
        BreakdownCoverLink: {
            visible: isBreakdown,
            href: BreakdownLevelSelected === "Level3" ? DocUtil.getFileUrl(brand,'BREAKDOWN_IPID_EURO', periodStartDate) : DocUtil.getFileUrl(brand,'BREAKDOWN_IPID_BASIC', periodStartDate)
        },
        EnhancedCourtesyCarCoverLink: {
            visible: isCCCP,
            href: DocUtil.getFileUrl(brand,'ECCC_IPID', periodStartDate)
        }
    };

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onNextAction: onNextAction,
            checkboxFieldAction: checkboxFieldAction,
            onValidate: onValidate,
            writeValue: writeValue
        }
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "PMLegalPage1InformationPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                disableNext={false}
                skipWhen={initialValidation}
                nextLabel={'Continue'}
                previousLabel={'Back'}
                disableCancel={true}
                cancelLabel={''}
            >
                {/* Wrap the form with ScrollToError */}
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    showErrors={submitted}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

PMLegalPage1InformationPage.propTypes = wizardProps;
export default withRouter(PMLegalPage1InformationPage);
