import React, { useState, useEffect } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CookiesComponent.metadata.json5';
import styles from './CookiesComponent.module.scss';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
import { brandingData } from 'gw-portals-branding-js';

function CookiesComponent({ handleModalActivity, getCookie, periodStartDate }) {
    const [isLetMeChooseToggle, setLetMeChooseToggle] = useState(false);
    const [isFunctionalCookies, setFunctionalCookies] = useState(false);
    const [isAnalyticsCookies, setAnalyticsCookies] = useState(false);
    const [isAdvertiseCookies, setAdvertiseCookies] = useState(false);
    const brand = brandingData.BRANDING;

    const setCookie = (name, value) => {
        document.cookie = `${name}=${value || ''}; path=/`;
    };

    const acceptAndAddAllCookies = () => {
        setCookie(`${brand}-PRIVACY-STRICTCOOKIES`, 1);
        setCookie(`${brand}-PRIVACY-FUNCTIONCOOKIES`, 1);
        setCookie(`${brand}-PRIVACY-ANALYTICSCOOKIES`, 1);
        setCookie(`${brand}-PRIVACY-ADVERTCOOKIES`, 1);
        setCookie('consent', true);
        handleModalActivity();
    };

    const dropAllCookies = () => {
        setCookie(`${brand}-PRIVACY-STRICTCOOKIES`, 1);
        setCookie(`${brand}-PRIVACY-FUNCTIONCOOKIES`, 0);
        setCookie(`${brand}-PRIVACY-ANALYTICSCOOKIES`, 0);
        setCookie(`${brand}-PRIVACY-ADVERTCOOKIES`, 0);
        setCookie('consent', true);
        handleModalActivity();
    };

    const letMeChooseAndSaveCookies = () => {
        if (isFunctionalCookies) {
            setCookie(`${brand}-PRIVACY-FUNCTIONCOOKIES`, 1);
        } else {
            setCookie(`${brand}-PRIVACY-FUNCTIONCOOKIES`, 0);
        }

        if (isAnalyticsCookies) {
            setCookie(`${brand}-PRIVACY-ANALYTICSCOOKIES`, 1);
        } else {
            setCookie(`${brand}-PRIVACY-ANALYTICSCOOKIES`, 0);
        }

        if (isAdvertiseCookies) {
            setCookie(`${brand}-PRIVACY-ADVERTCOOKIES`, 1);
        } else {
            setCookie(`${brand}-PRIVACY-ADVERTCOOKIES`, 0);
        }

        setCookie(`${brand}-PRIVACY-STRICTCOOKIES`, 1);
        setCookie('consent', true);
        handleModalActivity();
    };

    const handleLetMeChoose = () => {
        setLetMeChooseToggle(!isLetMeChooseToggle);
    };

    const handleFunctionalCookies = () => {
        setFunctionalCookies(!isFunctionalCookies);
    };

    const handleAnalyticsCookies = () => {
        setAnalyticsCookies(!isAnalyticsCookies);
    };

    const handleAdvertiseCookies = () => {
        setAdvertiseCookies(!isAdvertiseCookies);
    };

    const handleAcceptCookies = () => {
        handleModalActivity();
    };

    useEffect(() => {
        setFunctionalCookies(getCookie(`${brand}-PRIVACY-FUNCTIONCOOKIES`) == 1 ? true : false);
        setAnalyticsCookies(getCookie(`${brand}-PRIVACY-ANALYTICSCOOKIES`) == 1 ? true : false);
        setAdvertiseCookies(getCookie(`${brand}-PRIVACY-ADVERTCOOKIES`) == 1 ? true : false);
    }, []);

    const overrideProps = {
        LetMeChooseToggle: {
            visible: isLetMeChooseToggle,
        },
        FunctionalCookiesSwitch: {
            value: isFunctionalCookies,
        },
        AnalyticsCookiesSwitch: {
            value: isAnalyticsCookies,
        },
        AdvertiseCookiesSwitch: {
            value: isAdvertiseCookies,
        },
        pmCookiesPolicyInformationLink: {
            href: DocUtil.getFileUrl(brand,'COOKIE_POLICY', periodStartDate)
        },
        cookiesExperienceOnOurSiteLink: {
            href: DocUtil.getFileUrl(brand,'COOKIE_POLICY', periodStartDate)
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleLetMeChoose: handleLetMeChoose,
            handleFunctionalCookies: handleFunctionalCookies,
            handleAnalyticsCookies: handleAnalyticsCookies,
            handleAdvertiseCookies: handleAdvertiseCookies,
            handleAcceptCookies: handleAcceptCookies,
            acceptAndAddAllCookies: acceptAndAddAllCookies,
            letMeChooseAndSaveCookies: letMeChooseAndSaveCookies,
            dropAllCookies: dropAllCookies

        }
    };
    return (
        <div>
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </div>
        </div>
    );
}
export default CookiesComponent;
