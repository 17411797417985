import { defineMessages } from 'react-intl';

export default defineMessages({
    pmMarketingPref: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MarketingPref',
        defaultMessage: 'Marketing Preferences',
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-legal-and-payment-page-one',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    },
    pmCarRegistrationNumber: {
        id: 'quoteandbind.pm.views.pm-legal1-information.CarRegistrationNumber',
        defaultMessage: 'You should see your car registration number in the box below, if not please type it into this box now',
    },
    pmDifferentVehicleError: {
        id: 'quoteandbind.pm.views.pm-legal1-information.DifferentVehicleError',
        defaultMessage: 'This vehicle does not match the one that you have been quoted for',
    },
    pmMatchedCarDetailsContent: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MatchedCarDetailsContent',
        defaultMessage: "Thanks, we've matched your car to the details entered in your quote.",
    },
    pmInlineNotificationErrorBarLegalAndPayment1of3: {
        id: 'quoteandbind.pm.views.pm-legal1-information.NotificationErrorBarLegalAndPayment1of3',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    },
    pmWeCouldNotFindThisCar: {
        id: 'quoteandbind.pm.views.pm-legal1-information.WeCouldNotFindThisCar',
        defaultMessage: 'We could not find this car. You must either provide your registration number or enter your car details manually',
    },
    pmQuoteRecalculation: {
        id: 'quoteandbind.pm.views.pm-legal1-information.QuoteRecalculation',
        defaultMessage: 'Please confirm you are aware that you have changed the vehicle details and your quote will be recalculated.',
    },
    pmIacceptThisNewVehicle: {
        id: 'quoteandbind.pm.views.pm-legal1-information.IacceptThisNewVehicle',
        defaultMessage: 'I accept this is a new vehicle',
    },
    pmProvideAValidRegistraionNumber: {
        id: 'quoteandbind.pm.views.pm-legal1-information.IProvideAValidRegistraionNumber',
        defaultMessage: 'If you do not want to do this then please provide a valid Registration Number above.',
    },
    pmPolicyDocumentsHeader: {
        id: 'quoteandbind.pm.views.pm-legal1-information.PolicyDocumentsHeader',
        defaultMessage: 'Policy Documents',
    },
    pmCarInsuranceDocuments: {
        id: 'quoteandbind.pm.views.pm-legal1-information.CarInsuranceDocuments',
        defaultMessage: 'Below are the car insurance documents for your policy, based on the start date you have selected. Please read these documents to ensure that this policy meets your needs and you are aware of the benefits and exclusions.',
    },
    pmImportantInformationLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.ImportantInformationLink',
        defaultMessage: 'Important Information',
    },
    pmPrivacyDocumentLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.PrivacyDocumentLink',
        defaultMessage: 'Privacy Document',
    },
    pmInsuranceProductInformationDocumentLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.InsuranceProductInformationDocumentLink',
        defaultMessage: 'Insurance Product Information Document',
    },
    pmPrivacyNoticeLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.PrivacyNoticeLink',
        defaultMessage: 'Privacy Notice',
    },
    pmKeyLegalExpencesCoverLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.LegalExpencesCoverLink',
        defaultMessage: 'Legal Expenses Cover: Insurance Product Information Document',
    },
    pmBreakdownCoverLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.BreakdownCoverLink',
        defaultMessage: 'Breakdown Cover: Insurance Product Information Document',
    },
    pmEnhancedCourtesyCarCoverLink: {
        id: 'quoteandbind.pm.views.pm-legal1-information.EnhancedCourtesyCarCoverLink',
        defaultMessage: 'Enhanced Courtesy Car Cover: Insurance Product Information Document',
    },
    pmYourDocuments: {
        id: 'quoteandbind.pm.views.pm-legal1-information.YourDocuments',
        defaultMessage: 'Your Documents',
    },
    pmEasyWayToJoinUs: {
        id: 'quoteandbind.pm.views.pm-legal1-information.EasyWayToJoinUs',
        defaultMessage: 'An easy way to join us in helping to protect our planet is by having your documents issued electronically. You can update your preference to request documents via post at any time by calling our dedicated Customer Service team.',
    },
    pmMyMarketingPreferences: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MyMarketingPreferences',
        defaultMessage: 'My Marketing Preferences',
    },
    pmMarketingEmailsAndSmsMessages: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MarketingEmailsAndSmsMessages',
        defaultMessage: 'We would like to send you marketing emails and SMS messages about our products and service that we think may be of interest to you. For details, please see our ',
    },
    pmMarketingEmailsAndSmsMessagesText: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MarketingEmailsAndSmsMessagesText',
        defaultMessage: 'Privacy Notice. ',
    },
    pmMarketingEmailsAndSmsMessagesContent: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MarketingEmailsAndSmsMessagesContent',
        defaultMessage: ' Please select your preference below:',
    },
    pmMarketingEmailsAndSmsMessagesRequired: {
        id: 'quoteandbind.pm.views.pm-legal1-information.MarketingEmailsAndSmsMessagesRequired',
        defaultMessage: '*',
    }
});
