import { defineMessages } from 'react-intl';

export default defineMessages({
    pmAccountHolderContactInfo: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.AccountHolderContactInfo',
        defaultMessage: 'Account Holder Contact Info',
    },
    pmDriver: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.Driver',
        defaultMessage: 'Driver',
    },
    pmDriverName: {
        id: 'quoteandbind.pm.views.pm-driver-details.pm-driver-qualification.Name',
        defaultMessage: 'Name',
    },
    pmLicenseNumber: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.License Number',
        defaultMessage: 'License Number',
    },
    pmVehicle: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.Vehicle',
        defaultMessage: 'Vehicle',
    },
    pmCostNew: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.CostNew',
        defaultMessage: 'Cost New',
    },
    pmUseOfVehicle: {
        id: 'quoteandbind.pm.views.pm-policy-info.UseofVehicle',
        defaultMessage: 'Use of Vehicle',
    },
    pmCDistanceDriven: {
        id: 'quoteandbind.pm.views.pm-driver-qualification.DistanceDriven',
        defaultMessage: 'Distance Driven',
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-driver-error-one-of-two',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    }
});
