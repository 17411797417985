import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver',
        defaultMessage: 'Driver',
    },
    paDriverTitleNumber: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver:',
        defaultMessage: 'Driver:',
    },
    paDriver: {
        id: 'quoteandbind.views.quote.Personal Auto Driver',
        defaultMessage: 'Personal Auto Driver',
    },
    removeDriverTitle: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.Remove Driver?',
        defaultMessage: 'Remove Driver?',
    },
    removeDriverDescription: {
        id: 'quoteandbind.pa.drivers.remove-driver.message',
        defaultMessage: 'Are you sure you want to remove this driver from the list of drivers?',
    },
    paAddAnotherDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?',
    },
    paAddDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Driver',
        defaultMessage: 'Add Driver',
    }
});
