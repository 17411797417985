import { defineMessages } from 'react-intl';

export default defineMessages({
    choose: {
        id: 'platform.questionsets.question-set.-- Choose --',
        defaultMessage: '-- Choose --'
    },
    required: {
        id: 'gw-portals-questionsets-js.answer.validation.This is a required field',
        defaultMessage: 'This is a required field'
    },
    invalidCharacter: {
        id: 'gw-portals-questionsets-js.answer.validation.An invalid character was entered, such as a letter in a numeric field',
        defaultMessage: 'An invalid character was entered, such as a letter in a numeric field.'
    },
    pageTitle: {
        id: 'quoteandbind.views.prequal-questions.Qualification',
        defaultMessage: 'Qualification'
    },
    typeListPlaceholder: {
        id: 'platform.digital-modules-ui.gw-portals-questionsets-js.-- Choose --',
        defaultMessage: '-- Choose --'
    }
});
