import React, {
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useModal
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { InactivityTimerService } from 'gw-portals-auth-inactivity-timer-js';
import PropTypes from 'prop-types';
import messages from './LogoutWarning.messages';

const CHOICE_CONTINUE = 'choice-continue';
const CHOICE_TIMEOUT = 'choice-timeout';

function InactiveConfirmModal(props) {
    const {
        isOpen,
        onResolve,
        logoutConfirmationInterval
    } = props;

    const logoutConfirmationIntervalMs = logoutConfirmationInterval * 1000 * 60;

    const translator = useTranslator();
    const choseContinue = useCallback(() => {
        onResolve(CHOICE_CONTINUE);
    }, [onResolve]);
    const timedOut = useCallback(() => {
        onResolve(CHOICE_TIMEOUT);
    }, [onResolve]);

    useEffect(() => {
        const closeModalTimeoutId = setTimeout(timedOut, logoutConfirmationIntervalMs);
        return () => window.clearTimeout(closeModalTimeoutId);
    }, [logoutConfirmationInterval, logoutConfirmationIntervalMs, onResolve, timedOut]);

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader
                title={messages.sessionTimeout}
                status="warning"
                icon="mi-error-outline"
            />
            <ModalBody>
                {translator(messages.warningAboutToExpire)}
            </ModalBody>
            <ModalFooter>
                <Button onClick={choseContinue}>
                    {translator(messages.continueSession)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

InactiveConfirmModal.propTypes = {
    isOpen: PropTypes.string.isRequired,
    logoutConfirmationInterval: PropTypes.number.isRequired,
    onResolve: PropTypes.func.isRequired
};

function LogoutWarningModal({
    onEndSession,
    modalAutoCloseTimeout,
    userInactivityTimeout
}) {
    const {
        showModal
    } = useModal();
    const [userInactive, setUserInactive] = useState(false);

    const onUserInactive = useCallback(() => {
        setUserInactive(true);
    }, []);

    const inactivityService = useMemo(() => {
        return new InactivityTimerService({
            onUserInactive,
            inactivityIntervalMins: userInactivityTimeout
        });
    }, [onUserInactive, userInactivityTimeout]);

    useEffect(() => {
        return () => inactivityService.detach();
    }, [inactivityService]);

    useEffect(() => {
        if (userInactive) {
            showModal(
                <InactiveConfirmModal
                    logoutConfirmationInterval={modalAutoCloseTimeout}
                />
            ).then((choice) => {
                if (choice === CHOICE_CONTINUE) {
                    inactivityService.resetInactivityTimer();
                    setUserInactive(false);
                } else if (choice === CHOICE_TIMEOUT) {
                    onEndSession();
                }
            }, _.noop);
        }
    }, [inactivityService, modalAutoCloseTimeout, onEndSession, userInactive]);

    return null;
}

LogoutWarningModal.propTypes = {
    /** The action to be performed when the user is inactive, and failed to communicate activity */
    onEndSession: PropTypes.func.isRequired,
    /**  The time in minutes before the modal will autoclose and end the session */
    modalAutoCloseTimeout: PropTypes.number.isRequired,
    /** THe time in minutes before a user is prompted for inactivity */
    userInactivityTimeout: PropTypes.number.isRequired
};

export default LogoutWarningModal;
