import { defineMessages } from 'react-intl';

export default defineMessages({
    PurchaseFailed: {
        id: 'quoteandbind.views.purchased-failed.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    PurchaseFailedWarning: {
        id: 'quoteandbind.views.purchased-failed.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    PurchaseFailedSolution: {
        id: 'quoteandbind.views.purchased-failed.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.purchased-failed.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.purchased-failed.Reference Number',
        defaultMessage: 'Reference Number',
    }

});
