import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _, { has, set } from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { withRouter } from 'react-router-dom';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './SuccessPage.metadata.json5';
import styles from './SuccessPage.module.scss';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { brandingData } from 'gw-portals-branding-js';
import htmlParser from 'html-react-parser';
import { TranslatorContext } from '@jutro/locale';
import messagesTranslationsSet from './SuccessPage.messages';

function SuccessPage(props) {
    const { wizardData: submissionVM, updateWizardData, history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate } = useValidation('SuccessPage');
    const [isPageInitialized, setPageInitialized] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isActivated, setActivatedState] = useState(false);
    const [isPasswordSet, setPasswordSetState] = useState(false);
    const [newPassword, setNewPasswordState] = useState('');
    const [hasUserAccountBeenActivated, setUserAccountBeenActivatedState] = useState(false);
    const [hasAccountSetupExpired, setAccountSetupExpiredState] = useState(false);
    const [canUserAccessAccount, setUserAccessAccountState] = useState(false);
    const [isEmailAlreadyActivated, setEmailAlreadyActivatedState] = useState(false);
    const [isUserAccountBeenActivated, setUserAccountBeenActivated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [continueBtnDisabled, setContinueBtnDisabled] = useState(true);
    const { UserProfileService } = useDependencies('UserProfileService');
    const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const translator = useContext(TranslatorContext);
    const brand = brandingData.BRANDING;

    useEffect(() => {
        if (!isPageInitialized) {
            try {
                const quoteID = _.get(submissionVM, 'quoteID');
                const sessionUUID = _.get(submissionVM, 'sessionUUID');
                Promise.all([
                    UserProfileService.hasUserAccountBeenActivated(sessionUUID, quoteID),
                    UserProfileService.hasAccountSetupExpired(sessionUUID, quoteID),
                    UserProfileService.canUserAccessAccount(sessionUUID, quoteID)
                ]).then(([userAccountBeenActivated, accountSetupExpired, userAccessAccount]) => {
                    setUserAccountBeenActivatedState(userAccountBeenActivated);
                    setAccountSetupExpiredState(accountSetupExpired);
                    setUserAccessAccountState(userAccessAccount);
                    setPageInitialized(true);
                }).catch((error) => {
                    history.push({
                        pathname: '/error',
                        data: error,
                        origin: "SuccessPage [UserProfileService calls]",
                        quoteID: _.get(submissionVM.value, 'quoteID') || ''
                    });
                });
            } catch (error) {
                // re-throw this error within the updater function
                // it will be triggered during state update
                history.push({
                    pathname: '/error',
                    data: error,
                    origin: "SuccessPage [UserProfileService calls]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            }
        }
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM, UserProfileService]);

    const handleActivatePortal = useCallback(() => {
        setEmailAlreadyActivatedState(!canUserAccessAccount);
        setUserAccountBeenActivated(hasUserAccountBeenActivated);
        setActivatedState(true);
    }, [canUserAccessAccount, hasUserAccountBeenActivated]);

    const handlePasswordSet = useCallback(async () => {
        try {
            const quoteID = _.get(submissionVM, 'quoteID');
            const sessionUUID = _.get(submissionVM, 'sessionUUID');
            if (newPassword != null && newPassword !== '') {
                await UserProfileService.setupUserAccount(sessionUUID, quoteID, newPassword);
                setPasswordSetState(true);
            }
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "SuccessPage [handlePasswordSet]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [submissionVM, newPassword, UserProfileService, history]);

    const handlePasswordValueChange = useCallback(
        (value) => {
            setNewPasswordState(value);
            const passwordRegex = VALID_PASSWORD_REGEX;
            if (passwordRegex.test(value)) {
                setContinueBtnDisabled(false);
            } else {
                setContinueBtnDisabled(true);
            }
        },
        [setNewPasswordState, VALID_PASSWORD_REGEX]
    );

    const handleLoginPortal = useCallback(async () => {
        await LoadSaveService.getPortalDomain_AND(brand)
            .then((url) => {
                window.location.href = url;
            });
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        activatePortalPage: {
            visible: !isActivated && !isPasswordSet && !hasAccountSetupExpired
        },
        setPasswordPage: {
            visible: isActivated && !isPasswordSet && !hasUserAccountBeenActivated && !hasAccountSetupExpired && canUserAccessAccount
        },
        portalActivatedPage: {
            visible: isActivated && isPasswordSet && !hasUserAccountBeenActivated && !hasAccountSetupExpired && canUserAccessAccount
        },
        errorExpiredPage: {
            visible: !hasUserAccountBeenActivated && hasAccountSetupExpired && canUserAccessAccount
        },
        errorUserHasNoAccessPage: {
            visible: isEmailAlreadyActivated || isUserAccountBeenActivated
        },
        errorUserHasNoAccessDetails2: {
            content: htmlParser(translator(messagesTranslationsSet[`Number${brand.toUpperCase()}`].defaultMessage))
        },
        paymentSuccessThankYouMessage: {
            content: htmlParser(translator(messagesTranslationsSet[`paymentSuccessActivateYourPortal${brand.toUpperCase()}`].defaultMessage))
        },
        createPassword: {
            showRequired: true,
            required: true,
            onValueChange: handlePasswordValueChange,
            labelPosition: 'top',
            value: newPassword,
            inputType: showPassword ? 'text' : 'password',
            validationMessages: (newPassword == '' || null) ? ['This field is required'] : [],
            validator: {
                pattern: VALID_PASSWORD_REGEX,
                message: {
                    id: 'passwordValidationMessage',
                    defaultMessage: 'Password must be at least 8 characters, contain at least 1 digit, 1 uppercase and 1 lowercase character'
                }
            },
        },
        showPassword: {
            content: showPassword ? 'Hide' : 'Show',
            labelPosition: 'right',
            onClick: () => {
                setShowPassword(!showPassword);
            }
        },
        firstName: {
            content: _.get(submissionVM, 'baseData.accountHolder.firstName') + ' - '
        },
        continueBtn: {
            disabled: continueBtnDisabled
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onActivatePortal: handleActivatePortal,
            onSetPassword: handlePasswordSet,
            onLoginPortal: handleLoginPortal
        }
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "SuccessPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage showPrevious={false} showNext={false} showCancel={false}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </WizardPage>
        </ErrorBoundary>
    );
}

SuccessPage.propTypes = wizardProps;
export default withRouter(SuccessPage);