import React from 'react';
import { FieldComponent, Link } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import styles from './FieldLinkComponent.module.scss';

class FieldLinkComponent extends FieldComponent {
    static contextType = TranslatorContext;

    static propTypes = {
        ...FieldComponent.propTypes,
    };

    renderControl() {
        const { value } = this.props;
        return (
            <Link className={styles.fieldLinkColor} {...this.props}>
                {value}
            </Link>
        );
    }

    render() {
        return super.render();
    }
}

export default FieldLinkComponent;
