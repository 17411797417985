//import _ from 'underscore';
//import localeConfig from 'app-locale';

//const nameConfig = localeConfig.name;

function _append(sb, fieldId, value) {
    //if (!_.isEmpty(value) && _.contains(nameConfig.visibleFields, fieldId)) {
    //if (_.contains(nameConfig.visibleFields, fieldId)) {
        sb.push(value);
    //}
}

function _format(person, delimiter) {
    const lastName = person.lastNameKanji || person.lastName;
    const firstName = person.firstNameKanji || person.firstName;
    const result = [];
    /* switch (nameConfig.formatMode) {
        case 'default':
            _append(result, 'FirstName', firstName);
            _append(result, 'LastName', lastName);
            break;
        case 'France':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        case 'Japan':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        case 'China':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        default:
            break;
    } */
    _append(result, 'FirstName', firstName);
    _append(result, 'LastName', lastName);
    return result.join(delimiter);
}

function Person(data) {
    this.getDisplayName = function (defaultName, $filter) {
        return _format(this, ' ') || defaultName || $filter('translate')('quoteandbind.controllers.this driver');
    };

    if (data) {
        _.extend(this, data);
        if (!!data.firstName) {
            data.firstName = '';
        }
        if (!!data.lastName) {
            data.lastName = '';
        }
    }
}

export default Person;
