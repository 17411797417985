import { defineMessages } from 'react-intl';

export default defineMessages({
    generalCoverages: {
        id: 'quoteandbind.pa.directives.MultiQuoteView.General Coverages - Apply to all vehicles',
        defaultMessage: 'General Coverages - Apply to all vehicles',
    },
    vehicleSpecificCoverage: {
        id: 'quoteandbind.pa.directives.MultiQuoteView.Vehicle-',
        defaultMessage: 'Vehicle-Specific Coverages - ',
    }
});
