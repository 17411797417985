/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    useModal
} from '@jutro/components';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { useHistory } from 'react-router-dom';
import messages from './PMContactDetailsComponent.messages';
import styles from './PMContactDetailsComponent.module.scss';
import metadata from './PMContactDetailsComponent.metadata.json5';

function PMContactDetailsComponent(props) {
    const {
        showConfirm
    } = useModal();
    const translator = useTranslator();
    const {
        data: submissionVM,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        handleLandlineNumChange,
        handlePhoneNumChange,
        handleEmailChange,
        submitted,
        showOptional,
        phoneWide
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [ refresh, setRefresh ] = useState();
    const history = useHistory();
    const [disableEmailButton, setDisableEmailButton] = useState(false);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const sessionEnded = useCallback(() => {
        window.sessionStorage.clear();
        history.push({
            pathname: '/session-ended',
        });
    }, [history]);

    const handleEmailQuote = useCallback(() => {
        const quoteID = _.get(submissionVM, 'quoteID.value');
        const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
        const emailAddress = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');

        const emailInfo = {
            quoteID: quoteID,
            sessionID: sessionUUID
        };

        showConfirm({
            title: messages.emailSent,
            message: translator(messages.emailQuoteDescription, { quoteID, emailAddress }),
            status: 'info',
            icon: 'gw-error-outline',
            confirmButtonText: messages.sessionEndedButtonText,
            cancelButtonText: messages.returnToQuoteButtonText
        }).then((results) => {
            if (results === 'confirm') {
                return sessionEnded();
            }
        });
        setDisableEmailButton(true);
        EmailQuoteService.emailQuote(emailInfo)
            .then(() => {
                setDisableEmailButton(false);
            })
            .catch(() => {
                showConfirm({
                    title: messages.error,
                    message: translator(messages.cantSendQuoteDetailsAtThisTime),
                    status: 'error',
                    icon: 'gw-error-outline',
                });
                setDisableEmailButton(false);
            });
    }, [sessionEnded, showConfirm, submissionVM, translator]);

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const onPhoneNumChange = useCallback((value, dataPath) => {
        if (handlePhoneNumChange) {
            handlePhoneNumChange(value, dataPath);
        }
        setRefresh(true);
    }, [handlePhoneNumChange]);

    const onLandlineNumChange = useCallback((value, dataPath) => {
        if (handleLandlineNumChange) {
            handleLandlineNumChange(value, dataPath);
        }
        setRefresh(true);
    }, [handleLandlineNumChange]);

    const onEmailChange = useCallback((value, dataPath) => {
        if (handleEmailChange) {
            handleEmailChange(value, dataPath);
        }
        setRefresh(true);
    }, [handleEmailChange]);

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
        },
        [`emailAddress${index}`]: {
            value: submissionVM.baseData.accountHolder.emailAddress1.value,
            validationMessages: (submissionVM.baseData.accountHolder.emailAddress1.value == '' || submissionVM.baseData.accountHolder.emailAddress1.value == null) ? [messages.thisFieldIsRequiredErrorMessage.defaultMessage] : [],
            validator: {
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/,
                message: {
                    id: 'emailValidationMessage',
                    defaultMessage: messages.thisFieldIsRequiredErrorMessage.defaultMessage
                }
            },
            onValueChange: onEmailChange
        },
        [`mobile-field${index}`]: {
            validationMessages: (submissionVM.baseData.accountHolder.cellNumber.value == '' || submissionVM.baseData.accountHolder.cellNumber.value == null) ? [messages.thisFieldIsRequiredErrorMessage.defaultMessage] : [],
            validator: {
                pattern: /^(07700900[0-9]{3})|(07[0-9]{9})$/,
                message: {
                    id: 'mobileValidationMessage',
                    defaultMessage: messages.thisFieldIsRequiredErrorMessage.defaultMessage
                }
            },
            value: submissionVM.baseData.accountHolder.cellNumber.value,
            onValueChange: onPhoneNumChange
        },
        [`landline-field${index}`]: {
            validator: {
                pattern: /^[0-9]{4,11}$/,
                message: {
                    id: 'landlineValidationMessage',
                    defaultMessage: messages.thisFieldIsRequiredErrorMessage.defaultMessage
                }
            },
            onValueChange: onLandlineNumChange,
            validationMessages: [],
            value: submissionVM.baseData.accountHolder.homeNumber.value,
        },
        [`contactDetailsButtonSaveQuote${index}`]: {
            disabled: disableEmailButton
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            printQuote: handlePrint,
            emailQuote: handleEmailQuote
        }
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMContactDetailsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    handlePhoneNumChange: PropTypes.func.isRequired,
    handleLandlineNumChange: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string,
    submitted: PropTypes.bool
};
PMContactDetailsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0,
    submitted: undefined
};
export default PMContactDetailsComponent;
