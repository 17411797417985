import React from 'react';
import classNames from 'classnames';
import styles from './PMQuotePage_Benefits.module.scss';
import messages from './PMQuotePage_Benefits.messages';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
import { brandingData } from 'gw-portals-branding-js';
import {Segmentation, SegmentationOption} from 'gw-portals-segmentation-react';
import PropTypes from 'prop-types';

const params = {
    compareBy: brandingData.BRANDING
};

const tyaBenefits = [
    messages.toyLexParts.defaultMessage,
    messages.keyProtect.defaultMessage,
    messages.courtesyCar.defaultMessage,
    messages.uninsuredDriver.defaultMessage,
    messages.vandalismCover.defaultMessage,
    messages.drivingAbroadTYA.defaultMessage
];
const kfiBenefits = [
    messages.misfuellingKFI.defaultMessage,
    messages.keyProtect.defaultMessage,
    messages.courtesyCar.defaultMessage,
    messages.uninsuredDriver.defaultMessage,
    messages.vandalismCover.defaultMessage,
    messages.drivingAbroadKFI.defaultMessage
];

let benefitsList = [];
if (brandingData.BRANDING === 'tya') {
    tyaBenefits.forEach((benefit, index) => {
        benefitsList.push(<li key={index}>{benefit}</li>);
    });
} else if (brandingData.BRANDING === 'kfi') {
    kfiBenefits.forEach((benefit, index) => {
        benefitsList.push(<li key={index}>{benefit}</li>);
    });
}

function PMQuotePage_BenefitsSection({ data }) {
    const brand = brandingData.BRANDING;
    const periodStartDate = _.get(data, 'baseData.periodStartDate.value', null);
    const insuranceProductInfoUrl = DocUtil.getFileUrl(brand, 'CORE_IPID', periodStartDate);
    return (
        <div id="benefitsSectionContainer">
            <div id="yourBenefitsHeader" className="gw-sub-section-title-seperator">
                <h2>{messages.benefitsTitle.defaultMessage}</h2>
            </div>
            <div className={styles.andBenefitsLabelList}>
                <ul>{benefitsList}</ul>
            </div>
            <div>
                <Segmentation type="brand" params={params}>
                    <SegmentationOption is='tya'>
                        <p>{messages.benefitsNote_Applies.defaultMessage}</p>
                    </SegmentationOption>
                </Segmentation>
                <p>{messages[`benefitsNote_1${brand.toUpperCase()}`].defaultMessage}</p>
                <div className={styles.benefitsNote_2}>{messages.benefitsNote_2.defaultMessage}</div>
                <br />
                <p>
                    <span>{messages.benefitsNote_IPID_preLink.defaultMessage}</span>
                    <a href={insuranceProductInfoUrl} target="_blank" rel="noopener noreferrer" className={styles.andQuoteProductInformationDocumentLink}>
                        {messages.benefitsNote_IPID_link.defaultMessage}
                    </a>
                    <span>{messages[`benefitsNote_IPID_postLink${brand.toUpperCase()}`].defaultMessage}</span>
                </p>
                <br />
            </div>
        </div>
    );
}

PMQuotePage_BenefitsSection.propTypes = {
    data: PropTypes.object
};

export default PMQuotePage_BenefitsSection;
