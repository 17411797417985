import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentTypeChecking: {
        id: 'endorsement.directives.templates.bank-account-details.Checking',
        defaultMessage: 'Checking'
    },
    paymentTypeSavings: {
        id: 'endorsement.directives.templates.bank-account-details.Savings',
        defaultMessage: 'Savings'
    },
    paymentOptions: {
        id: 'quoteandbind.views.payment-details.Payment Options',
        defaultMessage: 'Payment Options',
    },
    averageMonthlyPremium: {
        id: 'quoteandbind.views.payment-details.Average Monthly Premium',
        defaultMessage: 'Average Monthly Premium:',
    },
    paymentDetails: {
        id: 'quoteandbind.views.payment-details.Payment Details',
        defaultMessage: 'Payment Details',
    },
    totalPremium: {
        id: 'quoteandbind.views.payment-details.Total Premium:',
        defaultMessage: 'Total Premium:',
    },
    paymentBuyNow: {
        id: 'quoteandbind.views.payment-details.Buy Now',
        defaultMessage: 'Buy Now'
    }
});
