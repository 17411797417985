import { defineMessages } from 'react-intl';

export default defineMessages({
    ErrorPage: {
        id: 'quoteandbind.views.tech-error.Tech Error',
        defaultMessage: 'Tech Error',
    },
    ErrorPageWarning: {
        id: 'quoteandbind.views.tech-error.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    ErrorPageSolution: {
        id: 'quoteandbind.views.tech-error.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.tech-error.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.tech-error.Reference Number',
        defaultMessage: 'Reference Number',
    },
    getAssistanceTextTYA: {
        id: 'quoteandbind.views.tech-error.get assistance contact details tya',
        defaultMessage: 'If you contact our Customer Service team on 0330 022 9450 they will be able to continue where you left off.',
    },
    getAssistanceTextKFI: {
        id: 'quoteandbind.views.tech-error.get assistance contact details kfi',
        defaultMessage: 'If you contact our Customer Service team on 0333 103 9670 they will be able to continue where you left off.',
    },
});
