/* eslint-disable max-len */
import _ from 'lodash';

export default {
    invalidChildProperties: (submissionVM, strOriginPage = '') => {
        const stepsValidatingBindData = ['PMLegalPage1InformationPage', 'PMLegalPage2InformationPage', 'PMLegalPage3InformationPage', 'PMLegalPage2InformationMonthly'];
        
        let invalidChildPropertiesArr = [];
        submissionVM.aspects.invalidChildProperties.forEach((element) => {
            if (!element.includes('bindData') && !stepsValidatingBindData.includes(strOriginPage)) {
                invalidChildPropertiesArr.push(element);
            }
        });

        return invalidChildPropertiesArr;
    }
};
