const importAll = (requirement) => {
    requirement.keys().forEach(requirement);
};

importAll(
    require.context(
        // eslint-disable-next-line no-undef
        '../../../../framework/packages/@jutro/components/widgets/Icon/icons',
        true,
        /.*(gw|cust)-.*\.svg$/
    )
);
