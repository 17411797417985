import HeaderLinkComponent from './HeaderLinkComponent/HeaderLinkComponent';
import ClauseComponent from './Clauses/ClauseComponent';
import ClausesComponentVM from './Clauses/ClausesComponentVM';
import SingleClauseComponentVM from './Clauses/SingleClauseComponentVM';
import './platform-styles.scss';
import ImageComponent from './ImageComponent/ImageComponent';
import FieldLinkComponent from './FieldLinkComponent/FieldLinkComponent';
import PaymentList from './PaymentList/PaymentList';
import MediaSideBar from './MediaSidebarComponent/MediaSideBar';
import TypeAheadComponent from './TypeAheadComponent/TypeAheadComponent';
import KnockOutPage from './KnockOutPageComponent/KnockOutPage';
import CreditCardExpiryDate from './CreditCardExpiryDate/CreditCardExpiryDate';
import TileComponent from './TileComponent/TileComponent';
// eslint-disable-next-line import/no-named-as-default
import FacebookShare from './FacebookShare/FacebookShare';
import QuoteClauseTable from './QuoteClauseTable/QuoteClauseTable';
import ScheduleItemsComponent from './ScheduleItemsComponent/ScheduleItemsComponent';
import CustomizedHeaderComponent from './CustomizedHeaderComponent/CustomizedHeaderComponent';
import PaymentComponent from './PaymentComponent/PaymentComponent';
import UnderwritingIssues from './UnderwritingIssues/UnderwritingIssues';
import EntryPageTemplate from './EntryPageTemplate/EntryPageTemplate';
import './CustomHTMLElement/CustomHTMLElement';
import SuccessNotification from './SuccessNotification/SuccessNotification';
import uwIssuesMessages from './UnderwritingIssues/UnderwritingIssues.messages';
import Currency from './CurrencyField/CurrencyField';
import HelpIcon from './HelpIcon/HelpIcon';
import CapabilityRoute from './CapabilityRoute';
import Captcha from './Captcha/Captcha';

const platformComponentMap = {
    ClauseComponent: { component: 'ClauseComponent' },
    ClausesComponentVM: { component: 'ClausesComponentVM' },
    CreditCardExpiryDate: { component: 'CreditCardExpiryDate' },
    FacebookShare: { component: 'FacebookShare' },
    fieldLinkComponent: { component: 'FieldLinkComponent' },
    headerLinkComponent: { component: 'HeaderLinkComponent' },
    ImageComponent: { component: 'ImageComponent' },
    link: { component: 'link' },
    mediaSidebar: { component: 'MediaSideBar' },
    PaymentList: { component: 'PaymentList' },
    QuoteClauseTable: { component: 'QuoteClauseTable' },
    ScheduleItemsComponent: { component: 'ScheduleItemsComponent' },
    CustomizedHeaderComponent: { component: 'CustomizedHeaderComponent' },
    SingleClauseComponentVM: { component: 'SingleClauseComponentVM' },
    TileComponent: { component: 'TileComponent' },
    TypeAheadComponent: { component: 'TypeAheadComponent' },
    PaymentComponent: { component: 'PaymentComponent' },
    EntryPageTemplate: { component: 'EntryPageTemplate' },
    UnderwritingIssues: { component: 'UnderwritingIssues' },
    SuccessNotification: { component: 'SuccessNotification' },
    // override both to preserve consistency for metadata usage
    Currency: { component: 'Currency' },
    currency: { component: 'currency' },
    Captcha: { component: 'Captcha' },
};

const platformComponents = {
    ClauseComponent: ClauseComponent,
    ClausesComponentVM: ClausesComponentVM,
    CreditCardExpiryDate,
    FacebookShare,
    FieldLinkComponent: FieldLinkComponent,
    HeaderLinkComponent: HeaderLinkComponent,
    ImageComponent,
    MediaSideBar,
    PaymentList,
    QuoteClauseTable,
    ScheduleItemsComponent,
    CustomizedHeaderComponent,
    SingleClauseComponentVM,
    TileComponent,
    TypeAheadComponent,
    PaymentComponent,
    UnderwritingIssues,
    SuccessNotification,
    Captcha,
    // override both to preserve consistency for metadata usage
    Currency,
    currency: Currency,
    EntryPageTemplate
};

export {
    CapabilityRoute,
    ClauseComponent,
    ClausesComponentVM,
    CreditCardExpiryDate,
    FacebookShare,
    FieldLinkComponent,
    HeaderLinkComponent,
    ImageComponent,
    MediaSideBar,
    PaymentList,
    SingleClauseComponentVM,
    TileComponent,
    TypeAheadComponent,
    CustomizedHeaderComponent,
    PaymentComponent,
    Currency,
    KnockOutPage,
    UnderwritingIssues,
    SuccessNotification,
    Captcha,
    // for Jutro
    platformComponentMap,
    platformComponents,
    // messages
    uwIssuesMessages,
    HelpIcon,
    EntryPageTemplate
};
