import { defineMessages } from 'react-intl';

export default defineMessages({
    pmAccordionReviewCardTitle: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Title',
        defaultMessage: 'Please check your details below and ensure that the cover you have selected meets your needs:',
    },
    pmAccordionAboutYou: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.About you',
        defaultMessage: 'About you',
    },
    pmAccordionPolicyholder: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Policyholder',
        defaultMessage: 'Policyholder',
    },
    pmAccordionDateOfBirth: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Date of birth',
        defaultMessage: 'Date of birth',
    },
    pmAccordionCoverLevel: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Cover level',
        defaultMessage: 'Cover level',
    },
    pmAccordionComprehensive: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Comprehensive',
        defaultMessage: 'Comprehensive',
    },
    pmAccordionHowUseCar: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.How use car',
        defaultMessage: 'How you use your car?',
    },
    pmAccordionEditDetails: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Edit details',
        defaultMessage: 'Amend your details',
    },
    pmAccordionPrimaryOccupation: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Primary Occupation',
        defaultMessage: 'Primary Occupation',
    },
    pmAccordionAddress: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Address',
        defaultMessage: 'Address',
    },
    pmAccordionAmendYourDetails: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Amend your details',
        defaultMessage: 'Amend your details',
    },
    pmAccordionHomeowner: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Homeowner',
        defaultMessage: 'Homeowner',
    },
    pmAccordionDrivers: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Drivers',
        defaultMessage: 'Drivers',
    },
    pmAccordionYourCar: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Your car',
        defaultMessage: 'Your car',
    },
    pmAccordionYourCover: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Your cover',
        defaultMessage: 'Your cover',
    },
    pmAccordionCoverStartDate: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Cover start date',
        defaultMessage: 'Cover start date',
    },
    pmAccordionCoverExpiryDate: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Expiry date',
        defaultMessage: 'Expiry date',
    }
});
