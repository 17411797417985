/* eslint-disable quote-props */
import _ from 'lodash';

const urlStepIndexMap = {
    "/about-you": 0,
    "/driver-details": 1,
    "/driver-summary": 1,
    "/vehicle-details": 2,
    "/quote": 3,
    "/legal-and-info-1": 4,
    "/legal-and-info-2": 4,
    "/legal-and-info-2-monthly": 4,
    "/legal-and-info-3": 4,
    "/payment": 4,
    "/success-payment": 5, //no index of 5 but allows to complete all steps
    'default': 0
};

export default {
    getStepsArray(history, currentSteps) {
        let updatedSteps = currentSteps;
        if(!history | !history.location | !history.location.pathname ) {
            return updatedSteps;
        }
        const stepIndexId = urlStepIndexMap[history.location.pathname] || urlStepIndexMap.default;
        updatedSteps.forEach((step, index) => {
            if(index === stepIndexId) {
                step.active = true;
                step.disabled = false;
                step.visited = true;
            }
            else {
                step.active = false;
                if(index < stepIndexId) {
                    step.disabled = false;
                    step.visited = true;
                } else {
                    step.disabled = true;
                    step.visited = false;
                }
            }
        });
        return updatedSteps;
    }
};
