/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';

import metadata from './PMQuoteReviewCarComponent.metadata.json5';
import './PMQuoteReviewCarComponent.messages';
import styles from '../Stylesheets/PMQuoteReview.module.scss';

function PMQuoteReviewCarComponent(props) {
    const {
        data: submissionVM,
        readOnlyFields,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        wizardData,
        updateWizardData,
        jumpTo,
        history,
        navigateToDetails,
    } = props;
    const translator = useTranslator();
    const vehicleVM = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0]');
    const monthsList = _.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.aspects.availableValues');
    const vehicle = vehicleVM.value;

    const dayParking = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'dayTimeParking.aspects.availableValues');
        let dayParking = availableValues.find((item) => item.code === vehicle.dayTimeParking);
        return dayParking ? translator({
            id: dayParking.name,
            defaultMessage: dayParking.name
        }) : '';
    };

    const nightParking = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'nightTimeParking.aspects.availableValues');
        let nightParking = availableValues.find((item) => item.code === vehicle.nightTimeParking);
        return nightParking ? translator({
            id: nightParking.name,
            defaultMessage: nightParking.name
        }) : '';
    };

    const securityDevices = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'securityDevices.aspects.availableValues');
        let securityDevices = availableValues.find((item) => item.code === vehicle.securityDevices);
        return securityDevices ? translator({
            id: securityDevices.name,
            defaultMessage: securityDevices.name
        }) : '';
    };

    const dataPurchasedFormatter = () => {
        let monthIndex = 0;
        if (vehicle.purchasedMonth && vehicle.purchasedYear) {
            if (typeof vehicle.purchasedMonth === 'string') {
                monthIndex = monthsList.findIndex(obj => obj.code === vehicle.purchasedMonth);
            } else {
                monthIndex = vehicle.purchasedMonth;
            }
        }

        return { month: monthIndex, year: vehicle.purchasedYear };
    };

    const onAmendYourCarDetailsClick = () => {
        const initialWizardStepID = 2;
        jumpTo(initialWizardStepID);
    };

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
            readOnly: true
        },
        [`makeYourCar${index}`]: {
            value: vehicle.make,
        },
        [`modelYourCar${index}`]: {
            value: vehicle.model,
        },
        [`fuelYourCar${index}`]: {
            value: vehicle.fuelType,
        },
        [`registrationYearYourCar${index}`]: {
            value: vehicle.firstRegisteredYear,
        },
        [`valueYourCar${index}`]: {
            value: vehicle.marketValue,
        },
        [`datePurchasedYourCar${index}`]: {
            value: dataPurchasedFormatter(),
        },
        [`securityDevicesYourCar${index}`]: {
            value: securityDevices(vehicle),
        },
        [`securityTrackerYourCar${index}`]: {
            value: vehicle.securityTracker ? 'Yes' : 'No',
        },
        [`vehicleModifiedYourCar${index}`]: {
            value: vehicle.hasBeenModified ? 'Yes' : 'No',
        },
        [`dayParkingYourCar${index}`]: {
            value: dayParking(vehicle),
        },
        [`nightParkingYourCar${index}`]: {
            value: nightParking(vehicle),
        },
        [`estimatedMileageYourCar${index}`]: {
            value: vehicle.annualMileage,
        },
        [`registeredKeeperYourCar${index}`]: {
            value: vehicle.registeredKeeper ? 'Yes' : 'No',
        },
        [`rightHandDriveYourCar${index}`]: {
            value: vehicle.rightHandDrive ? 'Yes' : 'No',
        },
        [`importedYourCar${index}`]: {
            value: vehicle.imported ? 'Yes' : 'No',
        },
        [`howManySeatsYourCar${index}`]: {
            value: vehicle.lessThanEightSeats ? 'Yes' : 'No',
        },
        [`ncdYearsYourCar${index}`]: {
            value: vehicle.ncdYears,
        },
        [`amendDetailsYourCar${index}`]: {
            onClick: () => {
                if (navigateToDetails) {
                    navigateToDetails('vehicle-details');
                }
            }
        },

    };

    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMQuoteReviewCarComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMQuoteReviewCarComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMQuoteReviewCarComponent;
