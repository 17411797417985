/**
 * Invokes the code to assign branding to the app
 */
import brandingData from './BrandingData';

function reSettingBrand(brand) {
    return brand.replace(/[^a-zA-Z]/g, '');
}
// adds branding class to an HTML element
function setBranding() {
    const settedBranding = reSettingBrand(brandingData.BRANDING);
    document.documentElement.classList.add(`gw-branding-${settedBranding}`);
}
export {
    brandingData,
    setBranding
};
