/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _, { set } from 'lodash';
import stereotype from 'stereotype';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps, WizardContext } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
import { MockUpUtil, EntityUtil } from 'gw-portals-util-js';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ScrollToError } from '@jutro/wizard-next';
import config from 'app-config';
import moment from 'moment';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import htmlParser from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { ErrorBoundary } from 'gw-portals-error-react';
// eslint-disable-next-line import/no-unresolved
import styles from './DriversPage.module.scss';
import metadata from './DriversPage.metadata.json5';
import messages from './DriversPage.messages';
import ValidationUtil from '../../util/ValidationUtil';

function formatDate(value) {
    return (`0${value}`).slice(-2);
}
function getAgeAt(dateDTO, driver) {
    if (!driver.dateOfBirth) {
        return 0;
    }
    const date = moment([dateDTO.year, dateDTO.month, dateDTO.day]);
    const dob = moment([driver.dateOfBirth.year, driver.dateOfBirth.month, driver.dateOfBirth.day]);
    return date.diff(dob, 'years');
}
function driverHasFullLicence(licenseType) {
    return ['FullEU', 'FullUK', 'InternationalLicence', 'FullChannelIslands', 'FullIoM', 'Japanese', 'Other'].includes(licenseType);
}
function DriversPage(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();
    const {
        wizardData: submissionVM, updateWizardData, jumpTo, history
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [dateForUkResidentLessThan5Years, setDateForUkResidentLessThan5Years] = useState(undefined);

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('DriversPage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [driverIndex, setDriverIndex] = useState(0);
    const drvrs = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', []);
    const policyHolderIndex = drvrs.length > 0 ? drvrs.findIndex((drvr) => drvr.isPolicyHolder === true) : 0;
    const policyHolderVM = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}]`);
    const policyHolder = policyHolderVM.value;
    const [mainDriver, setMainDriver] = useState(_.find(submissionVM.lobData.personalMotor.coverables.pmDrivers.value, (driver) => driver.mainDriver));
    const [mainDriverIndex, setMainDriverIndex] = useState(_.findIndex(submissionVM.lobData.personalMotor.coverables.pmDrivers.value, (driver) => driver.mainDriver));
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const getDateOfBirth = (policyHolder) => {
        return _.get(policyHolder, 'dateOfBirth');
    };
    const displayName = useCallback((policyHolder) => {
        const availableValues = _.get(policyHolderVM, 'title_itb.aspects.availableValues');
        const title = availableValues.find((item) => item.code === policyHolder.title_itb);
        if (title) {
            return `${translator({
                id: title.name,
                defaultMessage: title.name
            })} ${policyHolder.firstName} ${policyHolder.lastName}`;
        }
        return `${policyHolder.firstName} ${policyHolder.lastName}`;
    }, [policyHolderVM, translator]);
    const policyHolderRelationShip = useCallback((policyHolder) => {
        const availableValues = _.get(policyHolderVM, 'phRelationship_itb.aspects.availableValues');
        const relationship = availableValues.find((item) => item.code === policyHolder.phRelationship_itb);
        if (relationship) {
            return `${translator({
                id: relationship.name,
                defaultMessage: relationship.name
            })}`;
        }
        return '';
    }, [policyHolderVM, translator]);
    const [showDriverDetails, updateShowDriverDetails] = useState(false);
    const [minAllowedToDriveDateDriver, setMinAllowedToDriveDateDriver] = useState(null);
    const [driversCanHave10YearsExp, setDriversCanHave10YearsExp] = useState(false);
    const [driverUkResidentMonthIndex, setDriverUkResidentMonthIndex] = useState(0);
    const isHomeNumberValid = useState(ValidationUtil.isHomeNumberValid(submissionVM));
    const isPhoneNumberValid = useState(ValidationUtil.isPhoneNumberValid(submissionVM));
    const isPhoneNumberLnPValid = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const ageRefDateDTO = submissionVM.baseData.periodStartDate.value;
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const [driver, setDriver] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isRefresh, setRefresh] = useState(false);
    const [driverCanHave10YearsExp, setDriverCanHave10YearsExp] = useState(false);
    const monthList = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].provisionalLicenseMonth.aspects.availableValues`);
    const getMonthYearFieldValue = (monthDtoPath, yearDtoPath) => {
        const dtoMonth = _.get(submissionVM, monthDtoPath);
        const dtoYear = _.get(submissionVM, yearDtoPath);
        if (dtoMonth === undefined || dtoYear === undefined) {
            return undefined;
            // eslint-disable-next-line no-else-return
        } else {
            let monthNum = 0;
            monthList.forEach((month, index) => {
                if (month.code === dtoMonth.code) {
                    monthNum = index;
                }
            });
            return { month: monthNum, year: dtoYear };
        }
    };

    const [driverProvisonalLicenseMonthYear, setDriverProvisonalLicenseMonthYear] = useState(getMonthYearFieldValue(
        `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].provisionalLicenseMonth.value`,
        `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].provisionalLicenseYear.value`
    ));

    const [driverPassedTestMonthYear, setDriverPassedTestMonthYear] = useState(getMonthYearFieldValue(
        `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].passedTestMonth.value`,
        `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].passedTestYear.value`
    ));

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            if (path === `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].licenseType`) {
                if (value && !hasFullLicence(newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].value)) {
                    if (!driverCanHave10YearsExp) {
                        newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].testPassed10Years.value = false;
                    } else {
                        newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].testPassed10Years.value = undefined;
                    }
                }
            }

            updateWizardData(newSubmissionVM);
        },
        [driverCanHave10YearsExp,driverIndex, submissionVM, updateWizardData, viewModelService]
    );
    const createDriverVM = useCallback(() => {
        if (_.isEmpty(submissionVM.lobData.personalMotor.coverables.pmDrivers.value)) {
            const accountHolder = submissionVM.baseData.accountHolder.value;
            let driverObj = accountHolder;
            driverObj.mainDriver = true;
            driverObj.isPolicyHolder = true;
            const { _dtoName, _xCenter } = submissionVM.lobData.personalMotor.coverables.pmDrivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
            submissionVM.lobData.personalMotor.coverables.pmDrivers.pushElement(driverVM);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        const driversPath = 'lobData.personalMotor.coverables.pmDrivers';

        const drivers = _.get(submissionVM, `${driversPath}.value`);
        if (_.isEmpty(drivers)) {
            createDriverVM();
        }
        setPageInitialized(true);
        let vm = viewModelService.clone(submissionVM);
        //vm = initialiseVM(vm);
        if (!_.isEqual(vm.value, submissionVM.value)) {
            updateWizardData(vm);
        }
        const driver = _.get(submissionVM.value, `${driversPath}[0]`);
        if (!_.isEmpty(driver) && driver.dateOfBirth) {
            // Driving test pass and drove on provisional licence minimum date
            const { ageAllowedToDrive } = config.personalAutoConfig;
            const newMinAllowedToDriveDateDriver = moment([
                driver.dateOfBirth.year + ageAllowedToDrive,
                driver.dateOfBirth.month,
                driver.dateOfBirth.day
            ]);
            // eslint-disable-next-line max-len
            let driversCanHave10YearsExp = getAgeAt(ageRefDateDTO, driver) >= ageAllowedToDrive + 10;
            const minDate = {
                day: newMinAllowedToDriveDateDriver.date(),
                month: newMinAllowedToDriveDateDriver.month(),
                year: newMinAllowedToDriveDateDriver.year()
            };
            setMinAllowedToDriveDateDriver(minDate);
            setDriversCanHave10YearsExp(
                driversCanHave10YearsExp
            );
            if (!driversCanHave10YearsExp) {
                driver.testPassed10Years = false;
            }
        }
        setDateForUkResidentLessThan5Years(
            _.get(submissionVM, `${driversPath}[${driverIndex}].ukResidencyMonth_itb.value`) && _.get(submissionVM, `${driversPath}[${driverIndex}].ukResidencyYear_itb.value`) ?
                {
                    month: monthList[_.get(submissionVM, `${driversPath}[${driverIndex}].ukResidencyMonth_itb.value`)]?.code,
                    year: _.get(submissionVM, `${driversPath}[${driverIndex}].ukResidencyYear_itb.value`)
                } : undefined
        );
        
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(2));

        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => {
        if (ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'DriversPage', {}, isPhoneNumberLnPValid) > 0) {
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }
        return LoadSaveService.updateDraftSubmission(submissionVM.value)
            .then((value) => {
                submissionVM.value = value;
                window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
                const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
                updateWizardData(newUpdatedSubmissionVM);
                return newUpdatedSubmissionVM;
            })
            .catch((error) => {
            // re-throw this error within the updater function
            // it will be triggered during state update
                history.push({
                    pathname: '/error',
                    data: error,
                origin: "DriversPage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            });
    }, [LoadSaveService, history, isHomeNumberValid, isPhoneNumberLnPValid, isPhoneNumberValid, submissionVM, updateWizardData, viewModelService]);

    const onChangeDriversClick = useCallback(() => {
        const initialWizardStepID = 0;
        jumpTo(initialWizardStepID);
    }, [jumpTo]);

    const addDriver = useCallback(() => {
        scrollTo(0,0);
        updateShowDriverDetails(true);
        const driverObj = {
            isPolicyHolder: false,
            tempID: EntityUtil.nextId()
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmDrivers;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
        driverVM.person = {};
        _.set(driverVM, 'mainDriver.value', false);
        submissionVM.lobData.personalMotor.coverables.pmDrivers.pushElement(driverVM);
        updateWizardData(submissionVM);
        setDriver(driverVM);
        setDriverIndex(submissionVM.lobData.personalMotor.coverables.pmDrivers.value.length - 1);
    }, [submissionVM, updateWizardData, viewModelService]);
    useEffect(() => {
        if (driverIndex !== policyHolderIndex) {
            setDriverProvisonalLicenseMonthYear(undefined);
            setDriverPassedTestMonthYear(undefined);
        }
        setIsFirstLoad(false);
    }, [driverIndex, policyHolderIndex]);
    const addDriverConviction = useCallback(() => {
        const convictionObj = {
            convictionCode: undefined,
            convictionDate: undefined,
            convictionPoints: undefined,
            disqualificationMonths: undefined,
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmMotorConvictions;
        const convictionVM = viewModelService.create(convictionObj, _xCenter, _dtoName);
        if (!submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmMotorConvictions.value) {
            submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmMotorConvictions.value = [];
        }
        submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmMotorConvictions.pushElement(convictionVM);
        updateWizardData(submissionVM);
    }, [driverIndex, submissionVM, updateWizardData, viewModelService]);

    const addDriverClaim = useCallback(() => {
        const claimObj = {
            claimDate: undefined,
            claimType: undefined,
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmClaims;
        const claimVM = viewModelService.create(claimObj, _xCenter, _dtoName);
        if (!submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmClaims.value) {
            submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmClaims.value = [];
        }
        submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].pmClaims.pushElement(claimVM);
        updateWizardData(submissionVM);
    }, [driverIndex, submissionVM, updateWizardData, viewModelService]);

    const changeMainDriver = useCallback((index) => {
        const oldMainDriver = _.findIndex(submissionVM.lobData.personalMotor.coverables.pmDrivers.value, (d) => d.mainDriver);
        _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${oldMainDriver}].mainDriver.value`, false);
        _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${index}].mainDriver.value`, true);
        setMainDriver(_.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${index}].value`));
        setMainDriverIndex(index);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const removeDriver = useCallback(
        (index) => {
            showConfirm({
                title: messages.removeDriverTitle,
                message: messages.removeDriverDescription,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const driversList = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value');
                if (index === mainDriverIndex) {
                    changeMainDriver(0);
                }
                driversList.splice(index, 1);
                _.set(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', driversList);
                setMainDriverIndex(_.findIndex(submissionVM.lobData.personalMotor.coverables.pmDrivers.value, (targetDriver) => targetDriver.mainDriver));
                disregardFieldValidation(`driverDetails${driverIndex}`);
                updateWizardData(submissionVM);
                return true;
            }, _.noop);
        },
        [changeMainDriver, disregardFieldValidation, driverIndex, mainDriverIndex, showConfirm, submissionVM, updateWizardData]
    );
    const onCancelDriverClick = useCallback(
        () => {
            scrollTo(0,0);
            const driversList = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value');
            const newDriversList = _.filter(driversList, (driver, index) => index !== driverIndex);
            _.set(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', newDriversList);
            disregardFieldValidation(`driverDetails${driverIndex}`);
            updateWizardData(submissionVM);
        },
        [disregardFieldValidation, driverIndex, submissionVM, updateWizardData]
    );
    const cancelDriverClick = () => {
        updateShowDriverDetails(false);
        onCancelDriverClick();
        setSubmitted(false);
    };
    const continueDriverClick = () => {
        scrollTo(0,0);
        if (_.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].mainDriver.value`) === undefined) {
            _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].mainDriver.value`, false);
        }
        if (ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'DriversPage') > 0) {
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }
        updateShowDriverDetails(false);
        updateWizardData(submissionVM);
        if (submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].mainDriver.value === true) {
            setMainDriver(_.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].value`));
        }
    };
    const minimumAllowedDateOfBirthDriver = useMemo(() => {
        const baseDate = new Date(submissionVM.baseData.periodStartDate.year.value, submissionVM.baseData.periodStartDate.month.value, submissionVM.baseData.periodStartDate.day.value);
        const { ageAllowedToDrive } = config.personalAutoConfig;
        baseDate.setFullYear(baseDate.getFullYear() - ageAllowedToDrive);
        return baseDate;
    }, [submissionVM]);

    const maximumAllowedDateOfBirthDriver = useMemo(() => {
        const baseDate = new Date(submissionVM.baseData.periodStartDate.year.value, submissionVM.baseData.periodStartDate.month.value, submissionVM.baseData.periodStartDate.day.value);
        const { maxAgeAllowedToDrive } = config.personalAutoConfig;
        baseDate.setFullYear(baseDate.getFullYear() - maxAgeAllowedToDrive);
        return baseDate;
    }, [submissionVM]);
    const handleDOBValueChange = useCallback(
        (value, path) => {
            const dob = moment({ year: value.year, month: value.month, day: value.day });
            if (!dob.isBetween(moment(maximumAllowedDateOfBirthDriver).startOf('day'), moment(minimumAllowedDateOfBirthDriver).endOf('day'))) {
                showAlert({
                    message: htmlParser('We can’t help on this occasion. Our products are designed for drivers aged 17 – 80, ' +
                        'and therefore we are unfortunately unable to offer you cover.<br/>Please visit the ' +
                        "<a target='blank' href='https://www.biba.org.uk/find-insurance/'><b>British Insurance Brokers' Association</b></a>, " +
                        'where they will have a list of specialist brokers that may be able to assist.'),
                    status: 'error',
                    confirmButtonText: 'OK',
                }).catch(_.noop);
            } else {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
                // Driving test pass and drove on provisional licence minimum date
                const { ageAllowedToDrive } = config.personalAutoConfig;
                const newMinAllowedToDriveDateDriver = moment([
                    value.year + ageAllowedToDrive,
                    value.month,
                    value.day
                ]);
                // eslint-disable-next-line max-len
                const minDate = {
                    day: newMinAllowedToDriveDateDriver.date(),
                    month: newMinAllowedToDriveDateDriver.month(),
                    year: newMinAllowedToDriveDateDriver.year()
                };
                setMinAllowedToDriveDateDriver(minDate);
            }
        },
        [maximumAllowedDateOfBirthDriver, minimumAllowedDateOfBirthDriver, showAlert, submissionVM, updateWizardData, viewModelService]
    );
    const onDriverNameValueChange = useCallback(
        (value, path) => {
            const pattern = /^[a-zA-Z-' ]{0,25}$/;
            if (pattern.test(value)) {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleDriverUkResidentLessThan5YearsValueChange = useCallback((value, path) => {
        const monthsList = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].ukResidencyMonth_itb.aspects.availableValues`);
        setDriverUkResidentMonthIndex(_.findIndex(monthsList, (mon) => mon.code === _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].ukResidencyMonth_itb`)));
        _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].ukResidencyMonth_itb.value`, monthsList[value.month]?.code);
        _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}].ukResidencyYear_itb.value`, value.year);
        updateWizardData(submissionVM);
        setDateForUkResidentLessThan5Years({
            month: value.month,
            year: value.year
        });
    }, [driverIndex, submissionVM, updateWizardData]);
    const handleDriverGenderValueChange = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value.code);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleDriverLicenseTypeChange = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        if (value && !driverHasFullLicence(submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].value)) {
            if (!driversCanHave10YearsExp) {
                submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].testPassed10Years.value = false;
            } else {
                submissionVM.lobData.personalMotor.coverables.pmDrivers.children[driverIndex].testPassed10Years.value = undefined;
            }
        }
        updateWizardData(submissionVM);
        setRefresh(!isRefresh);
    }, [submissionVM, driverIndex, updateWizardData, isRefresh, driversCanHave10YearsExp]);

    const isDateInTheAllowedMinMaxRange = (objCheckDate, objMinDate, objMaxDate) => {
        // eslint-disable-next-line max-len
        return objCheckDate.getTime() >= objMinDate.getTime() && objCheckDate.getTime() <= objMaxDate.getTime();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCalendarDateValues = (value) => {
        if (value !== undefined) {
            let blnIsDateInRange = false;
            if (minAllowedToDriveDateDriver !== undefined) {
                blnIsDateInRange = isDateInTheAllowedMinMaxRange(
                    new Date(value.year, value.month, minAllowedToDriveDateDriver.day),
                    // eslint-disable-next-line max-len
                    new Date(minAllowedToDriveDateDriver.year, minAllowedToDriveDateDriver.month, minAllowedToDriveDateDriver.day),
                    // eslint-disable-next-line max-len
                    new Date(LocalDateUtil.today().year, LocalDateUtil.today().month, LocalDateUtil.today().day)
                );
            }

            return blnIsDateInRange;
        }
    };

    const handleDriverFirstdroveValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const selectedMonth = monthList[value.month].code;
                const blnDateInRange = handleCalendarDateValues(value);
                if (blnDateInRange) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseMonth`, selectedMonth);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseYear`, value.year);
                    setDriverProvisonalLicenseMonthYear({
                        month: value.month,
                        year: value.year
                    });
                } else {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseYear`, undefined);
                    setDriverProvisonalLicenseMonthYear(undefined); 
                }
                updateWizardData(submissionVM);
            }
        },
        [monthList, handleCalendarDateValues, updateWizardData, submissionVM, driverIndex]
    );

    const handleDriverPassedTestValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const provisionalLicenseYear = _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseYear`);
                if (provisionalLicenseYear && provisionalLicenseYear > value.year) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].provisionalLicenseYear`, undefined);
                    setDriverProvisonalLicenseMonthYear(undefined);
                }
                const selectedMonth = monthList[value.month].code;
                const blnDateInRange = handleCalendarDateValues(value);
                if (blnDateInRange) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].passedTestMonth`, selectedMonth);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].passedTestYear`, value.year);
                    setDriverPassedTestMonthYear({
                        month: value.month,
                        year: value.year
                    });
                } else {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].passedTestMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${driverIndex}].passedTestYear`, undefined);
                    setDriverPassedTestMonthYear(undefined);
                }
                updateWizardData(submissionVM);
            }
        },
        [submissionVM, driverIndex, monthList, handleCalendarDateValues, updateWizardData]
    );

    const onChangeDriverDetailsClick = useCallback((drvr, drIndex) => {
        scrollTo(0,0);
        if (drvr.isPolicyHolder === true) {
            onChangeDriversClick();
            return;
        }
        updateShowDriverDetails(true);
        setDriverIndex(drIndex);
        setDriver(drvr);
        updateWizardData(submissionVM);
    }, [onChangeDriversClick, submissionVM, updateWizardData]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const driversList = _.get(newSubmissionVM, 'lobData.personalMotor.coverables.pmDrivers.value');
        driversList.forEach((drvr, index) => {
            overrideProps[`namedDriverDetails${index}`] = {
                visible: index !== mainDriverIndex
            };
            overrideProps[`namedDriverName${index}`] = {
                value: displayName(drvr),
                className: 'driver-text driversPageDriverName',
            };
            overrideProps[`namedDriverDateOfBirth${index}`] = {
                value: _.get(drvr, 'dateOfBirth') ? `(${drvr.phRelationship_itb ? policyHolderRelationShip(drvr) : 'Policyholder'}-${formatDate(getDateOfBirth(drvr).day)}-${formatDate(getDateOfBirth(drvr).month + 1)}-${getDateOfBirth(drvr).year})` : '',
            };
            overrideProps[`pmDeleteDriver${index}`] = {
                visible: drvr.isPolicyHolder === false,
                onClick: () => {
                    removeDriver(index);
                }
            };
            overrideProps[`changeDriverDetails${index}`] = {
                onClick: () => {
                    onChangeDriverDetailsClick(drvr, index);
                }
            };
            overrideProps[`setMainDriver${index}`] = {
                visible: drvr.mainDriver === false,
                onClick: () => {
                    changeMainDriver(index);
                }
            };
        });

        return overrideProps;
    }, [changeMainDriver, displayName, mainDriverIndex, onChangeDriverDetailsClick, policyHolderRelationShip, removeDriver, submissionVM, viewModelService]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        namedDriverTiltle: {
            visible: !showDriverDetails && _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', []).length > 1
        },
        setPolicyHolderMainDriver: {
            visible: _.get(policyHolderVM, 'mainDriver.value') === false,
            onClick: () => {
                changeMainDriver(0);
            }
        },
        driversContainer: {
            data: _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value'),
            visible: !showDriverDetails
        },
        driverInfoContainer: {
            visible: !showDriverDetails
        },
        pmAddAnotherDriverButtonContainer: {
            visible: !showDriverDetails && _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', []).length <= 4
        },
        policyHolderName: {
            value: displayName(policyHolder)
        },
        policyholderDateOfBirth: {
            value: _.get(policyHolder, 'dateOfBirth') ? `(Policyholder - ${formatDate(getDateOfBirth(policyHolderVM.value).day)}-${formatDate(getDateOfBirth(policyHolderVM.value).month + 1)}-${getDateOfBirth(policyHolderVM.value).year})` : '',
        },
        mainDriverName: {
            value: displayName(mainDriver)
        },
        changeMainDriverDetails: {
            onClick: () => {
                onChangeDriverDetailsClick(mainDriver, mainDriverIndex);
            }
        },
        mainDriverDateOfBirth: {
            value: _.get(mainDriver, 'dateOfBirth') ? `(${mainDriver.phRelationship_itb ? policyHolderRelationShip(mainDriver) : 'Policyholder'} - ${formatDate(getDateOfBirth(mainDriver).day)}-${formatDate(getDateOfBirth(mainDriver).month + 1)}-${getDateOfBirth(mainDriver).year})` : '',
        },
        pmDeleteMainDriver: {
            visible: mainDriver.isPolicyHolder === false,
            onClick: () => {
                removeDriver(mainDriverIndex);
            }
        },
        driverDetails: {
            path: 'lobData.personalMotor.coverables.pmDrivers',
            visible: showDriverDetails,
            onValueChange: writeValue,
            cancelDriverClick: cancelDriverClick,
            index: driverIndex,
            driver: _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${driverIndex}]`),
            minimumAllowedDateOfBirthDriver: minimumAllowedDateOfBirthDriver,
            maximumAllowedDateOfBirthDriver: maximumAllowedDateOfBirthDriver,
            onDriverNameValueChange: onDriverNameValueChange,
            driverUkResidentMonthIndex: driverUkResidentMonthIndex,
            handleDriverUkResidentLessThan5YearsValueChange: handleDriverUkResidentLessThan5YearsValueChange,
            dateForUkResidentLessThan5Years: dateForUkResidentLessThan5Years,
            handleDriverGenderValueChange: handleDriverGenderValueChange,
            handleDriverLicenseTypeChange: handleDriverLicenseTypeChange,
            handleDriverPassedTestValueChange: handleDriverPassedTestValueChange,
            handleDriverFirstdroveValueChange: handleDriverFirstdroveValueChange,
            driverProvisonalLicenseMonthYear: driverProvisonalLicenseMonthYear,
            driverPassedTestMonthYear: driverPassedTestMonthYear,
            driverHasFullLicence: driverHasFullLicence,
            submitted: submitted,
            minAllowedToDriveDateDriver: minAllowedToDriveDateDriver,
            driversCanHave10YearsExp: driversCanHave10YearsExp,
            addDriverClaim: addDriverClaim,
            addDriverConviction: addDriverConviction,
            updateWizardData: updateWizardData,
            disregardFieldValidation: disregardFieldValidation,
            continueDriverClick: continueDriverClick,
            submissionVM: submissionVM,
            updateWizardDataDriver: updateWizardData,
            disregardFieldValidationDriver: disregardFieldValidation,
            handleDOBValueChange: handleDOBValueChange
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "DriversPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeDriverDetailsClick: onChangeDriverDetailsClick,
            onChangeDriversClick: onChangeDriversClick,
            onAddDriverClick: addDriver,
            onRemoveDriverClick: removeDriver,
            onValidate: onValidate
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                skipWhen={initialValidation}
                disableNext={!isComponentValid}
                nextLabel="Continue"
                previousLabel="Back"
                showNext={!showDriverDetails}
                showPrevious={!showDriverDetails}
                disableCancel={true}
                cancelLabel={''}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={submitted}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

DriversPage.propTypes = wizardProps;
export default withRouter(DriversPage);
