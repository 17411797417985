import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';

import metadata from './PMMonthlyRepresentativeExampleComponent.metadata.json5';
import styles from './PMMonthlyRepresentativeExampleComponent.module.scss';

function PMMonthlyRepresentativeExampleComponent(props) {
    const {
        installmentPlanDetails,
        path,
        id,
        index,
        showOptional,
        labelPosition,
        onValidate,
        onValueChange,
        phoneWide
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        '@field': {
            phoneWide,
            showOptional: false,
            readOnly: true,
            labelPosition: 'left',
        },
        [`monthlyRepresentativeAnnualPremiumValue${index}`]: {
            content: `£${installmentPlanDetails.totalPremiumRPT.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeDepositPayableValue${index}`]: {
            content: `£${installmentPlanDetails.downPayment.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeAnnualAmountOfCreditValue${index}`]: {
            content: `£${installmentPlanDetails.creditAmount_itb.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeAnnualInterestRateValue${index}`]: {
            content: `${installmentPlanDetails.interestRate_itb}%`
        },
        [`monthlyRepresentativeAnnualTransactionFeeValue${index}`]: {
            content: `£${installmentPlanDetails.fees_itb.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeAnnualMonthlyPaymentsValue${index}`]: {
            content: `£${installmentPlanDetails.installment.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeAnnualTotalAmountPayableValue${index}`]: {
            content: `£${installmentPlanDetails.total.amount.toFixed(2)}`
        },
        [`monthlyRepresentativeAnnualRepresentativeAPRValue${index}`]: {
            content: `${installmentPlanDetails.aprPercentage_itb} APR variable`
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <ViewModelForm
            model={installmentPlanDetails}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMMonthlyRepresentativeExampleComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMMonthlyRepresentativeExampleComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: false,
    id: undefined,
    index: 0
};
export default PMMonthlyRepresentativeExampleComponent;
