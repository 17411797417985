import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { DDMandateTableDisplayComponent } from 'gw-capability-policyjob-react/index';
import moment from 'moment';
import messages from './PMLegalPage3InformationPage.messages';
import DDLogo from './DirectDebitLogo.jpg';

function DDViewMandateModalPopup(props) {
    const { data, isOpen, serviceNum, refNum, handleClose, onPrint } = props;

    return (
        <ModalNext className="andMandateModal" isOpen={isOpen} onRequestClose={handleClose}>
            <ModalBody>
                <div id="popupBody">
                    <div id="mandateFormContainer" className="andMandatePopUpContainer">
                        <div className="andMandatePopupColumn">
                            <div className="gw-mb10">
                                <p>{messages.pmLegalThreeOfFourPCLCompanyName.defaultMessage}</p>
                                <p>{messages.pmLegalThreeOfFourPCLCompanyAddress1.defaultMessage}</p>
                                <p>{messages.pmLegalThreeOfFourPCLCompanyAddress2.defaultMessage}</p>
                                <p>{messages.pmLegalThreeOfFourPCLCompanyCity.defaultMessage}</p>
                                <p>{messages.pmLegalThreeOfFourPCLCompanyCounty.defaultMessage}</p>
                                <p>{messages.pmLegalThreeOfFourPCLCompanyPostcode.defaultMessage}</p>
                            </div>

                            <div className="gw-mb10">
                                <div className="and-mb5">
                                    {messages.popupNameOfAccHolder.defaultMessage}
                                </div>
                                <table className="and-w-100">
                                    <tr><td className="and-bordered">{data.bindData.paymentDetails.bankAccountData.accountHolderName.value}</td></tr>
                                </table>
                            </div>

                            <div className="gw-mb10">
                                <div className="and-mb5">
                                    {messages.popupBankAccNumber.defaultMessage}
                                </div>
                                <DDMandateTableDisplayComponent
                                    stringValue={data.bindData.paymentDetails.bankAccountData.bankAccountNumber.value} showStyles={true} />
                            </div>

                            <div className="gw-mb10">
                                <div className="and-mb5">
                                    {messages.popupBranchSortCode.defaultMessage}
                                </div>
                                <DDMandateTableDisplayComponent
                                    stringValue={data.bindData.paymentDetails.bankAccountData.sortCode.value} showStyles={true} />
                            </div>

                            <div className="gw-mb10">
                                <p className="and-mb5">{messages.popupFullNameAddressOfBank.defaultMessage}</p>
                                <table className="and-w-100 and-table">
                                    <tr><td>{data.bindData.paymentDetails.bankAccountData.validationResponse_itb.value?.bank}</td></tr>
                                    <tr><td>{data.bindData.paymentDetails.bankAccountData.validationResponse_itb.value?.contactAddressLine1}</td></tr>
                                    <tr><td>{data.bindData.paymentDetails.bankAccountData.validationResponse_itb.value?.contactAddressLine2}</td></tr>
                                    <tr><td>{data.bindData.paymentDetails.bankAccountData.validationResponse_itb.value?.contactPostTown}</td></tr>
                                    <tr><td>{data.bindData.paymentDetails.bankAccountData.validationResponse_itb.value?.contactPostcode}</td></tr>
                                </table>
                            </div>
                        </div>

                        <div className="andMandatePopupColumn">
                            <div className="gw-mb10 and-d-flex">
                                <div className="gw-bold">
                                    {messages.popupInstructionToBank.defaultMessage}
                                </div>
                                <div>
                                    <img className="and-direct-debit-logo" src={DDLogo} alt="Direct Debit Logo" />
                                </div>
                            </div>

                            <div className="gw-mb10">
                                <div className="and-mb5">
                                    {messages.popupServiceNumber.defaultMessage}
                                </div>
                                <table>
                                    <DDMandateTableDisplayComponent
                                        id="ServiceNumTable"
                                        stringValue={serviceNum}
                                        showStyles={true} />
                                </table>
                            </div>

                            <div className="gw-mb10">
                                <div className="and-mb5">
                                    {messages.popupReference.defaultMessage}
                                </div>
                                <table>
                                    <DDMandateTableDisplayComponent
                                        id="ReferenceNumTable"
                                        stringValue={refNum}
                                        showStyles={true} />
                                </table>
                            </div>

                            <div className="gw-mb10">
                                <div>{messages.popupInstructionToBank_title.defaultMessage}</div>
                                <div>{messages.popupInstructionToBank_info.defaultMessage}</div>
                            </div>

                            <div className="gw-mb10 and-bordered and-pad-5">
                                <div className="and-mb5">{messages.popupDate.defaultMessage}</div>
                                <div className="and-mb5">{moment(Date.now()).format('DD/MM/YYYY')}</div>
                                <span>{messages.popupDay.defaultMessage}</span>
                                <span>{messages.popupMonth.defaultMessage}</span>
                                <span>{messages.popupYear.defaultMessage}</span>
                            </div>
                        </div>
                    </div>
                    <p>{messages.popupDDDisclaimer.defaultMessage}</p>
                </div>
            </ModalBody>
            <ModalFooter className="mandateModalFooter">
                <Button onClick={onPrint} type="outlined" icon="mi-print">
                    {messages.popupPrint.defaultMessage}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}
DDViewMandateModalPopup.propTypes = {
    data: PropTypes.shape({}),
    isOpen: PropTypes.bool,
    serviceNum: PropTypes.string,
    refNum: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    onPrint: PropTypes.func.isRequired
};

DDViewMandateModalPopup.defaultProps = {
    data: {},
    isOpen: false,
    serviceNum: "",
    refNum: "",
};

export default DDViewMandateModalPopup;
