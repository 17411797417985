import { defineMessages } from 'react-intl';

export default defineMessages({
    pmDriverTitle: {
        id: 'quoteandbind.pm.views.pm-driver-details.Drivers',
        defaultMessage: 'Drivers',
    },
    pmDriverTitleNumber: {
        id: 'quoteandbind.pm.views.pm-driver-details.Driver:',
        defaultMessage: 'Driver:',
    },
    pmDriver: {
        id: 'quoteandbind.views.quote.Personal-Motor-Driver',
        defaultMessage: 'Personal Motor Driver',
    },
    removeDriverTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Remove Driver',
        defaultMessage: 'Remove driver',
    },
    setMainDriverTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Set Main Driver',
        defaultMessage: 'Set as main driver',
    },
    changeDetailsTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Change These Details?',
        defaultMessage: 'Change these details?',
    },
    namesDriversTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Named Drivers',
        defaultMessage: 'Named Drivers',
    },
    mainDriversTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Main Driver',
        defaultMessage: 'Main Driver',
    },
    eligibleToDriveTitle: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Eligible To Drive',
        defaultMessage: 'Here is a list of the people who will be eligible to drive under this insurance.',
    },
    clickContinue: {
        id: 'quoteandbind.pm.controllers.PMDriverDetailsCtrl.Click Continue',
        defaultMessage: 'Please review the details below and click "Continue" to carry on.',
    },
    removeDriverDescription: {
        id: 'quoteandbind.pm.drivers.remove-driver.message',
        defaultMessage: 'Are you sure you want to remove this driver from the list of drivers?',
    },
    pmAddAnotherDriverTitle: {
        id: 'quoteandbind.pm.views.pm-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?',
    },
    pmAddDriverButtonText: {
        id: 'quoteandbind.pm.views.pm-driver-details.Add Another Driver',
        defaultMessage: 'Add another driver',
    }
});
