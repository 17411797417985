/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';

import metadata from './PMQuoteReviewAddOnsComponent.metadata.json5';
import './PMQuoteReviewAddOnsComponent.messages.js';
import styles from '../Stylesheets/PMQuoteReview.module.scss';

function PMQuoteReviewAddOnsComponent(props) {
    const {
        data: submissionVM,
        readOnlyFields,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        onBreakDownCoverClick,
        onTrailerCoverClick,
        onDrivingAbroadCoverClick,
        onLegalExpensesCoverClick,
        onEnhancedCourtesyCarCoverClick
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const getChoosenCover = (coverageCode, termCode) => {
        const coverage = _.get(submissionVM, 'lobData.personalMotor.offerings.value[0].coverages.pmVehicleCoverages[0].coverages', []);
        let choosenCover = coverage.find((item) => item.codeIdentifier === coverageCode && item.selected);
        if (choosenCover) {
            let choosenTerm = choosenCover.terms.find((item) => item.patternCode === termCode);
            switch (choosenTerm.chosenTerm) {
                case 'Level1':
                    return 'Road Rescue - National cover';
                case 'Level2':
                    return 'Road Rescue - National plus Doorstep cover';
                case 'Level3':
                    return 'Road Rescue - National plus Doorstep and European cover';
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
        },
        [`breakDownCover${index}`]: {
            readOnly: true,
            value: getChoosenCover('TMBreakdownCov', 'TMBreakdownLevel'),
            visible: getChoosenCover('TMBreakdownCov', 'TMBreakdownLevel') !== '' && getChoosenCover('TMBreakdownCov', 'TMBreakdownLevel') !== undefined
        },
        [`editBreakdownCover${index}`]: {
            onClick: () => {
                if (onBreakDownCoverClick) {
                    onBreakDownCoverClick();
                }
            }
        },
        [`editTrailerCover${index}`]: {
            onClick: () => {
                if (onTrailerCoverClick) {
                    onTrailerCoverClick();
                }
            }
        },
        [`editDrivingAbroadCover${index}`]: {
            onClick: () => {
                if (onDrivingAbroadCoverClick) {
                    onDrivingAbroadCoverClick();
                }
            }
        },
        [`editLegalExpensesCover${index}`]: {
            onClick: () => {
                if (onLegalExpensesCoverClick) {
                    onLegalExpensesCoverClick();
                }
            }
        },
        [`editEnhancedCourtesyCarCover${index}`]: {
            onClick: () => {
                if (onEnhancedCourtesyCarCoverClick) {
                    onEnhancedCourtesyCarCoverClick();
                }
            }
        },
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMQuoteReviewAddOnsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMQuoteReviewAddOnsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMQuoteReviewAddOnsComponent;
