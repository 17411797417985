/* eslint-disable quote-props */
import _ from 'lodash';

const statusToStepIndexMap = {
    "Draft": 0,
    "Quoted": 4,
    'default': 0
};

export default {
    getStepToJumpTo(status) {
        let jumpToIndex = 0;
        if(!status) {
            return jumpToIndex;
        }
        jumpToIndex = statusToStepIndexMap[status] || statusToStepIndexMap.default;
        return jumpToIndex;
    },
    validSubmissionObj(data) {
        if(!data || !data?.baseData || !data?.baseData?.periodStatus ) {
            return false;
        }
        else {
            return true;
        }
    }
};
