import { defineMessages } from 'react-intl';

export default defineMessages({
    emailQuote: {
        id: 'quoteandbind.directives.templates.QnbWizardButtons.Save Quote',
        defaultMessage: 'Save Quote',
    },
    printQuote: {
        id: 'quoteandbind.directives.templates.QnbWizardButtons.Print Quote',
        defaultMessage: 'Print Quote',
    },
    saveForLater: {
        id: 'quoteandbind.directives.QnbWizardButtons.Save for Later?',
        defaultMessage: 'Save for Later?',
    },
    emailSent: {
        id: 'quoteandbind.directives.QnbWizardButtons.Email Sent',
        defaultMessage: 'Quote Saved',
    },
    returnToQuoteButtonText: {
        id: 'quoteandbind.directives.QnbWizardButtons.Confirmation Text',
        defaultMessage: 'Return to Quote',
    },
    sessionEndedButtonText: {
        id: 'quoteandbind.directives.QnbWizardButtons.Exit Text',
        defaultMessage: 'Exit',
    },
    emailQuoteDescription: {
        id: 'quoteandbind.directives.QnbWizardButtons.We will save your quote and send its number',
        defaultMessage: 'Your quote reference is: {quoteID}.\n\n We have sent you an email explaining how to retrieve your quote.\n Quotes are valid for 30 days, unless you change any details, including the policy start date.',
    },
    error: {
        id: 'quoteandbind.directives.QnbWizardButtons.Error',
        defaultMessage: 'Error',
    },
    cantSendQuoteDetailsAtThisTime: {
        id: 'quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.',
        defaultMessage: 'Sorry, we were not able to email the quote details at this time.',
    },
});
