import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

const { env } = appConfig;

const urlIsAbsolute = (urlString) => {
    const ABSOLUTE_URL_PATTERN = /^(http|https)?:\/\//i;
    return ABSOLUTE_URL_PATTERN.test(urlString);
};

/**
 * This is a proxy component that allows to render images
 * in a deployed environment
 */
class ImageComponent extends Component {
    static contextType = TranslatorContext;

    /**
     * @memberof gw-components-platform-react.ImageComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} PropTypes.src - URL for the image
     * @prop {boolean} PropTypes.alt - the alt text for the image
     */

    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: intlMessageShape,
        title: PropTypes.string
    };

    static defaultProps = {
        alt: undefined,
        title: null
    };

    render() {
        const { translator = _.identity } = this.context;
        const {
            src, alt, title, ...otherProps
        } = this.props;

        const getResolvedPath = () => {
            if (urlIsAbsolute(src)) {
                return src;
            }
            const url = _.get(env, 'DEPLOYMENT_URL');
            const srcWithoutInitialSlash = src.replace(/^\//, '');
            return `${url}/${srcWithoutInitialSlash}`;
        };

        return (
            <img
                src={getResolvedPath()}
                alt={translator(alt)}
                title={translator(title)}
                {...otherProps}
            />
        );
    }
}

export default ImageComponent;
