import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage, QualificationPage } from 'gw-capability-quoteandbind-common-react';

import PMAboutYouPage from './pages/AboutYou/AboutYouPage';
import PMDriversPage from './pages/Drivers/DriversPage';
import PMVehiclesPage from './pages/Vehicles/VehiclesPage';
import PMDriverQualificationPage from './pages/DriverQualification/DriverQualificationPage';
import PMQuotePage from './pages/Quote/QuotePage';
import PMLegalPage1InformationPage from './pages/PMLegalPage1Information/PMLegalPage1InformationPage';
import PMLegalPage2InformationMonthlyPage from './pages/PMLegalPage2InformationMonthly/PMLegalPage2InformationMonthly';
import PMLegalPage2InformationPage from './pages/PMLegalPage2Information/PMLegalPage2InformationPage';
import PMLegalPage3InformationPage from './pages/PMLegalPage3Information/PMLegalPage3InformationPage';
import PMPaymentPage from './pages/PMPaymentPage/PMPaymentPage';
//import PMPolicyInformationPage from './pages/PolicyInformation/PolicyInformationPage';
import PMSuccessPage from './pages/Success/SuccessPage';
import PMEmailQuotePage from './pages/PMEmailQuotePage/PMEmailQuotePage';

setComponentMapOverrides(
    {
        PMAboutYouPage: { component: 'PMAboutYouPage' },
        PMDriverQualificationPage: { component: 'PMDriverQualificationPage' },
        PMDriversPage: { component: 'PMDriversPage' },
        PMVehiclesPage: { component: 'PMVehiclesPage' },
        PMQuotePage: { component: 'PMQuotePage' },
        //PMPolicyInformationPage: { component: 'PMPolicyInformationPage' },
        PMLegalPage1InformationPage: { component: 'PMLegalPage1InformationPage' },
        PMLegalPage2InformationMonthlyPage: { component: 'PMLegalPage2InformationMonthlyPage' },
        PMLegalPage2InformationPage: { component: 'PMLegalPage2InformationPage' },
        PMLegalPage3InformationPage: { component: 'PMLegalPage3InformationPage' },
        PMPaymentPage: { component: 'PMPaymentPage' },
        PMSuccessPage: { component: 'PMSuccessPage' },
        PMEmailQuotePage: { component: 'PMEmailQuotePage' }
    },
    {
        PMAboutYouPage,
        PMDriverQualificationPage,
        PMDriversPage,
        PMVehiclesPage,
        PMQuotePage,
        //PMPolicyInformationPage,
        PMLegalPage1InformationPage,
        PMLegalPage2InformationMonthlyPage,
        PMLegalPage2InformationPage,
        PMLegalPage3InformationPage,
        PMPaymentPage,
        PMSuccessPage,
        PMEmailQuotePage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PMWizard } from './PMWizard';
