import { defineMessages } from 'react-intl';

export default defineMessages({
    retrieveQuote: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Retrieve Quote',
        defaultMessage: 'Retrieve Quote',
    },
    retrieveQuoteDescription: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Retrieve A Quote Help Text',
        defaultMessage: 'Please enter your ZIP code and the quote ID you have received by email to retrieve your quote.',
    },
    enterZipCode: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Enter ZIP Code',
        defaultMessage: 'Enter ZIP Code',
    },
    enterQuoteID: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Enter Quote ID',
        defaultMessage: 'Enter Quote ID',
    },
    continue: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Continue',
        defaultMessage: 'Continue',
    },
    cancel: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Cancel',
        defaultMessage: 'Cancel',
    }
});
