import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './SuccessPage.module.scss';
import metadata from './SuccessPage.metadata.json5';
import './SuccessPage.messages';

const ACCORDION_CARDS = [
    'driverAccordion',
    'vehicleAccordion',
    'coverageAccordion',
    'contactAccordion'
];

function renderCellContent(data, index, props) {
    const { path } = props;
    return _.get(data, path);
}

function renderCoverageAmount(row, rowIndex) {
    return _.isEmpty(row.amount) ? null : (
        <CurrencyField
            id={`coverageAmount_${rowIndex}`}
            value={row.amount}
            dataType="object"
            className={styles.currencyStyle}
            showFractions
            readOnly
            hideLabel
        />
    );
}

function SuccessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isPrinting, setPrintingState] = useState(false);
    const { wizardData: submissionVM } = props;

    useEffect(() => {
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.accountNumber.value');
    }, [submissionVM]);

    const policyNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.policyNumber.value');
    }, [submissionVM]);

    const periodStartDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodStartDate.value');
    }, [submissionVM]);

    const periodEndDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodEndDate.value');
    }, [submissionVM]);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const getCoverageData = useCallback(() => {
        const lineCoverages = _.get(
            submissionVM,
            'lobData.personalAuto.offerings.value[0].coverages.lineCoverages'
        );
        const vehicles = _.get(
            submissionVM,
            'lobData.personalAuto.offerings.value[0].coverages.vehicleCoverages'
        );

        const structuredLineCoverages = lineCoverages.filter((coverage) => coverage.selected);
        const structuredVehicleCoverages = vehicles.flatMap((vehicle) => {
            return vehicle.coverages
                .filter((cov) => cov.selected)
                .map((coverage) => ({
                    name: `${vehicle.vehicleName} - ${coverage.name}`,
                    amount: coverage.amount
                }));
        });

        return [...structuredLineCoverages, ...structuredVehicleCoverages];
    }, [submissionVM]);

    const coverageOverrides = useMemo(() => {
        return {
            coverageTable: { data: getCoverageData() },
            coverageName: { renderCell: renderCellContent },
            coverageAmount: { renderCell: renderCoverageAmount }
        };
    }, [getCoverageData]);

    const vehicleOverrides = useMemo(() => {
        return {
            vehicleTable: {
                data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
            },
            vehicleMake: { renderCell: renderCellContent },
            vehicleModel: { renderCell: renderCellContent },
            vehicleLicensePlate: { renderCell: renderCellContent }
        };
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        accountNumberLink: {
            to: `/accounts/${accountNumber}/summary`
        },
        policyNumberLink: {
            to: `/policies/${policyNumber}/summary`
        },
        policyPeriod: {
            value: {
                startDate: periodStartDate,
                endDate: periodEndDate,
            }
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined
        },
        ...coverageOverrides,
        ...vehicleOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
