/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import htmlParser from 'html-react-parser';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { Button, CurrencyValue, Chevron } from '@jutro/components';
import _, { set } from 'lodash';
import styles from './PMQuotePage_Coverage.module.scss';
import { CoveragesList, BreakdownList } from './CoverageDetails';
import Tick from './Tick.svg';
import ThickTick from './ThickerTick.svg';
import messages from './PMQuotePage_Coverage.messages';
import CoverageUtil from 'gw-capability-quoteandbind-common-react/util/CoverageUtil';
import { brandingData } from 'gw-portals-branding-js';
import { Segmentation, SegmentationOption } from 'gw-portals-segmentation-react';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';

const INPUT_SELECTED_TRUE_VALUE = 'selected';
const INPUT_SELECTED_FALSE_VALUE = 'none';
const TM_BREAKDOWN_COVERAGE_CODE = 'TMBreakdownCov';
const PM_DRIVING_ABROAD_COVERAGE_CODE = 'PMDrivingAbroadCov';
const PM_DRIVING_ABROAD_90_DAY_COVERAGE_CODE = '90';
const brand = brandingData.BRANDING;
const thickTickBrands = ['kfi'];
let periodStartDate = null;

const params = {
    compareBy: brandingData.BRANDING
};

let coverageParagraphsWithDocLinks = null;
let breakdownCoverageParagraphsWithDocLinks = null;

const setCoverageObject = (covCode) => {
    const coverage = CoveragesList.find((cov) => {
        return cov.code === covCode;
    });
    if (coverage === undefined) {
        return {
            title: covCode,
            name: covCode,
            bulletPoints: [
            ],
            paragraphs: [
            ],
            imgURL: ''
        };
    }
    return coverage;
};

const setBulletPoints = (coverage) => {
    const coverageBenefitsList = [];
    coverage.bulletPoints.forEach((value) => {
        const parsedText = htmlParser(value);
        coverageBenefitsList.push(
            <div className={styles.covBenefitContainer}>
                <img src={thickTickBrands.includes(brand) ? ThickTick : Tick} className="tick-icon" alt="Tick"/>
                <p>{parsedText}</p>
            </div>
        );
    });
    return coverageBenefitsList;
};

const setParagraphs = (coverage) => {
    const coverageParagraphs = [];
    if (coverageParagraphsWithDocLinks[coverage.code]) {
        coverageParagraphsWithDocLinks[coverage.code].forEach((value) => {
            const parsedText = htmlParser(value);
            coverageParagraphs.push(<p>{parsedText}</p>);
        });
    } else if (breakdownCoverageParagraphsWithDocLinks[coverage.code]) {
        breakdownCoverageParagraphsWithDocLinks[coverage.code].forEach((value) => {
            const parsedText = htmlParser(value);
            coverageParagraphs.push(<p>{parsedText}</p>);
        });
    } else {
        coverage.paragraphs.forEach((value) => {
            const parsedText = htmlParser(value);
            coverageParagraphs.push(<p>{parsedText}</p>);
        });
    }
    return coverageParagraphs;
};

const retrieveCoverageAmount = (data) => {
    if (data.codeIdentifier === PM_DRIVING_ABROAD_COVERAGE_CODE) {
        const term = data.terms[0].options.find((DAterm) => {
            return DAterm.name === PM_DRIVING_ABROAD_90_DAY_COVERAGE_CODE;
        });
        return term.amount;
    } else if (data.amount === undefined) {
        return { amount: 0, currency: 'gbp' };
    } else {
        return data.amount;
    }
};


const setAddOnValue = (event, covCode, covData) => {
    let newValue;
    if (event.target.value === INPUT_SELECTED_FALSE_VALUE) {
        if (covCode === PM_DRIVING_ABROAD_COVERAGE_CODE) {
            newValue = covData.terms[0].options.find((opt) => opt.name === '3').code;
        } else if (covCode === TM_BREAKDOWN_COVERAGE_CODE) {
            newValue = null;
        } else {
            newValue = event.target.value === INPUT_SELECTED_TRUE_VALUE;
        }
    } else {
        if (covCode === PM_DRIVING_ABROAD_COVERAGE_CODE) {
            newValue = covData.terms[0].options.find((opt) => opt.name === PM_DRIVING_ABROAD_90_DAY_COVERAGE_CODE).code;
        } else if (covCode === TM_BREAKDOWN_COVERAGE_CODE) {
            newValue = event.target.value;
        } else {
            newValue = event.target.value === INPUT_SELECTED_TRUE_VALUE;
        }
    }
    return newValue;
};

const getCoverageSelected = (code) => {
    return CoverageUtil.getBrandSpecificCoverages(brand).find((cov) => {
        return cov.CoverageCode === code;
    });
};

const getCoverageSelectedOrChosenTerm = (data, CoverageSelectedValue) => {
    if (data === null) { return null; }
    let covSelectedVal = '';
    const BrandSpecificCoverages = getCoverageSelected(data.codeIdentifier);
    if(BrandSpecificCoverages == null) { return covSelectedVal};
    if(data.hasTerms && data.terms[0] && data.terms[0].options.length > 0 && BrandSpecificCoverages?.Options !== null) {
        if (data.terms[0].chosenTerm && PM_DRIVING_ABROAD_COVERAGE_CODE !== data.codeIdentifier) {
            covSelectedVal = data.terms[0].chosenTerm;
        } else if (CoverageSelectedValue) {
            covSelectedVal = INPUT_SELECTED_FALSE_VALUE;
        }
        if (data.codeIdentifier === PM_DRIVING_ABROAD_COVERAGE_CODE && data.terms[0].chosenTerm) {
            const abroadSelectedChoosenValue = data.terms[0].chosenTerm;
            const option = data.terms[0].options.find((opt) => opt.code === abroadSelectedChoosenValue);
            if (option) {
                const abroadSelectedValue = option.code;
                if (abroadSelectedChoosenValue === abroadSelectedValue) {
                    covSelectedVal = option.name === '90' ? INPUT_SELECTED_TRUE_VALUE : CoverageSelectedValue ? INPUT_SELECTED_FALSE_VALUE : '';
                }
            }
        }
    } else if (data.selected === true) {
        covSelectedVal = INPUT_SELECTED_TRUE_VALUE;
    } else if (CoverageSelectedValue && data.selected === false) {
        covSelectedVal = INPUT_SELECTED_FALSE_VALUE;
    }
    return covSelectedVal;
};

const setCovParagraphArray = () => {
    return {
        PMLegalExpensesCov: [
            'Eligible claims must have at least a 51% chance of success. Costs that you incur without our consent or which exceed the sum we would pay a law firm from our panel, parking infringements or non-payment of parking fines, and fines, penalties or compensation ordered against you are not covered.',
            `View the <a href="${DocUtil.getFileUrl(brand, 'LEGAL_EXP_IPID', periodStartDate)}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions. You can access the <a href="${DocUtil.getFileUrl(brand,'LEGAL_EXP_POLICY', periodStartDate)}" target="_blank">Policy Document</a> for full terms and conditions.`
        ],
        TMEnhancedCourtesyCarCov: [
            'To be eligible for a courtesy car you must have held a full driving licence for at least 6 months, and not work as an entertainer or professional sports person. If you are not eligible for a courtesy car, you’ll receive up to £250 towards alternative transport costs.',
            'A £350 excess applies for most claims. A £75 + VAT excess applies for glass claims.',
            `View the <a href="${DocUtil.getFileUrl(brand, 'ECCC_IPID', periodStartDate)}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions. You can access the <a href="${DocUtil.getFileUrl(brand,'ECCC_POLICY', periodStartDate)}" target="_blank">Policy Document</a> for full terms and conditions.`
        ],
        PMTrailerCov: [
            'This cover will pay the cost of repairs to your trailer or make a cash payment in the event of accidental damage or loss, or if your trailer is stolen but not recovered.',
            `You can find out more about this cover and full details of any exclusions in sections 1 and 2 of the <a href="${DocUtil.getFileUrl(brand, 'PRIVATE_CAR_POLICY_DOCUMENT', periodStartDate)}" target="_blank">Private Car Policy Document</a>.`,
            'Policy Excesses apply for any claims under this cover.'
        ],
        PMDrivingAbroadCov: [
            'The full benefits of your policy apply whilst driving in the EU/EEA, including when your car is being transported.',
            'If you wish to drive abroad, you must ensure that you have any documentation required to evidence insurance.',
            'Temporary replacement cars are not available abroad and repairs undertaken abroad are not guaranteed as they are not carried out by our approved network of repairers.',
            `Please see Section 6 of the <a href="${DocUtil.getFileUrl(brand, 'PRIVATE_CAR_POLICY_DOCUMENT', periodStartDate)}" target="_blank">Private Car Policy Document</a> for full details of the cover terms and exclusions that apply.`
        ]
    };
};

const setBreakdownCovParagraphArray = (breakdownLevelDocCode) => {
    const code = (breakdownLevelDocCode === 'Level3') ? 'BREAKDOWN_IPID_EURO' : 'BREAKDOWN_IPID_BASIC';
    return {
        TMBreakdownCov: [
            `Read the <a href="${DocUtil.getFileUrl(brand, code, periodStartDate)}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions.`
        ]
    };
};

function PMQuotePage_CoverageSection(props) {
    const { data, showSelectedAddon, submissionVM } = props;
    periodStartDate = _.get(submissionVM, 'baseData.periodStartDate.value', null);
    const [selectedCoverageOption, setSelectedCoverageOption] = useState(data.selected ? data.selected : undefined);
    const translator = useTranslator();
    const [showAllBreakdownCovOptions, setShowAllBreakdownCovOptions] = useState(false);
    const [coverageSelection, setCoverageSelection] = useState();
    const covAmount = retrieveCoverageAmount(data);
    var currentCoverageObj = setCoverageObject(data.codeIdentifier);
    coverageParagraphsWithDocLinks = setCovParagraphArray();
    const CoverageSelected = getCoverageSelected(currentCoverageObj.code);
    const [coverageSelectedValue, setCoverageSelectedValue] = useState(false);
    const hasCurrCovBeenSelected = getCoverageSelectedOrChosenTerm(data, coverageSelectedValue);
    const [redBoundarytoCoverage, setRedBoundarytoCoverage] = useState(true);
    const isDrivingAbroadCovSelected = data.terms[0]?.chosenTermValue === PM_DRIVING_ABROAD_90_DAY_COVERAGE_CODE;
    const isDrivingAbroad = data.codeIdentifier === PM_DRIVING_ABROAD_COVERAGE_CODE;

    const iterateBreakdownCovs = (showAllBreakdownCovOptions, props, translator, selectedCoverageOption, handleChange) => {
        const breakdownCovOption = [];
        BreakdownList.forEach((cov, index) => {
            breakdownCoverageParagraphsWithDocLinks = setBreakdownCovParagraphArray(cov.term);
            const selectedterm = props.data.terms[0];
            const covTerm = selectedterm.options.find((term) => {
                return term.code === cov.term;
            });
            breakdownCovOption.push(
                <div key={index}
                    className={(props.submitted && redBoundarytoCoverage && (selectedCoverageOption == '' || null)) ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}
                    style={{ display: (showAllBreakdownCovOptions || index === 0) ? 'flex' : 'none' }}
                >
                    <div>
                        <input
                            type="radio"
                            checked={selectedterm?.chosenTerm === cov.term}
                            onChange={(e) => {
                                setRedBoundarytoCoverage(!e.target.checked);
                                handleChange(e, cov.code, props.data);
                                setCoverageSelectedValue(true);
                                if (e.target.checked) {
                                    props.onCoveragesRadioButtonSelected();
                                }
                                if (selectedCoverageOption === '') {
                                    props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                }
                            }}
                            value={cov.term}
                        />
                    </div>
                    <div className={styles.andLabel}>
                        <p className={styles.andCoverageNameLabel}>{htmlParser(cov.name)}</p>
                        <div className={styles.andLabel}>
                            {setBulletPoints(cov)}
                        </div>
                        <div className={styles.andLabel}>
                            {setParagraphs(cov)}
                        </div>
                        <div className={styles.coverageAmount}>
                            <CurrencyValue
                                id="cov-value"
                                showFractions
                                value={covTerm.amount === undefined ? 0 : covTerm.amount}
                            />
                            <span>{messages.perYear.defaultMessage}</span>
                        </div>
                        <p className={styles.coverageAmountText}>{translator(messages.amountBreakdownText.defaultMessage, { monthlyAmount: (covTerm.amount.amount === undefined ? 0 : (Math.floor((covTerm.amount.amount / 12) * 100) / 100).toFixed(2)) })}</p>
                    </div>
                </div>
            );
        });
        return breakdownCovOption;
    };

    const coverageVM = {
        coverage: currentCoverageObj.code,
        value: selectedCoverageOption,
        aspects: {
            required: true,
            availableValues: [true, false],
            get valid() {
                return !_.isUndefined(coverageVM.value) && coverageVM.value !== null;
            },
            get validationMessages() {
                if (coverageVM.aspects.valid === false) {
                    return ['Please fill out this field'];
                }
                return [];
            }
        }
    };

    useEffect(() => {
        props.allSteps.forEach((step) => {
            if (step.path === 'quote' && props.chosenQuote !== undefined) {
                setCoverageSelectedValue(true);
            }
        });
        const coverage = props.data;
        if (coverage.selected === true) {
            setSelectedCoverageOption(true);
        } else {
            if (coverageSelection && coverageSelection[coverageCode] && coverageSelection[coverageCode].selected === false) {
                setSelectedCoverageOption(false);
            }
        }
        if (showSelectedAddon && (data.codeIdentifier === TM_BREAKDOWN_COVERAGE_CODE && data.selected)) {
            setShowAllBreakdownCovOptions(true);
        }
        if(hasCurrCovBeenSelected != '') {
            props.onUnSelectedCountChange(prevCount => prevCount - 1);
        }
    }, []);

    const toggleAllBreakdownCovOptions = () => {
        setShowAllBreakdownCovOptions(!showAllBreakdownCovOptions);
    };

    function handleChange(event, covCode, covData) {
        CoverageUtil.getBrandSpecificCoverages(brand).forEach((cov) => {
            if (cov.CoverageCode === covCode) {
                cov.SelectedTerm = event.target.value;
            }
        });
        setSelectedCoverageOption(event.target.value);
        const covPatternCode = covData?.terms[0]?.patternCode;
        if (event.target.value !== null) {
            const newValue = setAddOnValue(event, covCode, covData);
            if (selectedCoverageOption !== newValue) {
                if (covCode === PM_DRIVING_ABROAD_COVERAGE_CODE || covCode === TM_BREAKDOWN_COVERAGE_CODE) {
                    if (newValue === null && !selectedCoverageOption) {
                        return;
                    }
                    props.updateCoverageTermSelection(newValue, covCode, covPatternCode);
                }
                else {
                    props.updateCoverageSelection(newValue, covCode)
                        .then((qdd) => {
                            setSelectedCoverageOption(newValue);
                        });
                }
            }
        }
    }

    return (
        <div className={styles.andCoverage}>
            {currentCoverageObj.code === TM_BREAKDOWN_COVERAGE_CODE ?
                <div>
                    <div id={`coverageNameHeader${currentCoverageObj.code}`} className={styles.andCoverageIconContainer}>
                        <div className={styles.andCoverageIcon}>
                            {currentCoverageObj.imgURL}
                        </div>
                        <h4 className={classNames(styles.andIconCoverageTitle, styles.andCoverageTitle)}>{currentCoverageObj.title}</h4>
                    </div>
                    <p className={styles.andCovBreakdwnPretext}>{messages.chooseBreakdownCoverOption.defaultMessage}</p>
                    <div id="coverageOptionsContainer">
                        {iterateBreakdownCovs(showAllBreakdownCovOptions, props, translator, hasCurrCovBeenSelected, handleChange)}
                        <div className={styles.showOptionsContainer}>
                            <Chevron
                                isOpen={true}
                                style={{ display: (!showAllBreakdownCovOptions) ? 'block' : 'none' }}
                                onClick={toggleAllBreakdownCovOptions}
                            />
                            <Button
                                className={`${styles.showBreakdownOptionsButton} ${styles.seeAllBreakdownOptions}`}
                                onClick={toggleAllBreakdownCovOptions}
                                style={{ display: (!showAllBreakdownCovOptions) ? 'block' : 'none' }}
                            >{messages.showAllBreakdownOptions.defaultMessage}</Button>
                        </div>
                        <div className={(props.submitted && redBoundarytoCoverage && (hasCurrCovBeenSelected == '' || null)) ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}>
                            <div>
                                <input
                                    type="radio"
                                    checked={(hasCurrCovBeenSelected === INPUT_SELECTED_FALSE_VALUE) || (showSelectedAddon && !selectedCoverageOption)}
                                    onChange={(e) => {
                                        setRedBoundarytoCoverage(!e.target.checked);
                                        handleChange(e, currentCoverageObj.code, props.data);
                                        setCoverageSelectedValue(true);
                                        if (e.target.checked) {
                                            props.onCoveragesRadioButtonSelected();
                                        }
                                        if (hasCurrCovBeenSelected === '') {
                                            props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                        }
                                    }
                                    }
                                    value={INPUT_SELECTED_FALSE_VALUE}
                                    required
                                />
                            </div>
                            <div className={styles.andLabel}>
                                <p>
                                    {translator(messages.noOption.defaultMessage, { coverageName: currentCoverageObj.title })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>{htmlParser(`You can access the <a href="${DocUtil.getFileUrl(brand,'BREAKDOWN_POLICY', periodStartDate)}" target="_blank">Policy Document</a> for full terms and conditions.`)}</p>
                        <p>{messages.breakdownAdditionalCoverageNote.defaultMessage}</p>
                    </div>
                </div>
                :
                <div>
                    <div id="coverageNameHeader" className={styles.andCoverageIconContainer}>
                        <div className={styles.andCoverageIcon}>
                            {currentCoverageObj.imgURL}
                        </div>
                        <h4 className={classNames(styles.andIconCoverageTitle, styles.andCoverageTitle)}>{currentCoverageObj.title}</h4>
                    </div>
                    <p className={styles.andCovPretext}>{currentCoverageObj.pretext}</p>
                    <form>
                        <div id="coverageOptionsContainer">
                            <div className={(props.submitted && redBoundarytoCoverage && (hasCurrCovBeenSelected == '' || null)) ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}>
                                <div>
                                    {isDrivingAbroad ?
                                        <input
                                            type="radio"
                                            checked={(hasCurrCovBeenSelected === INPUT_SELECTED_TRUE_VALUE) || (showSelectedAddon && isDrivingAbroadCovSelected)}
                                            onChange={(e) => {
                                                setRedBoundarytoCoverage(!e.target.checked);
                                                handleChange(e, currentCoverageObj.code, props.data);
                                                setCoverageSelectedValue(true);
                                                if (e.target.checked) {
                                                    props.onCoveragesRadioButtonSelected();
                                                }
                                                if (hasCurrCovBeenSelected === '') {
                                                    props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                                }
                                            }
                                            }
                                            value={INPUT_SELECTED_TRUE_VALUE}
                                            required
                                        />
                                        :
                                        <input
                                            type="radio"
                                            checked={(hasCurrCovBeenSelected === INPUT_SELECTED_TRUE_VALUE) || (showSelectedAddon && selectedCoverageOption)}
                                            onChange={(e) => {
                                                setRedBoundarytoCoverage(!e.target.checked);
                                                handleChange(e, currentCoverageObj.code, props.data);
                                                setCoverageSelectedValue(true);
                                                if (e.target.checked) {
                                                    props.onCoveragesRadioButtonSelected();
                                                }
                                                if (hasCurrCovBeenSelected === '') {
                                                    props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                                }
                                            }
                                            }
                                            value={INPUT_SELECTED_TRUE_VALUE}
                                            required
                                        />
                                    }
                                </div>
                                <div className={styles.andLabel}>
                                    { currentCoverageObj.code === 'TMEnhancedCourtesyCarCov' ?
                                        <Segmentation type="brand" params={params}>
                                            <SegmentationOption is='tya'>
                                                <p className={styles.andCoverageNameLabel}>{htmlParser(currentCoverageObj.name)}</p>
                                            </SegmentationOption>
                                            <SegmentationOption is='kfi' defaultOption>
                                                <p className={styles.andCoverageNameLabelEcccKfi}>{htmlParser(currentCoverageObj.nameKFI)}</p>
                                            </SegmentationOption>
                                        </Segmentation>
                                        :
                                        <p className={styles.andCoverageNameLabel}>{htmlParser(currentCoverageObj.name)}</p>
                                    }
                                    <div className={styles.andLabel}>
                                        {setBulletPoints(currentCoverageObj)}
                                    </div>
                                    <div className={styles.andLabel}>
                                        {setParagraphs(currentCoverageObj)}
                                    </div>
                                    <div className={styles.coverageAmount}>
                                        <CurrencyValue
                                            id="cov-value"
                                            showFractions
                                            value={covAmount}
                                        />
                                        <span>{messages.perYear.defaultMessage}</span>
                                    </div>
                                    <p className={styles.coverageAmountText}>{translator(messages.amountBreakdownText.defaultMessage, { monthlyAmount: (Math.floor((covAmount.amount / 12) * 100) / 100).toFixed(2) })}</p>
                                </div>
                            </div>
                            <div className={(props.submitted && redBoundarytoCoverage && (hasCurrCovBeenSelected == '' || null)) ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}>
                                <div>
                                    {isDrivingAbroad ?
                                        <input
                                            type="radio"
                                            checked={(hasCurrCovBeenSelected === INPUT_SELECTED_FALSE_VALUE) || (showSelectedAddon && !isDrivingAbroadCovSelected)}
                                            onChange={(e) => {
                                                setRedBoundarytoCoverage(!e.target.checked);
                                                handleChange(e, currentCoverageObj.code, props.data);
                                                setCoverageSelectedValue(true);
                                                if (e.target.checked) {
                                                    props.onCoveragesRadioButtonSelected();
                                                }
                                                if (hasCurrCovBeenSelected === '') {
                                                    props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                                }
                                            }
                                            }
                                            value={INPUT_SELECTED_FALSE_VALUE}
                                            required
                                        />
                                        :
                                        <input
                                            type="radio"
                                            checked={(hasCurrCovBeenSelected === INPUT_SELECTED_FALSE_VALUE) || (showSelectedAddon && !selectedCoverageOption)}
                                            onChange={(e) => {
                                                setRedBoundarytoCoverage(!e.target.checked);
                                                handleChange(e, currentCoverageObj.code, props.data);
                                                setCoverageSelectedValue(true);
                                                if (e.target.checked) {
                                                    props.onCoveragesRadioButtonSelected();
                                                }
                                                if (hasCurrCovBeenSelected === '') {
                                                    props.onUnSelectedCountChange(prevCount => prevCount - 1);
                                                }
                                            }
                                            }
                                            value={INPUT_SELECTED_FALSE_VALUE}
                                            required
                                        />
                                    }
                                </div>
                                <div className={styles.andLabel}>
                                    <p>
                                        {translator(messages.noOption.defaultMessage, { coverageName: currentCoverageObj.title })}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <input type="submit" value="Send" /> */}
                    </form>
                </div>
            }
        </div>
    );
}

PMQuotePage_CoverageSection.propTypes = {
    data: PropTypes.shape({}),
    path: PropTypes.string,
    index: PropTypes.number,
    updateCoverageSelection: PropTypes.func,
    updateCoverageTermSelection: PropTypes.func,
    showSelectedAddon: PropTypes.bool
};

PMQuotePage_CoverageSection.defaultProps = {
    data: {},
    index: 0,
    path: undefined,
    updateCoverageSelection: undefined,
    updateCoverageTermSelection: undefined,
    showSelectedAddon: undefined
};

export default PMQuotePage_CoverageSection;
