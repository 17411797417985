import _ from 'lodash';

// eslint-disable-next-line prefer-regex-literals
const REG_EXP = new RegExp(/\((\d+)\)\./);

const separateFilenameFromExtension = (filename) => {
    const indexOfExtension = filename.lastIndexOf('.');
    const filenameWithoutExtension = filename.substring(0, indexOfExtension);
    const extension = filename.substring(indexOfExtension + 1);

    return {
        filename: filenameWithoutExtension,
        extension
    };
};

const getLatestDocument = (filename, existingDocuments) => {
    const matchedDocuments = existingDocuments.filter(({ name: documentName }) => {
        const removedDuplicateNumber = documentName.replace(REG_EXP, '.');
        return removedDuplicateNumber === filename;
    });
    const [latestMatch] = _.orderBy(matchedDocuments, 'publicID', 'desc') || [];
    return latestMatch;
};

const checkFileName = (filename, existingDocuments) => {
    const latestDocumentMatch = getLatestDocument(filename, existingDocuments);
    if (latestDocumentMatch) {
        const { name: documentName } = latestDocumentMatch;
        // checks if the filename has a number `filename(12).png` and extracts 12
        const [, filenameDuplicateNumberString] = documentName.match(REG_EXP) || [];

        if (filenameDuplicateNumberString) {
            const newNumber = _.toInteger(filenameDuplicateNumberString) + 1;
            return documentName.replace(REG_EXP, `(${newNumber}).`);
        }
        // if a number isn't found, hard code a number
        const { filename: name, extension } = separateFilenameFromExtension(filename);
        return `${name}(1).${extension}`;
    }
    return filename;
};

export default {
    checkFileName,
    separateFilenameFromExtension
};
