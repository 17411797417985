import { defineMessages } from 'react-intl';

export default defineMessages({
    DeclinedUW: {
        id: 'quoteandbind.views.declined-uw.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    DeclinedUWWarning: {
        id: 'quoteandbind.views.declined-uw.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    DeclinedUWSolution: {
        id: 'quoteandbind.views.declined-uw.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.declined-uw.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.declined-uw.Reference Number',
        defaultMessage: 'Reference Number',
    },
    getAssistanceTextTYA: {
        id: 'quoteandbind.views.declined-uw.get assistance text tya',
        defaultMessage: 'If you need help, call us on 0330 022 9452.',
    },
    getAssistanceTextKFI: {
        id: 'quoteandbind.views.declined-uw.get assistance text kfi',
        defaultMessage: 'Unfortunately based on the information you have provided we are unable to provide a quote. Please contact our customer service team on Live Chat or by calling us on 0330 022 6969 to discuss your quote.',
    },
});
