import React from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import styles from './CustomLandingPage.module.scss';
import metadata from './CustomLandingPage.metadata.json5';
import './CustomLandingPage.messages';

export default function CustomLandingPage() {
    const resolvers = { resolveClassNameMap: styles };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={{}} {...resolvers} />;
}
