/* eslint-disable class-methods-use-this */
import { service } from '@jutro/services';
import { BrandSegmentation as BrandSegmentationJS } from 'gw-portals-segmentation-js';

export const BRAND_SEGMENT_SERVICE_ID = 'BrandSegmentation';

export default class BrandSegmentationImpl {
    retrieveExperimentVariant({ compareBy }) {
        return BrandSegmentationJS.getExperimentValue(compareBy);
    }
}

export const BrandSegmentation = (
    service(BRAND_SEGMENT_SERVICE_ID)(BrandSegmentationImpl)
);
