import { withRouter } from 'react-router-dom';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { publish, JUTRO_TOPICS } from '@jutro/events';

export class RoutingTracking extends Component {
    componentDidMount() {
        const { location: { pathname, search, state } } = this.props;
        this.handleChange(pathname, search, state);
    }

    componentDidUpdate({ location: prevLocation }) {
        const { location: { pathname, search, state } } = this.props;
        const isDifferentPathname = pathname !== prevLocation.pathname;
        const isDifferentSearch = search !== prevLocation.search;
        const isDifferentState = state !== prevLocation.state;

        if (isDifferentPathname || isDifferentSearch || isDifferentState) {
            this.handleChange(pathname, search, state);
        }
    }

    handleChange = (pathname, search, state) => {
        const page = pathname + search;
        const { location } = window;
        publish(JUTRO_TOPICS.ROUTE_CHANGED, {
            page: page,
            state: state,
            location: `${location.origin}${page}`
        });
    };

    render() {
        return null;
    }
}

RoutingTracking.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        state: PropTypes.shape({}),
    }).isRequired,
};

export default withRouter(RoutingTracking);
