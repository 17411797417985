import React, { Component } from 'react';
import ViewModelServiceContext from './ViewModelServiceContext';

/**
 * Renders a component
 * @function withViewModelServiceHOC
 * @memberof module:gw-portals-viewmodel-react
 * @param {Object} WrappedComponent Component that should be rendered
 * @returns {Object} Rendered the wrapped component
 */
export default function withViewModelServiceHOC(WrappedComponent) {
    return class extends Component {
        render() {
            return (
                <ViewModelServiceContext.Consumer>
                    {(ViewModelService) => ViewModelService && (
                    /* we render the component only if the viewModelService is available */
                        <WrappedComponent viewModelService={ViewModelService} {...this.props} />
                    )
                    }
                </ViewModelServiceContext.Consumer>
            );
        }
    };
}
