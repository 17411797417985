import React from 'react';
import PropTypes from 'prop-types';
import styles from './DDMandateTableDisplayComponent.module.scss';

const renderTableRow = (stringValue, showBorder) => {
    const valueArray = stringValue.split("");
    const correctStyle = (showBorder) ? styles.mandateValueTableCell : null;
    return valueArray.map((key, index) => {
        return <th key={index} className={correctStyle}>{key.toUpperCase()}</th>;
    });
};

function DDMandateTableDisplayComponent(props) {
    const { stringValue, showStyles } = props;
    return (
        <div id="tableContainer" className={styles.tableContainer}>
            <table>
                <tr>
                    { renderTableRow(stringValue, showStyles) }
                </tr>
            </table>
        </div>
    );
}

DDMandateTableDisplayComponent.propTypes = {
    stringValue: PropTypes.string,
    showStyles: PropTypes.bool
};

DDMandateTableDisplayComponent.defaultProps = {
    stringValue: null,
    showStyles: false
};

export default DDMandateTableDisplayComponent;
