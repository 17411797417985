/* eslint-disable max-len */
import _ from 'lodash';

const accountNumValidRegex = /^\d{7,8}$/;

export default {
    invalidPropertiesCount: (submissionVM, isHomeNumberValid, isPhoneNumberValid, strOriginPage = '', objOriginPageValues = {}, isPhoneNumberLnPValid, vehLookupSuccessful = null) => {
        let invalidPropsLength = 0;
        let includedResidencyMonthOrYearInList = false;
        let includedDateOfBirtInList = false;
        let includedPostcodeInList = false;
        let includedAddressLine1InList = false;
        let includedPostcodeOrAddressLine1InList = false;
        const stepsValidatingBindData = ['PMLegalPage1InformationPage', 'PMLegalPage2InformationPage', 'PMLegalPage3InformationPage', 'PMLegalPage2InformationMonthly'];
        let blnCountMonthsAndYearsAsOne = false;
        let blnCountPassedMonthsAndYearsAsOne = false;
        let includedResidencyMonthOrYearDriversInList = false;
        let includeBoxDeclarationInList = false;
        let includedVehicleRegInList = false;
        let includedVehicleManualInList = false;
        let includedVehicleAbiCodeInList = false;
        let includedVehicleFirstRegInList = false;
        let includedVehicleAbiCodeInListManual = false;
        let includedBankAccountNumber = false;
        let includedInvalidVehicleReg = false;
        let includedAboutYouPagePeriodStartDateInList = false;
        const bankAccNum = _.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.bankAccountNumber');

        if (strOriginPage === 'AboutYouPage' && objOriginPageValues.getFormattedDate(Date.now()) !== undefined && !includedAboutYouPagePeriodStartDateInList) {
            includedAboutYouPagePeriodStartDateInList = true;
            const periodStartDate = _.get(submissionVM.value, 'baseData.periodStartDate');
            const today = objOriginPageValues.getFormattedDate(Date.now());
            const periodStartDateDate = new Date(periodStartDate.year, periodStartDate.month, periodStartDate.day);
            const todayDate = new Date(today.year, today.month, today.day);
            if (periodStartDateDate < todayDate) {
                invalidPropsLength += 1;
            }
        }

        if (strOriginPage !== '') {
            switch (strOriginPage) {
                case 'PMLegalPage1InformationPage':
                    if (Object.keys(objOriginPageValues).length > 0) {
                        let blnDifferentVehicleCheckboxPassThrough = false;
                        if (objOriginPageValues.strVehicleRegValue === undefined || objOriginPageValues.strVehicleRegValue === '') {
                            invalidPropsLength += 1;
                        } else {
                            // eslint-disable-next-line no-lonely-if, max-len
                            if (objOriginPageValues.isVehicleWeCouldNotFind) invalidPropsLength += 1;
                            if (objOriginPageValues.isDifferentVehicle) {
                                if (!objOriginPageValues.isCheckboxActive) {
                                    invalidPropsLength += 1;
                                } else {
                                    blnDifferentVehicleCheckboxPassThrough = true;
                                }
                                // eslint-disable-next-line max-len
                                if (!blnDifferentVehicleCheckboxPassThrough) invalidPropsLength += 1;
                            }
                        }
                        return invalidPropsLength;
                    }
                    break;
                default:
            }
        }

        if (_.get(submissionVM.value, 'baseData.accountHolder.dateOfBirth.year') === undefined) {
            invalidPropsLength += 1;
        }
        if (!includedVehicleAbiCodeInList && !objOriginPageValues.isRegModeActive && (objOriginPageValues.objLoadedVehicleDetails?.value.abiCode == undefined || objOriginPageValues.objLoadedVehicleDetails?.value.abiCode == '') && strOriginPage === 'VehiclesPage') {
            includedVehicleAbiCodeInList = true;
        }
        if (!includedVehicleFirstRegInList && objOriginPageValues.objLoadedVehicleDetails?.value && (objOriginPageValues.objLoadedVehicleDetails.value.firstRegisteredYear !== undefined && objOriginPageValues.objLoadedVehicleDetails.value.firstRegisteredYear !== '') && strOriginPage === 'VehiclesPage') {
            includedVehicleFirstRegInList = true;
        }
        //place outside as if all other fields populated and a value eb=ntered for acc num will mean this never gets hit
        if (!accountNumValidRegex.test(bankAccNum) && !includedBankAccountNumber && stepsValidatingBindData.includes(strOriginPage)) {
            includedBankAccountNumber = true;
            invalidPropsLength += 1;
        }

        const arrUniqueAspectsInvalidChildProperties = [...new Set(submissionVM.aspects.invalidChildProperties)];
        arrUniqueAspectsInvalidChildProperties.some((element) => {
            if (objOriginPageValues.confirmationCheckBox === undefined && stepsValidatingBindData.includes(strOriginPage) && !includeBoxDeclarationInList) {
                includeBoxDeclarationInList = true;
                invalidPropsLength += 1;
            }
            if (element.includes('bindData') && stepsValidatingBindData.includes(strOriginPage) && !element.includes('bankAccountNumber')) {
                invalidPropsLength += 1;
            } else if (!element.includes('bindData')) {
                if (element.includes('homeNumber') || element.includes('homePhone')) {
                    if (!isHomeNumberValid) {
                        invalidPropsLength += 1;
                    }
                } else if (element.includes('cellNumber')) {
                    if (!isPhoneNumberValid) {
                        invalidPropsLength += 1;
                    }
                } else {
                    if (!element.includes('coverables.pmDrivers[0]') && element.includes('dateOfBirth') && !includedDateOfBirtInList) {
                        includedDateOfBirtInList = true;
                        invalidPropsLength += 1;
                    }
                    if (!element.includes('baseData.accountHolder.ukResidency') && !element.includes('coverables.pmDrivers[0]') && element.includes('ukResidency') && !includedResidencyMonthOrYearDriversInList) {
                        includedResidencyMonthOrYearDriversInList = true;
                        invalidPropsLength += 1;
                    }
                    if (element.includes('baseData.accountHolder.ukResidency') && !includedResidencyMonthOrYearInList) {
                        includedResidencyMonthOrYearInList = true;
                        invalidPropsLength += 1;
                    }
                    if ((element.includes('policyAddress.postalCode') || element.includes('policyAddress.addressLine1')) && !includedPostcodeOrAddressLine1InList) {
                        includedPostcodeOrAddressLine1InList = true;
                        invalidPropsLength += 1;
                    }
                    if ((element.includes('purchasedMonth') || element.includes('purchasedYear')) && !blnCountMonthsAndYearsAsOne && includedVehicleFirstRegInList) {
                        blnCountMonthsAndYearsAsOne = true;
                        invalidPropsLength += 1;
                    }
                    if ((element.includes('passedTestMonth') || element.includes('passedTestYear')) && !blnCountPassedMonthsAndYearsAsOne) {
                        blnCountPassedMonthsAndYearsAsOne = true;
                        invalidPropsLength += 1;
                    }
                    if ((element.includes('fuelType') || element.includes('make') || element.includes('transmission') || element.includes('firstRegisteredYear') || element.includes('model')) && !includedVehicleRegInList && objOriginPageValues.isRegModeActive && strOriginPage === 'VehiclesPage') {
                        includedVehicleRegInList = true;
                        invalidPropsLength -= 5;
                    }
                    if ((element.includes('pmVehicles[0]') && !includedVehicleAbiCodeInListManual && includedVehicleAbiCodeInList && !objOriginPageValues.isRegModeActive && strOriginPage === 'VehiclesPage')) {
                        includedVehicleAbiCodeInListManual = true;
                        invalidPropsLength += 1;
                    }
                    if ((!vehLookupSuccessful && !includedInvalidVehicleReg && objOriginPageValues.isRegModeActive && strOriginPage === 'VehiclesPage')) {
                        includedInvalidVehicleReg = true;
                        invalidPropsLength += 1;
                    }
                    if ((element.includes('fuelType') || element.includes('make') || element.includes('transmission') || element.includes('firstRegisteredYear') || element.includes('model')) && !includedVehicleManualInList && !objOriginPageValues.isRegModeActive && strOriginPage === 'VehiclesPage') {
                        includedVehicleManualInList = true;
                        invalidPropsLength += 1;
                    } else if (!element.includes('postalCode') && !element.includes('addressLine1') && !element.includes('ukResidency') && !element.includes('homeNumber') && !element.includes('homePhone') && !element.includes('cellNumber') && !element.includes('cardHolderPhoneNumber') && !element.includes('dateOfBirth') && !element.includes('billingAddress.postalCode') && !element.includes('billingAddress.addressLine1') && !element.includes('policyAddress.postalCode') && !element.includes('policyAddress.addressLine1')
                            && !element.includes('purchasedMonth') && !element.includes('purchasedYear') && !element.includes('passedTestMonth') && !element.includes('passedTestYear') && !element.includes('registrationNumber')) {
                        invalidPropsLength += 1;
                    }
                }
            } else if (element.includes('cardHolderPhoneNumber')) {
                if (!isPhoneNumberLnPValid) {
                    invalidPropsLength += 1;
                }
            } else if (element.includes('billingAddress.postalCode') && !includedPostcodeInList) {
                includedPostcodeInList = true;
                invalidPropsLength += 1;
            } else if (element.includes('billingAddress.addressLine1') && !includedAddressLine1InList) {
                includedAddressLine1InList = true;
                invalidPropsLength += 1;
            } else if (!element.includes('billingAddress.postalCode') && !element.includes('billingAddress.addressLine1') && !element.includes('cardHolderPhoneNumber') && !element.includes('billingAddress') && !element.includes('autoRenew_itb') && !element.includes('bankAccountNumber')) {
                invalidPropsLength += 1;
            }
        });

        return invalidPropsLength;
    },
    isPhoneNumberValid: (submissionVM) => {
        const betweenRangePatternMobile = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
        return betweenRangePatternMobile.test(submissionVM.value.baseData.accountHolder.cellNumber);
    },
    isHomeNumberValid: (submissionVM) => {
        if (submissionVM.value.baseData.accountHolder.homeNumber === undefined || submissionVM.value.baseData.accountHolder.homeNumber === '') {
            return true;
        } else {
            const betweenRangePatternLandLine = /^\d{4,11}$/;
            return betweenRangePatternLandLine.test(submissionVM.value.baseData.accountHolder.homeNumber);
        }
    },
    isPhoneNumberLnPValid: (submissionVM) => {
        const betweenRangePatternMobile = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
        if (submissionVM.value.bindData && submissionVM.value.bindData.paymentDetails && submissionVM.value.bindData.paymentDetails.creditCardData
            && submissionVM.value.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber) {
            const testPattern = submissionVM.value.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber;
            return betweenRangePatternMobile.test(testPattern);
        }
        return false;
    },
    updateInlineErrorBanner: (submissionVM, messages, invalidPropsCount, isSubmitted) => {
        const objReturnSet = { strErrorBannerTextOutput: '', blnErrorWidgetVisibility: false };
        // Check that the reference exists before accessing it
        if (submissionVM.aspects.invalidChildProperties.length !== undefined
            && submissionVM.aspects.invalidChildProperties.length !== null) {
            /* Get the count of the errors in the page and if the page has at least been submitted,
            Show dynamic message and show element */
            if (invalidPropsCount > 0 && isSubmitted) {
                const strOutput = messages.pmMainErrorBannerMessage.defaultMessage.replace(/some/g, invalidPropsCount);
                objReturnSet.strErrorBannerTextOutput = strOutput;
                objReturnSet.blnErrorWidgetVisibility = true;
            } else { // Reset values when no errors to show
                objReturnSet.strErrorBannerTextOutput = '';
                objReturnSet.blnErrorWidgetVisibility = false;
            }
        }
        return objReturnSet;
    }
};
