/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import vmTranslator, { messages as platformMessages } from 'gw-platform-translations';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { MetadataContent } from '@jutro/uiconfig';
import { useModal } from '@jutro/components';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { LoadSaveService } from 'gw-capability-quoteandbind';

import { ErrorBoundary } from 'gw-portals-error-react';

import metadata from './QuoteRetrievalPage.metadata.json5';
import messages from './QuoteRetrievalPage.messages.js';
import styles from './QuoteRetrievalPage.module.scss';

function QuoteRetrievalPage(props) {
    const {
        showAlert
    } = useModal();
    
    const { history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, updateIsLoading] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const onRetrieveQuote = useCallback(async (retrieveQuoteVM = {}) => {
        try {
            const submission = await LoadSaveService.retrieveSubmission(retrieveQuoteVM);
            //updateWizardData(submission);
            history.push({ 
                pathname: '/',
                data: submission
               });
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            showAlert({
                title: commonMessages.unableToRetrieveSubmission,
                message: commonMessages.somethingWentWrongRetrievingQuote,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: platformMessages.close
            });
        }
        
    }, []);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            readOnly: true
        },
    };
    const resolvers = {
        resolveCallbackMap: {
            onRetrieveQuote: onRetrieveQuote
        },
        resolveClassNameMap: styles
    };

    const handleError = useCallback((error = {}) => {
        history.push({ 
                    pathname: '/error',
                    data: error,
                    origin: "QuoteRetrievalPage",
                    quoteID: ''
                   });
        return false;
    });
    
    return (
        <ErrorBoundary onError={handleError}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                //model={retrieveQuoteVM}
                //onModelChange={updateWizardData}
                overrideProps={overrideProps}                
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </ErrorBoundary>
    );

}
QuoteRetrievalPage.propTypes = wizardProps;
export default withRouter(withViewModelService(QuoteRetrievalPage));
