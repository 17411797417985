/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ErrorBoundary } from "gw-portals-error-react";
import { withRouter } from 'react-router-dom';

import metadata from './PMEmailQuotePage.metadata.json5';
import messages from './PMEmailQuotePage.messages.js';
import styles from './PMEmailQuotePage.module.scss';

function PMEmailQuotePage(props) {
    const { initialValidation } = useValidation('PMEmailQuotePage');
    const { wizardData: submissionVM, updateWizardData, labelPosition, showOptional, history} = props;
    const quoteReference = submissionVM && submissionVM.quoteID && submissionVM.quoteID.value;

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            readOnly: true
        },
        quoteReference: {
            value: quoteReference
        },
    };

    const handleError = useCallback((error = {}) => {
        history.push({ 
            pathname: '/error',
            data: error,
            origin: "PMEmailQuotePage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, []);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                showPrevious={false}
                skipWhen={initialValidation}
                showNext={false}
                showCancel={false}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                />
            </WizardPage>
        </ErrorBoundary>
    );
}
PMEmailQuotePage.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    showOptional: PropTypes.bool,
};
PMEmailQuotePage.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    showOptional: true
};
export default withRouter(PMEmailQuotePage);
