import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ImageComponent } from 'gw-components-platform-react';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import ProductZipCode from './ProductZipCode/ProductZipCode';
import messages from './LineOfBusiness.messages';
import styles from './LineOfBusiness.module.scss';

class LineOfBusiness extends Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                defaultMessage: PropTypes.string
            }),
        ]),
        image: PropTypes.string,
        isProduct: PropTypes.bool,
        wizardLink: PropTypes.string,
        description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                defaultMessage: PropTypes.string
            }),
        ]),
        retrieveLink: PropTypes.string,
        productCode: PropTypes.string,
        onTogglePageState: PropTypes.func,
        onEnterProductFlow: PropTypes.func,
        startQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        zipCodePlaceholder: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        retreiveQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        id: undefined,
        name: undefined,
        image: undefined,
        isProduct: true,
        wizardLink: undefined,
        description: undefined,
        retrieveLink: undefined,
        productCode: undefined,
        onTogglePageState: undefined,
        onEnterProductFlow: undefined,
        startQuoteMessage: messages.startQuote,
        zipCodePlaceholder: messages.zipCodePlaceholder,
        retreiveQuoteMessage: messages.retrieveQuote
    };

    renderButtonLink = (translator) => {
        const { id, startQuoteMessage } = this.props;

        const buttonStyles = classNames(styles.startQuote, styles.quoteEntry);
        return (
            <Button
                id={`${id}_start_quote`}
                className={buttonStyles}
                onClick={this.enterNonProductFlow}
            >
                {translator(startQuoteMessage)}
            </Button>
        );
    };

    enterNonProductFlow = () => {
        const { onEnterProductFlow, wizardLink, productCode } = this.props;
        onEnterProductFlow(productCode, wizardLink);
    };

    handleChangeInLineOfBusiness = () => {
        const { onTogglePageState } = this.props;
        if (_.isFunction(onTogglePageState)) {
            onTogglePageState();
        }
    };

    renderQuoteEntry(translator) {
        const {
            id,
            isProduct,
            wizardLink,
            retrieveLink,
            productCode,
            startQuoteMessage,
            zipCodePlaceholder,
            onEnterProductFlow,
            retreiveQuoteMessage
        } = this.props;
        if (isProduct) {
            return (
                <ProductZipCode
                    id={id}
                    wizardLink={wizardLink}
                    retrieveLink={retrieveLink}
                    productCode={productCode}
                    className={styles.quoteEntry}
                    startQuoteMessage={startQuoteMessage}
                    zipCodePlaceholder={zipCodePlaceholder}
                    onTogglePageState={this.handleChangeInLineOfBusiness}
                    onEnterProductFlow={onEnterProductFlow}
                    retreiveQuoteMessage={retreiveQuoteMessage}
                />
            );
        }
        return this.renderButtonLink(translator);
    }

    render() {
        const {
            id, name, image, description
        } = this.props;

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <div id={`${id}_container`} className={styles.lobContainer}>
                        <ImageComponent
                            id={`${id}_imageId`}
                            src={image}
                            alt={translator(name)}
                            className={styles.productImage}
                        />
                        <h4 id={`${id}_name`} className={styles.productName}>
                            {translator(name)}
                        </h4>
                        <span id={`${id}_description`} className={styles.productDescription}>
                            {translator(description)}
                        </span>

                        {this.renderQuoteEntry(translator)}
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default withViewModelService(LineOfBusiness);
