import React, { useCallback } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import { brandingData } from 'gw-portals-branding-js';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import metadata from './VRNMissing.metadata.json5';
import styles from './VRNMissing.module.scss';
import messagesTranslationsSet from './VRNMissing.messages';

function VRNMissing(props) {
    const { location, history } = props;
    const translator = useTranslator();
    const brand = brandingData.BRANDING;

    const overrideProps = {
        getAssistanceText: {
            className: (brand === 'tya') ? 'gw-decline-error-text' : '',
            content: htmlParser(translator(messagesTranslationsSet[`getAssistanceText${brand.toUpperCase()}`].defaultMessage))
        }
    };

    const onNewQuoteClick = useCallback(() => {
        history.push("/");
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onNewQuoteClick: onNewQuoteClick
        }
    };

    return (
        <div className={styles.VRNMissingContainer}>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        </div>
    );
}

VRNMissing.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    })
};
VRNMissing.defaultProps = {
    location: undefined
};
export default withRouter(VRNMissing);
