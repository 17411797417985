import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteContactDetailsSaveButton: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Quote Contact Details Save Button',
        defaultMessage: 'Save Quote',
    },
    quoteContactDetailsPrintButton: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Quote Contact Details Print Button',
        defaultMessage: 'Print Quote',
    },
    emailSent: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.Email Sent',
        defaultMessage: 'Quote Saved',
    },
    emailQuoteDescription: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-driver.We will save your quote and send its number',
        defaultMessage: 'Your quote reference is: {quoteID}.\n\n We have sent you an email explaining how to retrieve your quote.\n Quotes are valid for 30 days, unless you change any details, including the policy start date.',
    },
    thisFieldIsRequiredErrorMessage: {
        id: 'quoteandbind.pm.components.PMContactDetailsComponent.field required error',
        defaultMessage: 'This field is required'
    },
    returnToQuoteButtonText: {
        id: 'quoteandbind.pm.components.PMContactDetailsComponent.Confirmation Text',
        defaultMessage: 'Return to Quote',
    },
    sessionEndedButtonText: {
        id: 'quoteandbind.pm.components.PMContactDetailsComponent.Exit Text',
        defaultMessage: 'Exit',
    },
    error: {
        id: 'quoteandbind.directives.PMContactDetailsComponent.Error',
        defaultMessage: 'Error',
    },
    cantSendQuoteDetailsAtThisTime: {
        id: 'quoteandbind.directives.PMContactDetailsComponent.Sorry, we were not able to email the quote details at this time.',
        defaultMessage: 'Sorry, we were not able to email the quote details at this time.',
    },
});
