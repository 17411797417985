import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeoutTitleKFI: {
        id: 'quoteandbind.views.session-timeout.sessionTimeoutTitleKFI',
        defaultMessage: 'Your session has timed out',
    },
    sessionTimeoutTitleTYA: {
        id: 'quoteandbind.views.session-timeout.sessionTimeoutTitleTYA',
        defaultMessage: 'Sorry, your session timed out.',
    },
    warningTextKFI: {
        id: 'quoteandbind.views.session-timeout.warningTextKFI',
        defaultMessage: 'We are sorry your session has now timed out. Please click the button below to start a new quote.',
    },
    warningTextTYA: {
        id: 'quoteandbind.views.session-timeout.warningTextTYA',
        defaultMessage: 'If you saved your progress, click the link in the email we sent you.',
    },
    SessionTimeout: {
        id: 'quoteandbind.views.session-timeout.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    SessionTimeoutWarning: {
        id: 'quoteandbind.views.session-timeout.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    SessionTimeoutSolution: {
        id: 'quoteandbind.views.session-timeout.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.session-timeout.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.session-timeout.Reference Number',
        defaultMessage: 'Reference Number',
    }
});
