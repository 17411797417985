import { defineMessages } from 'react-intl';

export default defineMessages({
    showAllBreakdownOptions: {
        id: 'quoteandbind.pm.components.coverages.showAllBreakdownOptions',
        defaultMessage: 'See all breakdown cover options',
    },
    noOption: {
        id: 'quoteandbind.pm.components.coverages.noOption',
        defaultMessage: 'I don’t need {coverageName}',
    },
    chooseBreakdownCoverOption: {
        id: 'quoteandbind.pm.components.coverages.chooseOption',
        defaultMessage: 'Choose your level of breakdown cover below',
    },
    breakdownAdditionalCoverageNote: {
        id: 'quoteandbind.pm.components.coverages.breakdownAdditionalCoverageNote_2',
        defaultMessage: 'Breakdown cover is not available for cars 15 years or over, or within 48 hours of purchase. A six callout per year limit applies. Breakdown cover is provided by Collinson Insurance Services Limited, and is underwritten by Astrenska Insurance Limited.',
    },
    amountBreakdownText: {
        id: 'quoteandbind.pm.components.coverages.amountBreakdownText',
        defaultMessage: '(equivalent to £{monthlyAmount} per month excluding the transaction fee)',
    },
    perYear: {
        id: 'quoteandbind.pm.components.coverages.perYear',
        defaultMessage: ' per year',
    },
});
