import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class VehicleInfoLookupService {
    static lookupVehicleInfoBasedOnVin(vin, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnVIN',
            [vin],
            additionalHeaders
        );
    }

    static lookupMakes(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupMakes',
            [],
            additionalHeaders
        );
    }

    static autofillBasedOnPartialDto(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnPartialDTO',
            [dto],
            additionalHeaders
        );
    }

    static lookupVehicleByRegNumber(reg, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupVehicleByRegNumber',
            [reg],
            additionalHeaders
        );
    }

    static lookupLCVVehicleByRegNumber(reg, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupLCVVehicleByRegNumber',
            [reg],
            additionalHeaders
        );
    }

    static findMakes(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findMakes',
            [],
            additionalHeaders
        );
    }

    static findLCVMakes(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findLCVMakes',
            [],
            additionalHeaders
        );
    }

    static findModels(make, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findModels',
            [make],
            additionalHeaders
        );
    }

    static findLCVModels(make, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findLCVModels',
            [make],
            additionalHeaders
        );
    }

    static findYears(make, model, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findYears',
            [make, model],
            additionalHeaders
        );
    }

    static findLCVYears(make, model, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findLCVYears',
            [make, model],
            additionalHeaders
        );
    }

    static findAbiCars(abiVehicleLookupDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findAbiCars',
            [abiVehicleLookupDTO],
            additionalHeaders
        );
    }

    static findAbiLCVs(abiVehicleLookupDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'findAbiLCVs',
            [abiVehicleLookupDTO],
            additionalHeaders
        );
    }

    /*
lookupVehicleByRegNumber: (reg) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupVehicleByRegNumber', [reg])
                .then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        lookupLCVVehicleByRegNumber: (reg) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupLCVVehicleByRegNumber', [reg])
                .then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findMakes: () => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findMakes', [])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVMakes: () => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVMakes', [])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findModels: (make) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findModels', [make])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVModels: (make) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVModels', [make])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findYears: (make, model) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findYears', [make, model])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVYears: (make, model) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVYears', [make, model])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findAbiCars: (abiVehicleLookupDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findAbiCars', [abiVehicleLookupDTO])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findAbiLCVs: (abiVehicleLookupDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findAbiLCVs', [abiVehicleLookupDTO])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        }
    */
}
