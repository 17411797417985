import { defineMessages } from 'react-intl';

export default defineMessages({
    frequentlyAskedQuestions: {
        id: 'portal.index.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ',
    },
    checkAnswers: {
        id: 'portal.index.Check Answers',
        defaultMessage: 'Check Answers',
    },
    needAssistance: {
        id: 'portal.index.Need Assistance?',
        defaultMessage: 'Need Assistance?',
    },
    liveChatNow: {
        id: 'portal.index.Live Chat Now',
        defaultMessage: 'Live Chat Now',
    },
    furtherQuestions: {
        id: 'portal.index.Further Questions?',
        defaultMessage: 'Further Questions?',
    },
    privacyPolicy: {
        id: 'footer.left_items.Privacy Policy',
        defaultMessage: 'Privacy Policy',
    },
    legalNotes: {
        id: 'footer.left_items.Legal Notes',
        defaultMessage: 'Legal Notes',
    },
    copyRight: {
        id: 'footer.right_items.Copyright &copy; 2001-2019 Guidewire Software',
        defaultMessage: 'Copyright © 2001-2019 Guidewire Software'
    },
    home: {
        id: 'portal.index.Home',
        defaultMessage: 'Home'
    },
    footer_1: {
        id: 'footer.bottom_items.legal notice.1',
        defaultMessage: 'Insurance is arranged by Toyota Insurance Management UK Limited, trading as Toyota Insurance Services, registered in England, Company No. 14297877. Registered Office: 7th Floor, 52-56 Leadenhall Street, London, EC3A 2BJ. Authorised and regulated by the Financial Conduct Authority. Firm Reference Number: 983839.'
    },
    footer_2: {
        id: 'footer.bottom_items.legal notice.2',
        defaultMessage: 'Policies are underwritten and administered by Aioi Nissay Dowa Insurance UK Limited. Registered in England, No. 11105895. Registered Office: 7th Floor, 52-56 Leadenhall Street, London, EC3A 2BJ. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and Prudential Regulation Authority. Firm Reference Number: 816870.'
    },
    footer_3: {
        id: 'footer.bottom_items.legal notice.3',
        defaultMessage: 'Kwik Fit Motor Insurance is arranged, administered and underwritten by Aioi Nissay Dowa Insurance UK Limited, registered in England and Wales number 11105895 at 52-56 Leadenhall Street, London, EC3A 2BJ. Aioi Nissay Dowa Insurance UK Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Financial Services Register number 816870.'
    },
    header_1: {
        id: 'header.navigation_items.contact contact_us.1',
        defaultMessage: 'Contact us'
    }
});
