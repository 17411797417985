import { defineMessages } from 'react-intl';

export default defineMessages({
    pncdTitle: {
        id: 'quoteandbind.pm.components.pncd.Title',
        defaultMessage: 'No Claim Discount Protection',
    },
    pncdAddon_1: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.1',
        defaultMessage: 'No Claim Discount Protection allows you to make one or more claims before the number of No Claim Discount year falls. Please see the ',
    },
    pncdAddon_2KFI: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.2.kfi',
        defaultMessage: ' for details. This can also be found in Section 12 of the ',
    },
    pncdAddon_2TYA: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.2.tya',
        defaultMessage: ' for details. This can also be found in Section 12 of the Private Car Policy Document.',
    },
    pncdAddon_2AfterLink: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.2AfterLink',
        defaultMessage: '.',
    },
    pncdAddon_3: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.3',
        defaultMessage: 'No Claim Discount Protection does not protect the overall price of your insurance policy. The price of your insurance policy may increase following an accident even if you were not at fault.',
    },
    pncdStepBack: {
        id: 'quoteandbind.pm.components.pncd.PNCD step back',
        defaultMessage: 'step-back procedures',
    },
    pncdGoodNews: {
        id: 'quoteandbind.pm.components.pncd.PNCD included',
        defaultMessage: 'Good news! No Claim Discount Protection is included at no additional cost with this policy.',
    },
    pncdQuestion: {
        id: 'quoteandbind.pm.components.pncd.noOption2',
        defaultMessage: 'You have declared {pncdYears} years No Claim Discount (NCD). Would you like to protect it? ',
    },
    pncdCost: {
        id: 'quoteandbind.pm.components.pncd.pncdCostMessage',
        defaultMessage: '£{cost} per year'
    },
    pncdNo: {
        id: 'quoteandbind.pm.components.pncd.noOption',
        defaultMessage: "No, I don't need NCD Protection"
    },
    pncdYes: {
        id: 'quoteandbind.pm.components.pncd.yesOption',
        defaultMessage: 'Yes, I would like to protect my NCD'
    }
});
