import { defineMessages } from 'react-intl';

export default defineMessages({
    pncdModalTitle: {
        id: 'quoteandbind.pm.components.pncdmodal.title',
        defaultMessage: 'Important information about No Claim Discount (NCD) Protection',
    },
    pncdModalDiscription: {
        id: 'quoteandbind.pm.components.pncdmodal.description',
        defaultMessage: 'The tables below show i) the average NCD discount awarded to our motor insurance policyholders last year according' +
        ' to their number of NCD years; and ii) what would happen to your NCD years if you were to make one or more claims in' +
        ' the next 12 months with and without this protection.',
    },
    pncdModalTable1Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table1.header',
        defaultMessage: 'Table 1: Average NCD discount awarded to our customers.',
    },
    pncdModalTable1Column1Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table1.Column1.header',
        defaultMessage: 'Number of years NCD',
    },
    pncdModalTable1Column2Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table1.Column2.header',
        defaultMessage: ' Average NCD discount in 2023',
    },
    pncdModalTable2Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.header',
        defaultMessage: 'Table 2: Step back procedure (adapted from the Private Car Policy Document – Section 12)',
    },
    pncdModalTable2Desc1: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.desc1',
        defaultMessage: 'If you have made no claims in the period of policy cover, you will be eligible for a No Claim Discount or for an increase' +
        ' in your No Claim Discount, when you renew your policy. The level of your No Claim Discount is based upon the number' +
        ' of consecutive years that you have had cover and the number of claims that have been made during that time.',
    },
    pncdModalTable2Desc2: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.desc2',
        defaultMessage: 'If you make one or more claims during the period of policy cover, at your next renewal your No Claim Discount will be' +
        ' reduced to the number of years specified in the following scale:',
    },
    pncdModalTable2Col1Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col1.header',
        defaultMessage: 'Number of years No Claim Discount held at your purchase / renewal date',
    },
    pncdModalTable2Col2Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col2.header',
        defaultMessage: '1 claim in next 12 months',
    },
    pncdModalTable2Col3Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col3.header',
        defaultMessage: '2 claims in next 12 months',
    },
    pncdModalTable2Col4Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col4.header',
        defaultMessage: '3 claims in next 12 months',
    },
    pncdModalTable2Col5Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col5.header',
        defaultMessage: '1 claim in next 12 months',
    },
    pncdModalTable2Col6Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col6.header',
        defaultMessage: '2 claims in next 12 months',
    },
    pncdModalTable2Col7Header: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col7.header',
        defaultMessage: '3 claims in next 12 months',
    },
    pncdModalTable2WithoutPNCDColsHeader: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.col7.without pncd header',
        defaultMessage: 'No Claim Discount at next renewal date without NCD Protection',
    },
    pncdModalTable2WithPNCDColsHeader: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.with pncd header',
        defaultMessage: 'No Claim Discount at next renewal date with NCD Protection',
    },
    pncdModalTable2NewBusinessCustomer: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.new business',
        defaultMessage: 'For all New Business customers, and for Renewing customers who have had no claims within the past two' +
        ' periods of continuous policy cover with us',
    },
    pncdModalTable2RenewingCustomer: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.renewing',
        defaultMessage: 'For all Renewing customers who have had a claim within the past two periods of continuous policy cover with us',
    },
    pncdModalTable2NilYears: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.nil years',
        defaultMessage: 'Nil years',
    },
    pncdModalTable2Unaffected: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.unaffected',
        defaultMessage: 'Unaffected',
    },
    pncdModalTable2FooterNote: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.footerNote',
        defaultMessage: 'Note:'
    },
    pncdModalTable2Footer: {
        id: 'quoteandbind.pm.components.pncdmodal.table2.footer',
        defaultMessage: ' Customers are ineligible for No Claim Discount protection if they have had two or more fault claims within the past two years.',
    },
    pncdModalFollowingClaimListHeader: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.header',
        defaultMessage: 'The following claims will not reduce your No Claim Discount:',
    },
    pncdModalFollowingClaimListItem1: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item1',
        defaultMessage: 'any payment for emergency treatment charges under Section 3 (Liability to other people)',
    },
    pncdModalFollowingClaimListItem2: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item2',
        defaultMessage: 'any payment made under Section 5 (Glass in windscreens and windows)',
    },
    pncdModalFollowingClaimListItem3: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item3',
        defaultMessage: 'any claims where you are not at fault, provided the insurer has recovered its outlay in full',
    },
    pncdModalFollowingClaimListItem4: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item4',
        defaultMessage: 'any claims where you are not at fault, and the driver of that car was uninsured',
    },
    pncdModalFollowingClaimListItem5TYA: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item5.tya',
        defaultMessage: "any claims made under 'Your Car Insurance by Toyota Insurance Services Key Protect policy'",
    },
    pncdModalFollowingClaimListItem5KFI: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Item5.kfi',
        defaultMessage: "any claims made under 'Your Kwik Fit Motor Insurance Key Protect policy'",
    },
    pncdModalFollowingClaimListDisclaimer1: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Disclaimer1',
        defaultMessage: 'You cannot transfer your No Claim Discount to anyone else. If you insure more than one car through us, the No Claim Discount is earned separately for each car.',
    },
    pncdModalFollowingClaimListDisclaimer2: {
        id: 'quoteandbind.pm.components.pncdmodal.followingClaimList.Disclaimer2',
        defaultMessage: 'The application of your No Claim Discount may not necessarily result in a decrease in your premium.',
    },
});
