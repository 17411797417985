import { defineMessages } from 'react-intl';

export default defineMessages({
    aggHeadingTextTYA: {
        id: 'quoteandbind.views.agg.headingText.tya',
        defaultMessage: 'Thank you for choosing Car Insurance by Toyota Insurance Services on'
    },
    aggHeadingTextKFI: {
        id: 'quoteandbind.views.agg.headingText.kfi',
        defaultMessage: 'Thank you for choosing Kwik Fit Motor Insurance on'
    },
    aggSpinnerSubText: {
        id: 'quoteandbind.pm.views.aggs-sub-spinner-text',
        defaultMessage: 'Insurance from the experts at getting you back on the road'
    }
});
