import { defineMessages } from 'react-intl';

export default defineMessages({
    quote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.Homeowners Insurance Quote',
        defaultMessage: ''
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.Location',
        defaultMessage: 'Location'
    }
});
