import React, { Component } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withModalContext } from '@jutro/components';
import { AvailabilityService } from 'gw-capability-policycommon';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { isCapabilityEnabled } from 'gw-portals-config-js';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import metadata from './LandingPage.metadata.json5';
import styles from './LandingPage.module.scss';
import messages from './LandingPage.messages';

class LandingPage extends Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    state = {
        formData: {
            lobCategory: 'personal'
        },
        landingPageState: 'startQuote'
    };

    handleValueChange = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    readValue = (id, path) => {
        const { formData } = this.state;
        return _.get(formData, path);
    };

    startQuote = (data, wizardLink) => {
        const { history } = this.props;
        if (data === 'QuickQuote' || data === 'Guidance') {
            history.push(wizardLink);
        } else {
            AvailabilityService.productAvailableBasedOnPostalCode(data).then((response) => {
                if (response.isAvailable) {
                    const nextState = {
                        address: response.addressCompletion
                    };
                    history.push(wizardLink, nextState);
                }
            });
        }
    };

    retrieveQuote = async ({ captchaValue, ...submissionData }) => {
        const { history, modalContext } = this.props;
        const headers = {
            'x-captcha': captchaValue
        };
        LoadSaveService.retrieveSubmission(submissionData, headers)
            .then((submission) => {
                const wizardLink = this.getWizardLink(submission.baseData.productCode);
                history.push(wizardLink, { submission });
            })
            .catch(() => {
                modalContext.showAlert({
                    title: messages.errorRetrievingQuoteModalTitle,
                    message: messages.errorRetrievingQuoteModalBody,
                    status: 'error',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
            });
    };

    togglePageState = () => {
        const { landingPageState } = this.state;
        if (landingPageState === 'startQuote') {
            this.setState({ landingPageState: 'retrieveQuote' });
        } else {
            this.setState({ landingPageState: 'startQuote' });
        }
    };

    getWizardLink = (lobCode) => {
        const lobWizardLinks = {
            BusinessOwners: '/quote-bop',
            Homeowners: '/quote-ho',
            HOPHomeowners: '/quote-ho',
            PersonalAuto: '/quote-pa',
            PersonalMotor: '/quote-pm'
        };
        return lobWizardLinks[lobCode];
    };

    render() {
        const { submitted, formData, landingPageState } = this.state;
        const { capabilitiesConfig } = appConfig;
        const { history } = this.props;
        const overrideProps = {
            '@field': {
                // apply to all fields
                onValueChange: this.handleValueChange,
                showErrors: submitted
            },
            personalContainer: {
                visible: formData.lobCategory === 'personal'
            },
            businessContainer: {
                visible: formData.lobCategory === 'business'
            },
            startQuoteContainer: {
                visible: landingPageState === 'startQuote'
            },
            retrieveQuoteContainer: {
                visible: landingPageState === 'retrieveQuote'
            },
            businessOwnersLOB: {
                visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'quote', lob: 'bop' })
            }
            
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onRetrieveQuote: this.retrieveQuote,
                onToggleState: this.togglePageState,
                onEnterFlow: this.startQuote,
                onGetWizardLink: this.getWizardLink
            }
        };

        return (
            <div className={styles.landingPageContainer}>
                <MetadataContent
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    {...resolvers}
                />
            </div>
        );
    }
}
export const LandingPageComponent = LandingPage;
export default withRouter(withModalContext(LandingPage));
