//import _ from 'underscore';

import { isUndefined } from "lodash";

function PmVehicle(data) {
    if (data) {
        _.extend(this, data);
    }

    /* if (!angular.isDefined(this.costNew)) {
        this.costNew = {};
    } */
}

PmVehicle.prototype.getDisplayName = function () {
    if (this.abiCode) {
        return `${this.firstRegisteredYear} ${this.make} ${this.enhancedVehicleDescription} ${this.engineCapacity} CC`;
    }
};

export default PmVehicle;