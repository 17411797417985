/* eslint-disable max-len */

import messages from './PNCDPopupModalWindow.messages';

const YEAR1_NCD_HELD_OPTION = {
    title: '1 Year NCD held',
    WO_PNCD_1Claim: messages.pncdModalTable2NilYears.defaultMessage,
    WO_PNCD_2Claim: messages.pncdModalTable2NilYears.defaultMessage,
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: '',
    W_PNCD_2Claim_NB: '',
    W_PNCD_2Claim_R: '',
    W_PNCD_3Claim: ''
};
const YEAR2_NCD_HELD_OPTION = {
    title: '2 Years NCD held',
    WO_PNCD_1Claim: '1 year',
    WO_PNCD_2Claim: messages.pncdModalTable2NilYears.defaultMessage,
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_NB: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_R: '1 year',
    W_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage
};
const YEAR3_NCD_HELD_OPTION = {
    title: '3 Years NCD held',
    WO_PNCD_1Claim: '2 years',
    WO_PNCD_2Claim: '1 year',
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_NB: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_R: '2 years',
    W_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage
};
const YEAR4_NCD_HELD_OPTION = {
    title: '4 Years NCD held',
    WO_PNCD_1Claim: '3 years',
    WO_PNCD_2Claim: '2 years',
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_NB: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_R: '3 years',
    W_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage
};
const YEAR5_NCD_HELD_OPTION = {
    title: '5 Years NCD held',
    WO_PNCD_1Claim: '4 years',
    WO_PNCD_2Claim: '3 years',
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_NB: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_R: '4 years',
    W_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage
};
const YEAR6_NCD_HELD_OPTION = {
    title: '6 Years + NCD held',
    WO_PNCD_1Claim: '4 years',
    WO_PNCD_2Claim: '4 years',
    WO_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage,
    W_PNCD_1Claim: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_NB: messages.pncdModalTable2Unaffected.defaultMessage,
    W_PNCD_2Claim_R: '4 years',
    W_PNCD_3Claim: messages.pncdModalTable2NilYears.defaultMessage
};

export default [YEAR1_NCD_HELD_OPTION, YEAR2_NCD_HELD_OPTION, YEAR3_NCD_HELD_OPTION, YEAR4_NCD_HELD_OPTION, YEAR5_NCD_HELD_OPTION, YEAR6_NCD_HELD_OPTION];
