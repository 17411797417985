import { defineMessages } from 'react-intl';

export default defineMessages({
    pmVehicleTitle: {
        id: 'quoteandbind.pm.views.pm-vehicle-details.AboutYourCar',
        defaultMessage: 'About your car',
    },
    pmVehicleTitleNumber: {
        id: 'quoteandbind.pm.views.pm-vehicle-details.Vehicle:',
        defaultMessage: ' ',
    },
    pmAddAnotherVehicleTitle: {
        id: 'quoteandbind.pm.views.pm-driver-details.Add Another Vehicle?',
        defaultMessage: 'Add Another Vehicle?',
    },
    pmAddDriverButtonText: {
        id: 'quoteandbind.pm.views.pm-driver-details.Add Vehicle',
        defaultMessage: 'Add Vehicle',
    },
    removeVehicleTitle: {
        id: 'quoteandbind.pm.controllers.PAVehicleDetailsCtrl.Remove Vehicle?',
        defaultMessage: 'Remove Vehicle?',
    },
    removeVehicleDescription: {
        id: 'quoteandbind.pm.drivers.remove-vehicle.message',
        defaultMessage: 'Are you sure you want to remove this vehicle from the list of vehicles?',
    },
    fillInMsg: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.fillInMsg',
        defaultMessage: 'After you enter a VIN and move to the next field, the Make, Model and Year fields will be filled in automatically if possible.',
    },
    additionalInterestsForThisVehicle: {
        id: 'quoteandbind.pm.views.pm-vehicle-details.Additional Interests for This Vehicle',
        defaultMessage: 'Additional Interests for This Vehicle',
    },
    pmAddAdditionalInterestType: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Type',
        defaultMessage: 'Type',
    },
    pmAddAdditionalInterestFirstName: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.First Name',
        defaultMessage: 'First Name',
    },
    pmAddAdditionalInterestLastName: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Last Name',
        defaultMessage: 'Last Name',
    },
    pmAddAdditionalInterestCountry: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Country',
        defaultMessage: 'Country',
    },
    pmAddAdditionalInterestAddress: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Address',
        defaultMessage: 'Address',
    },
    pmAddAdditionalInterestCity: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.City',
        defaultMessage: 'City',
    },
    pmAddAdditionalInterestState: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.State',
        defaultMessage: 'State',
    },
    pmAddAdditionalInterestZipCode: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.ZIP Code',
        defaultMessage: 'ZIP code',
    },
    pmAddAdditionalInterestInterestType: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Interest Type',
        defaultMessage: 'Interest Type',
    },
    pmAddAdditionalInterestAddressType: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Address Type',
        defaultMessage: 'Address Type',
    },
    pmAddAdditionalInterestPhoneNumber: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Phone Number',
        defaultMessage: 'Phone Number',
    },
    pmAddAdditionalInterestEmail: {
        id: 'quoteandbind.views.pm-ho-your-home.additional-interest.Email',
        defaultMessage: 'Email',
    },
    pmAddAdditionalInterestForThisVehicle: {
        id: 'quoteandbind.pm.views.pm-driver-details.Additional Interests for This Vehicle',
        defaultMessage: 'Add Additional Interests for This Vehicle',
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.pm-vehicles.additional-interest.Additional Interest:',
        defaultMessage: 'Additional Interest: { count }'
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-vehicle-page',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    }
});
