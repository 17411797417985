/* eslint-disable max-len */
import React, {
    useCallback, useMemo, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import moment from 'moment';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import styles from './PMClaimsComponent.module.scss';

import metadata from './PMClaimsComponent.metadata.json5';
import './PMClaimsComponent.messages';

function PMClaimsComponent(props) {
    const {
        data: claimVM,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        submitted
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMClaimsComponent');
    const minimumDate = moment().subtract(5, 'years').toDate();
    const [minClaimDate] = useState({
        year: minimumDate.getFullYear(),
        month: minimumDate.getMonth(),
        day: minimumDate.getDate()
    });
    const [maxClaimDate] = useState(LocalDateUtil.today);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const getBooleanFieldValue = (fieldPath) => {
        const booleanVal = _.get(claimVM, fieldPath, false);

        return booleanVal;
    };

    const isDateInTheAllowedMinMaxRange = (objCheckDate, objMinDate, objMaxDate) => {
        return objCheckDate.getTime() >= objMinDate.getTime() && objCheckDate.getTime() <= objMaxDate.getTime();
    };

    const handleManualEnteredDateConstraint = () => {
        const objClaimDateInPage = _.get(claimVM, 'claimDate');
        if (objClaimDateInPage?.year && objClaimDateInPage?.month && objClaimDateInPage?.day) {
            const ClaimInDateRange = isDateInTheAllowedMinMaxRange(new Date(objClaimDateInPage.year.value, objClaimDateInPage.month.value, objClaimDateInPage.day.value), new Date(minClaimDate.year, minClaimDate.month, minClaimDate.day), new Date(maxClaimDate.year, maxClaimDate.month, maxClaimDate.day));
            if (!ClaimInDateRange) {
                _.set(claimVM.value, 'claimDate', undefined);
            }
        }
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'PMClaimsComponent');
        }
    }, [id, onValidate, isComponentValid]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
        },
        [`claimDate${index}`]: {
            onBlur: handleManualEnteredDateConstraint(),
            minDate: new Date(minClaimDate.year, minClaimDate.month, minClaimDate.day),
            maxDate: new Date(maxClaimDate.year, maxClaimDate.month, maxClaimDate.day),
            required: getBooleanFieldValue('claimDate.aspects.required'),
            showRequired: getBooleanFieldValue('claimDate.aspects.required'),
        },
        [`claimType${index}`]: {
            required: getBooleanFieldValue('claimType.aspects.required'),
            showRequired: getBooleanFieldValue('claimType.aspects.required'),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            model={claimVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            showErrors={submitted}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMClaimsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMClaimsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMClaimsComponent;
