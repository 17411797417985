/* eslint-disable no-param-reassign */
import StringUtil from 'gw-portals-util-js/StringUtil';

const availKinds = ['root', 'property', 'collectionElement'];

/**
 * Enhances an element with kind data and properties, freezes it and return it back.
 * @param {*} kind
 * @param {Object} elt
 *
 * @returns {Object}
 */
function finishWithKinds(kind, elt) {
    elt.kind = kind;
    availKinds.forEach((possibleKind) => {
        elt[`is${StringUtil.capitalizeFirstLetter(possibleKind)}`] = kind === possibleKind;
    });
    return Object.freeze(elt);
}

/** This module defines a step specification, description of one (direct) step in the view model.
 *
 *
 * <h2>Step definition</h2>
 * <dl>
 *     <dt>kind</dt><dd>Type of the item, different types of steps have different kinds.</dd>
 *     <dt>is[kind]</dt><dd>Boolean value (not a function)
 *      set to true only for the actual kind and to false for
 *        all other possible kinds.
 * </dl>
 *
 * <h2>Step kinds</h2>
 * Valid values for the <code>step</code> property on the step definition are:
 * <dl>
 *     <dt>root</dt><dd>Object in question is an actual "root" object. It have no parents.</dd>
 *     <dt>property</dt><dd>Object is a property on another object.
 *          Values with this kind have an additional
 *        <code>property</code> property referencing a property definition
 *          from the metadata package.</dd>
 *     <dt>collectionElement</dt><dd>This steps defines a "dynamic" access,
 *          descent on one step in some unspecified
 *        collection (map or array).</dd>*
 * </dl>
 */
export default Object.freeze({
    /** Step describing "entering" the tree (root object). */
    ROOT: finishWithKinds('root', {}),

    /** Step describing a navigation in one collection element. */
    COLLECTION_ELT: finishWithKinds('collectionElement', {}),

    /**
     * Creates a property navigation step.
     * @param {*} info property metadata.
     * @returns {Object}
     */
    property: (info) => {
        return finishWithKinds('property', {
            property: info
        });
    }
});
