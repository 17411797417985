import { defineMessages } from 'react-intl';

export default defineMessages({
    excessTitleTYA: {
        id: 'quoteandbind.pm.components.excesses.title.tya',
        defaultMessage: 'Review your excesses',
    },
    excessTitleKFI: {
        id: 'quoteandbind.pm.components.excess.title.kfi',
        defaultMessage: 'Review your excess',
    },
    driver: {
        id: 'quoteandbind.pm.components.excesses.driver',
        defaultMessage: 'Driver',
    },
    compulsory: {
        id: 'quoteandbind.pm.components.excesses.compulsory',
        defaultMessage: 'Compulsory',
    },
    voluntary: {
        id: 'quoteandbind.pm.components.excesses.voluntary',
        defaultMessage: 'Voluntary',
    },
    voluntaryExcessDescription: {
        id: 'quoteandbind.pm.components.excesses.voluntary excess description',
        defaultMessage: 'Choosing a voluntary excess can help lower your premium but in the event of a claim you will need to pay any voluntary excess in addition to your compulsory excess.',
    },
    voluntaryExcess: {
        id: 'quoteandbind.pm.components.excesses.voluntary excess',
        defaultMessage: 'Voluntary Excess',
    },
    voluntaryExcessDescription2: {
        id: 'quoteandbind.pm.components.excesses.voluntary excess description 2',
        defaultMessage: '​Excess for damage caused to your car by accident, fire or theft.',
    },
    total: {
        id: 'quoteandbind.pm.components.excesses.total',
        defaultMessage: 'Total',
    },
    otherCompulsoryExcesses: {
        id: 'quoteandbind.pm.components.excesses.Other Compulsory Excesses',
        defaultMessage: 'Other Compulsory Excesses',
    },
    voluntaryExcessWarningTYA: {
        id: 'quoteandbind.pm.components.excesses.Voluntary Excess Warning.tya',
        defaultMessage: 'If you need to make a claim you will have to pay both the compulsory and voluntary excesses. Voluntary excess is the amount you choose when buying your policy. A higher voluntary excess can lower the cost of your insurance.',
    },
    voluntaryExcessWarningKFI: {
        id: 'quoteandbind.pm.components.excesses.Voluntary Excess Warning.kfi',
        defaultMessage: 'If you need to make a claim you will have to pay both compulsory and voluntary excesses. Voluntary excess is the amount you choose when buying your policy. A higher voluntary excess can lower the cost of your insurance.',
    },
    windscreenReplacement: {
        id: 'quoteandbind.pm.components.excesses.Windscreen replacement',
        defaultMessage: 'Windscreen replacement',
    },
    windscreenRepair: {
        id: 'quoteandbind.pm.components.excesses.Windscreen repair',
        defaultMessage: 'Windscreen repair',
    },
    compulsoryTooltipMessage: {
        id: 'quoteandbind.pm.components.excesses.Compulsory Tooltip Message',
        defaultMessage: 'Compulsory excess is the amount that is set for your policy and cannot be changed. If you make a claim, you will have to pay both the compulsory and the voluntary excess.',
    },
    driverName: {
        id: 'quoteandbind.pm.components.excesses.driverName',
        defaultMessage: '{firstName} {lastName}',
    },
});
