import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { ErrorRedirectUtil } from 'gw-capability-quoteandbind-common-react'
import { useDependencies } from 'gw-portals-dependency-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import { brandingData } from 'gw-portals-branding-js';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import metadata from './ErrorPage.metadata.json5';
import styles from './ErrorPage.module.scss';
import messagesTranslationsSet from './ErrorPage.messages';

function ErrorPage(props) {
    const { location, history } = props;
    const translator = useTranslator();
    const brand = brandingData.BRANDING;

    const { LoadSaveService } = useDependencies('LoadSaveService');

    useEffect(() => {
        //Clear session storage on all errors
        //will require work to retain data or clear in different scenarios 
        window.sessionStorage.clear();
        if (location && location.data) {            
            let errorDTO = {
                appErrorCode: location.data.appErrorCode || 101,
                message: location.data.baseError || location.data.message || '',
                origin: location.origin || '',
                quoteID: location.quoteID || ''
            };
            LoadSaveService.logFrontendError(errorDTO);
            const redirectUrl = ErrorRedirectUtil.getRedirectURL(location.data);
            if(redirectUrl) {
                history.push({ 
                    pathname: redirectUrl,
                    data: location.data
                });
            }
        }
    }, []);

    const overrideProps = {
        warningText: {
            className: (brand === 'tya') ? 'gw-decline-error-text' : ''
        },
        getAssistanceText: {
            className: (brand === 'tya') ? 'gw-decline-error-text' : '',
            content: htmlParser(translator(messagesTranslationsSet[`getAssistanceText${brand.toUpperCase()}`].defaultMessage))
        },
        getAssistanceTimings1: {
            className: (brand === 'tya') ? 'gw-decline-error-text' : ''
        },
        getAssistanceTimings2: {
            className: (brand === 'tya') ? 'gw-decline-error-text-reduced' : ''
        },
        getAssistanceTimings3: {
            className: (brand === 'tya') ? 'gw-decline-error-text-reduced' : ''
        },
        getAssistanceTimings4: {
            className: (brand === 'tya') ? 'gw-decline-error-text-reduced' : ''
        },
    };
    const onNewQuoteClick = useCallback(() => {
        history.push("/");
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onNewQuoteClick: onNewQuoteClick
        }
    };

    return (
        <div className={styles.ErrorPageContainer}>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        </div>
    );
}

ErrorPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    })
};
ErrorPage.defaultProps = {
    location: undefined
};
export default withRouter(ErrorPage);
